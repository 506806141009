import * as Shared from "../shared";

export function createShradhamRecord({
  contactId,
  shradhamForm
}: {
  contactId: string;
  shradhamForm: Shared.Form.Shradham;
}): Shared.Shradham {
  const shradham: Shared.Shradham = {
    contactId,
    ...shradhamForm
  };

  return shradham;
}
