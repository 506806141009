import {
  Avatar,
  Typography,
  styled,
  Grid,
  withStyles
} from "@material-ui/core";
import * as firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import { useUser } from "reactfire";
import { useSharedStyles } from "./styles";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0
  },
  rect: {
    marginBottom: 0
  }
})(Skeleton);

const ProfileInfo = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const ProfileInfoName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1)
}));

export const ProfileInfoSkeleton: React.FC = props => {
  return (
    <>
      <ProfileInfo
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Skeleton variant="circle" width={60} height={60} />
        <StyledSkeleton variant="text" width="90%" />
        <StyledSkeleton variant="text" width="40%" />
      </ProfileInfo>
    </>
  );
};

const SideNavbarContentProfileInfo: React.FC = props => {
  const sharedClasses = useSharedStyles(props);

  const user: firebase.User = useUser();

  return (
    <>
      <ProfileInfo
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        {user && user.photoURL && (
          <Avatar
            alt="Person"
            className={sharedClasses.bigAvatar}
            src={user.photoURL}
            component={Link}
            to="/settings"
          />
        )}
        {user && !user.photoURL && user.displayName && (
          <Avatar
            className={sharedClasses.bigAvatar}
            component={Link}
            to="/settings"
          >
            <Typography variant="h1">{user.displayName[0]}</Typography>
          </Avatar>
        )}
        <Grid item xs zeroMinWidth>
          <ProfileInfoName variant="h4" noWrap>
            {user && user.displayName}
          </ProfileInfoName>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant="body2" noWrap>
            Pandit
          </Typography>
        </Grid>
      </ProfileInfo>
    </>
  );
};

export default SideNavbarContentProfileInfo;
