import * as React from "react";
import { Typography } from "@material-ui/core";
import { EventForm, FormSkeleton, EVENT_FORM_VARIANT } from "../../../components";
import { useParams } from "react-router-dom";

interface AddEventProps {}

const AddEvent: React.FunctionComponent<AddEventProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams<{ contactId: string }>();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Add event
      </Typography>
      <React.Suspense fallback={<FormSkeleton variant={EVENT_FORM_VARIANT.WITH_CONTACT} />}>
        <EventForm contactId={params.contactId} variant={EVENT_FORM_VARIANT.WITH_CONTACT} />
      </React.Suspense>
    </>
  );
};

export default AddEvent;
