import * as React from "react";
import { Grid, CardContent, Typography, DialogActions, Button, styled, Card } from "@material-ui/core";
import { Link } from "react-router-dom";

const WarningCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #546E7A 30%, #546E7A 90%)",
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),
}));

interface NoteProps {}

const Note: React.FunctionComponent<NoteProps> = (props) => {
  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <WarningCard>
            <CardContent>
              <Typography color="inherit">
                This is for adding one time event without creating a contact. If you want to add an event to an exising
                contact please navigate to contacts page.
              </Typography>
            </CardContent>
          </WarningCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Note;
