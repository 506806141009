import * as React from "react";
import {
  Card,
  CardContent,
  FormHelperText,
  withStyles,
  InputLabel,
  FormControl,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
  useTheme,
  styled
} from "@material-ui/core";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField, Select } from "formik-material-ui";
import * as Yup from "yup";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import * as Shared from "../../../../shared";
import * as Utils from "../../../../utils";
import { LinkStoreContext } from "../../../../app";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34)
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0
  },
  rect: {
    marginBottom: 0
  }
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = props => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton
            component="span"
            width={70}
            height={theme.spacing(3)}
          />
          <StyledSkeleton
            component="span"
            width={60}
            height={theme.spacing(3)}
          />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface AddShradhamDialogProps {
  handleSubmit(): void;
}

const AddShradhamDialog: React.FC<AddShradhamDialogProps> = props => {
  const linkStore = React.useContext(LinkStoreContext);

  const validationSchema = Yup.object<Shared.Form.Shradham>({
    forName: Yup.string().required("Shradham for is required"),
    masam: Yup.string().required("Masam is required"),
    paksham: Yup.string().required("Paksham is required"),
    thithi: Yup.string().required("Thithi is required"),
    panchangam: Yup.string().required("Panchangam is required")
  });

  const masamLabel = React.useRef<HTMLLabelElement>(null);
  const pakshamLabel = React.useRef<HTMLLabelElement>(null);
  const thithiLabel = React.useRef<HTMLLabelElement>(null);
  const panchangamLabel = React.useRef<HTMLLabelElement>(null);
  const [masamLabelWidth, setMasamLabelWidth] = React.useState<number>(0);
  const [pakshamLabelWidth, setPakshamLabelWidth] = React.useState<number>(0);
  const [thithiLabelWidth, setThithiLabelWidth] = React.useState<number>(0);
  const [panchangamLabelWidth, setPanchangamLabelWidth] = React.useState<
    number
  >(0);
  React.useEffect(() => {
    setMasamLabelWidth(masamLabel.current!.offsetWidth);
    setPakshamLabelWidth(pakshamLabel.current!.offsetWidth);
    setThithiLabelWidth(thithiLabel.current!.offsetWidth);
    setPanchangamLabelWidth(panchangamLabel.current!.offsetWidth);
  }, []);

  const handleSubmit = async (
    shradhamForm: Shared.Form.Shradham,
    { setSubmitting }: FormikActions<Shared.Form.Shradham>
  ) => {
    const shradham: Shared.Shradham = Utils.createShradhamRecord({
      contactId: linkStore.lkid as string,
      shradhamForm
    });
    linkStore.addShradham(shradham);
    setSubmitting(false);
    props.handleSubmit();
  };

  return (
    <>
      <StyledCard>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            forName: "",
            masam: "",
            paksham: "",
            thithi: "",
            panchangam:
              (linkStore.contact && linkStore.contact.panchangam) || ""
          }}
        >
          {({ isSubmitting, isValid, errors, touched, submitForm }) => (
            <FormikForm>
              <CardContent>
                <Field
                  type="text"
                  name="forName"
                  label={
                    <>
                      Shradham for{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={masamLabel}
                    htmlFor="shradham-masam"
                    error={
                      touched && touched.masam && errors && errors.masam
                        ? true
                        : false
                    }
                  >
                    Masam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="masam"
                    component={Select}
                    labelWidth={masamLabelWidth}
                    inputProps={{
                      id: "shradham-masam"
                    }}
                    error={
                      touched && touched.masam && errors && errors.masam
                        ? true
                        : false
                    }
                  >
                    <option value="" hidden></option>
                    {Shared.MASAM.map((option, index: number) => (
                      <option
                        key={`masam-${option.label}#${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.masam && errors && errors.masam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.masam}
                  </FormHelperText>
                )}
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={pakshamLabel}
                    htmlFor="shradham-paksham"
                    error={
                      touched && touched.paksham && errors && errors.paksham
                        ? true
                        : false
                    }
                  >
                    Paksham{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="paksham"
                    component={Select}
                    labelWidth={pakshamLabelWidth}
                    inputProps={{
                      id: "shradham-paksham"
                    }}
                    error={
                      touched && touched.paksham && errors && errors.paksham
                        ? true
                        : false
                    }
                  >
                    <option value="" hidden></option>
                    {Shared.PAKSHAM.map((option, index: number) => (
                      <option
                        key={`paksham-${option.label}#${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.paksham && errors && errors.paksham && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.paksham}
                  </FormHelperText>
                )}
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={thithiLabel}
                    htmlFor="shradham-thithi"
                    error={
                      touched && touched.thithi && errors && errors.thithi
                        ? true
                        : false
                    }
                  >
                    Thithi{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="thithi"
                    component={Select}
                    labelWidth={thithiLabelWidth}
                    inputProps={{
                      id: "shradham-thithi"
                    }}
                    error={
                      touched && touched.thithi && errors && errors.thithi
                        ? true
                        : false
                    }
                  >
                    <option value="" hidden></option>
                    {Shared.THITHI.map((option, index: number) => (
                      <option
                        key={`thithi-${option.label}#${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.thithi && errors && errors.thithi && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.thithi}
                  </FormHelperText>
                )}
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={panchangamLabel}
                    htmlFor="contact-panchangam"
                    error={
                      touched &&
                      touched.panchangam &&
                      errors &&
                      errors.panchangam
                        ? true
                        : false
                    }
                  >
                    Panchangam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="panchangam"
                    component={Select}
                    labelWidth={panchangamLabelWidth}
                    inputProps={{
                      id: "contact-panchangam"
                    }}
                    error={
                      touched &&
                      touched.panchangam &&
                      errors &&
                      errors.panchangam
                        ? true
                        : false
                    }
                  >
                    <option value="" hidden></option>
                    {Shared.PANCHANGAM.map((option: string, index: number) => (
                      <option
                        key={`panchangam-${option}#${index}`}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.panchangam && errors && errors.panchangam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.panchangam}
                  </FormHelperText>
                )}
              </CardContent>
              <DialogActions>
                <Button
                  size="small"
                  disabled={isSubmitting}
                  onClick={props.handleSubmit}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="primary"
                  disabled={!isValid || isSubmitting}
                  onClick={() => submitForm()}
                >
                  {isSubmitting ? <CircularProgress /> : "Submit"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};

export default AddShradhamDialog;
