import * as React from "react";
import { Typography } from "@material-ui/core";
import Form, { FormSkeleton } from "../form";

interface AddProps {}

const Add: React.FunctionComponent<AddProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Add contact
      </Typography>
      <React.Suspense fallback={<FormSkeleton />}>
        <Form />
      </React.Suspense>
    </>
  );
};

export default Add;
