/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
  CircularProgress,
  DialogActions,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  StepLabel,
  Step,
  Stepper,
  Divider
} from "@material-ui/core";
import * as React from "react";
import { SubscriptionStoreContext } from "../../../app";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router";
import "firebase/auth";
import { useFirebaseApp } from "reactfire";
import { useCheckout } from "../../../hooks";
import { Plan, PLAN_VARIANT, Info } from "../../../components";
import { Link } from "react-router-dom";

interface CheckoutPlanProps {}

const CheckoutPlan: React.FC<CheckoutPlanProps> = observer(props => {
  let isComponentLoaded: boolean;
  const [isCheckoutAvailable, setIsCheckoutAvailable] = React.useState<boolean>(
    false
  );

  const [isPaymentModalClosed, setIsPaymentModalClosed] = React.useState<
    boolean
  >(false);

  const [activeStep, setActiveStep] = React.useState<number>(0);

  useCheckout({
    callback: () => {
      if (isComponentLoaded) {
        setIsCheckoutAvailable(true);
      }
    }
  });

  const subscriptionStore = React.useContext(SubscriptionStoreContext);
  React.useEffect(() => {
    isComponentLoaded = true;
    return () => {
      isComponentLoaded = false;
      subscriptionStore.clearCheckoutState();
    };
  }, []);
  const firebaseApp = useFirebaseApp();
  const user = firebaseApp.auth().currentUser;

  const onDissmissCallback = () => {
    setIsPaymentModalClosed(true);
    if (subscriptionStore.isProcessingPaymentVerification) {
      setActiveStep(2);
    }
  };

  const handleProceedToCheckout = async () => {
    await subscriptionStore.createSubscriptionRequest();
    setActiveStep(1);
    subscriptionStore.processSubscriptionPayment(user, onDissmissCallback);
  };

  const handleRetryPayment = async () => {
    subscriptionStore.processSubscriptionPayment(user, onDissmissCallback);
  };

  return (
    <>
      {!subscriptionStore.chosenPlan && <Redirect to="/settings/plans" />}
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <Typography variant="h2" gutterBottom>
            Order details
          </Typography>
          <Card>
            <CardContent>
              <Stepper activeStep={activeStep} alternativeLabel>
                <Step>
                  <StepLabel>Review</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Payment</StepLabel>
                </Step>
              </Stepper>
              <Divider />
              {activeStep === 0 && (
                <>
                  <Table aria-label="order items table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5">Item</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5">Quatity</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5">Price</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscriptionStore.chosenPlan && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">
                                Pandit Diary Membership
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {subscriptionStore.chosenPlan.item.name}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="body1">x 1</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="body1">
                                ₹&nbsp;
                                {subscriptionStore.chosenPlan.item.amount / 100}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body1">Total</Typography>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">
                              <Typography variant="h4">
                                ₹&nbsp;
                                {subscriptionStore.chosenPlan.item.amount / 100}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                  <Divider />
                </>
              )}
              {activeStep === 1 && (
                <>
                  {!isPaymentModalClosed ? (
                    <Info
                      body=""
                      picture="/images/undraw_Credit_card_3ed6.svg"
                      title=""
                    />
                  ) : (
                    <Info
                      body="You have yet to pay for your order. Click on the 'RETRY PAYMENT' button from below to complete the payment."
                      picture="/images/undraw_Credit_card_3ed6.svg"
                      title="Payment is pending"
                    />
                  )}
                </>
              )}
              {activeStep > 1 && (
                <>
                  {subscriptionStore.isProcessingPaymentVerification && (
                    <Info
                      body="Your payment is being processed. Thank you for your patience."
                      picture="/images/undraw_process_e90d.svg"
                      title="Processing payment"
                    />
                  )}
                  {!subscriptionStore.isProcessingPaymentVerification &&
                    !subscriptionStore.isPaymentVerified && (
                      <Info
                        body="This could happen for a variety of reasons but is rare. You might have to retry the whole process. We're sorry for the inconvenience."
                        picture="/images/undraw_access_denied_6w73.svg"
                        title="Payment failed"
                      />
                    )}
                  {!subscriptionStore.isProcessingPaymentVerification &&
                    subscriptionStore.isPaymentVerified && (
                      <Info
                        body="Congradulations! You successfully bought a Pandit Diary Membership. It might take some time to reflect the membership details in the settings page. However you can click the 'VIEW MY MEMBERSHIP' button from below to check your membership details."
                        picture="/images/undraw_order_confirmed_1m3v.svg"
                        title="Order confirmed"
                      />
                    )}
                </>
              )}
            </CardContent>
            <DialogActions>
              {activeStep === 0 && (
                <>
                  <Button
                    color="primary"
                    disabled={
                      !isCheckoutAvailable ||
                      subscriptionStore.isCreatingSubscription
                    }
                    onClick={handleProceedToCheckout}
                  >
                    {isCheckoutAvailable &&
                    !subscriptionStore.isCreatingSubscription ? (
                      "Checkout"
                    ) : (
                      <CircularProgress />
                    )}
                  </Button>
                </>
              )}
              {activeStep === 1 && (
                <>
                  {isPaymentModalClosed && (
                    <Button
                      color="primary"
                      disabled={!isCheckoutAvailable}
                      onClick={handleRetryPayment}
                    >
                      Retry payment
                    </Button>
                  )}
                </>
              )}
              {activeStep > 1 && (
                <>
                  {subscriptionStore.isProcessingPaymentVerification && (
                    <Button color="primary" disabled>
                      <CircularProgress />
                    </Button>
                  )}
                  {!subscriptionStore.isProcessingPaymentVerification &&
                    !subscriptionStore.isPaymentVerified && (
                      <>
                        <Button
                          color="primary"
                          component={Link}
                          to="/settings/plans"
                        >
                          Start fresh
                        </Button>
                        <Button color="primary">Retry this order</Button>
                      </>
                    )}
                  {!subscriptionStore.isProcessingPaymentVerification &&
                    subscriptionStore.isPaymentVerified && (
                      <Button color="primary" component={Link} to="/settings">
                        View my membership
                      </Button>
                    )}
                </>
              )}
            </DialogActions>
          </Card>
        </Grid>
        <Grid item xs={12} md>
          <Typography variant="h2" gutterBottom>
            Chosen plan
          </Typography>
          {subscriptionStore.chosenPlan && (
            <>
              {subscriptionStore.chosenPlan && (
                <Plan
                  plan={subscriptionStore.chosenPlan}
                  variant={PLAN_VARIANT.WITHOUT_ACTIONS}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
});

export default CheckoutPlan;
