import * as Shared from "../shared";

export function createSodagumbams({
  masigams,
  masigamForm,
}: {
  masigams: Array<Shared.Event.Masigam | undefined>;
  masigamForm: Shared.Form.Masigam;
}): Array<Shared.Event.Sodagumbam | Shared.Event.AabdhigaSodagumbam | undefined> {
  return masigams.map((masigam: Shared.Event.Masigam | undefined) => {
    if (!masigam) {
      return undefined;
    }

    const { masam, paksham, thithi, masigamThithi, ...restOfMasigam } = masigam;

    //const masigamDate = new Date(masigam.date);
    //const sodagumbamDate = masigamDate.setDate(masigamDate.getDate() - 1);
    const sodagumbamDate = new Date(masigam.date);
    sodagumbamDate.setDate(sodagumbamDate.getDate() - 1);

    const sodagumbamName =
      masigam.masam === masigamForm.masam
        ? `Aabdhiga Sodagumbam for ${masigamForm.forName}`
        : `Sodagumbam for ${masigamForm.forName}`;
    // TODO: Confirm from Vaidhy about the changes in event name

    const sodagumbam: Shared.Event.Sodagumbam | Shared.Event.AabdhigaSodagumbam = {
      ...restOfMasigam,
      eventName: sodagumbamName,
      date: sodagumbamDate,
    };

    return sodagumbam;
  });
}
