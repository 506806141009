import * as React from "react";
import {
  Button,
  styled,
  Fab,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowBackIosSharp as PreviousIcon,
  ArrowForwardIosSharp as NextIcon,
  Event as CalendarIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { ContactSkeleton as EventSkeleton, Events as RenderEvents, EVENTS_VARIANT } from "../../components";
import { KeyboardDatePicker, MaterialUiPickersDate } from "@material-ui/pickers";
import FilterForm from "./filter-form";

const EventsGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const DaySwitchFilterCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #ff3d00 30%, #b22a00 90%)",
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: theme.palette.common.white,
}));

const DaySwitchFilterCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1),
  "&:last-child": {
    paddingBottom: theme.spacing(1),
  },
}));

const FilterCardContent = DaySwitchFilterCardContent;

const DaySwitchFilter = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const DaySwitchLabel = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const StyledCalendarIcon = styled(CalendarIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

interface EventsProps {}

const Events: React.FunctionComponent<EventsProps> = (props) => {
  const getStart = (date?: Date): number => {
    const start = new Date(new Date(date || new Date()).setHours(0, 0, 0, 0)).setDate(
      new Date(date || new Date()).getDate()
    );
    return start;
  };

  const getEnd = (date?: Date): number => {
    const end = new Date(new Date(date || new Date()).setHours(0, 0, 0, 0)).setDate(
      new Date(date || new Date()).getDate() + 1
    );
    return end;
  };

  const [searchBy, setSearchBy] = React.useState<"date" | "filters">("date");
  const [date, setDate] = React.useState<Date>(new Date());
  const [start, setStart] = React.useState<number>(getStart());
  const [end, setEnd] = React.useState<number>(getEnd());

  const [thithi, setThithi] = React.useState<string>("");

  const [openCalendar, setOpenCalendar] = React.useState<boolean>(false);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDateClick = () => {
    setOpenCalendar(true);
  };

  const handleCalendarClose = () => {
    setOpenCalendar(false);
  };

  const handleCalendarDateNext = () => {
    const nextDate = new Date(new Date(date).setDate(date.getDate() + 1));
    setDate(nextDate);
    setStart(getStart(nextDate));
    setEnd(getEnd(nextDate));
  };

  const handleCalendarDatePrevious = () => {
    const previousDate = new Date(new Date(date).setDate(date.getDate() - 1));
    setDate(previousDate);
    setStart(getStart(previousDate));
    setEnd(getEnd(previousDate));
  };

  const handleCalendarDateChange = (changedDate?: MaterialUiPickersDate) => {
    if (!changedDate || (changedDate && changedDate === date)) {
      return;
    }
    setDate(changedDate);
    setStart(getStart(changedDate));
    setEnd(getEnd(changedDate));
    setOpenCalendar(false);
  };

  const handleToggleSearchBy = () => {
    setSearchBy(searchBy === "date" ? "filters" : "date");
    setThithi("");
  };

  const handleFilterSearch = (thithi: string) => {
    setThithi(thithi);
  };

  return (
    <>
      <Grid container justify="flex-end" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button size="large" variant="contained" color="primary" component={Link} to="/events/add">
            Add Event
          </Button>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button size="large" variant="contained" onClick={handleToggleSearchBy}>
            Search by {searchBy === "date" ? "thithi" : "date"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {searchBy === "date" && (
            <DaySwitchFilterCard>
              <CardActionArea onClick={handleDateClick} component="div">
                <DaySwitchFilterCardContent>
                  <DaySwitchFilter>
                    <Fab
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleCalendarDatePrevious();
                      }}
                    >
                      <PreviousIcon />
                    </Fab>
                    <DaySwitchLabel>
                      <StyledCalendarIcon fontSize={smallScreen ? "small" : "default"} />
                      <Typography variant={smallScreen ? "h5" : "h4"} color="inherit">
                        {date.toDateString().toUpperCase()}
                      </Typography>
                    </DaySwitchLabel>
                    <Fab
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleCalendarDateNext();
                      }}
                    >
                      <NextIcon />
                    </Fab>
                  </DaySwitchFilter>
                </DaySwitchFilterCardContent>
              </CardActionArea>
            </DaySwitchFilterCard>
          )}
          {searchBy === "filters" && (
            <Card>
              <FilterCardContent>
                <FilterForm handleSubmit={handleFilterSearch} />
              </FilterCardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <KeyboardDatePicker
        style={{ position: "absolute", visibility: "hidden" }}
        onClose={handleCalendarClose}
        open={openCalendar}
        margin="normal"
        id="date-picker-dialog"
        format="dd/MM/yyyy"
        value={date}
        onChange={handleCalendarDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
      <EventsGridContainer container spacing={3}>
        <React.Suspense
          fallback={[1, 2].map((val) => (
            <Grid key={`contact-skeleton#${val}`} item xs={12} md={6}>
              <EventSkeleton />
            </Grid>
          ))}
        >
          {searchBy === "date" && date && start && end && (
            <RenderEvents variant={EVENTS_VARIANT.FILTER_BY_DATE} start={start} end={end} />
          )}
          {searchBy === "filters" && thithi && (
            <RenderEvents thithi={thithi} variant={EVENTS_VARIANT.FILTER_BY_THITHI} />
          )}
        </React.Suspense>
      </EventsGridContainer>
    </>
  );
};

export default Events;
