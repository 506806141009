import * as React from "react";
import { useUser } from "reactfire";
import * as firebase from "firebase/app";
import "firebase/auth";
import { Redirect } from "react-router-dom";

interface LinkMobileHandlerProps {}

const LinkMobileHandler: React.FC<LinkMobileHandlerProps> = props => {
  const [redirect, setRedirect] = React.useState<boolean | undefined>();

  const user = useUser<firebase.User>();

  React.useEffect(() => {
    if (user) {
      setRedirect(!user.phoneNumber);
    }
  }, [user]);

  return <>{redirect && <Redirect to="/settings/linkmobile" />}</>;
};

export default LinkMobileHandler;
