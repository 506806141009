import React from "react";
import { ErrorInfo } from "../../components";

const NotFound: React.FC = () => {
  return (
    <>
      <ErrorInfo
        title="404: The page you are looking for isn’t here"
        body="You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation"
        picture="/images/undraw_page_not_found_su7k.svg"
      />
    </>
  );
};

export default NotFound;
