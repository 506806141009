import React from "react";
import {
  match,
  Redirect,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";
import * as Views from "../views";
import * as Errors from "../errors";

export const Routes: React.FC = () => {
  const routeMatch = useRouteMatch() as match<{}>;
  const { path } = routeMatch;
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Errors.RegistrationFailed>
            <Views.Register />
          </Errors.RegistrationFailed>
        </Route>
        <Route exact path={`${path}/otp`}>
          <Errors.PhoneAuthFailed>
            <Views.OTP />
          </Errors.PhoneAuthFailed>
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
