import {
  Button,
  Card,
  CardContent,
  Chip,
  DialogActions,
  Typography,
  Grid,
  Avatar,
  Theme,
  makeStyles,
  createStyles,
  styled,
  colors,
  withStyles,
  useTheme,
  ListItem,
  ListItemIcon,
  List,
  ListItemText
} from "@material-ui/core";
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  Warning as UnverifiedIcon
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import * as firebase from "firebase/app";
import * as React from "react";
import "firebase/auth";
import { useUser, useFirebaseApp } from "reactfire";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { ListItemProps } from "@material-ui/core/ListItem";
import { observer } from "mobx-react-lite";
import {
  PasswordConfirmation,
  PASSWORD_CONFIRMATION_VARIANT
} from "../../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigAvatar: {
      height: 60,
      width: 60
    },
    icon: {
      minWidth: theme.spacing(4)
    }
  })
);

const StyledChip = withStyles({
  root: {
    border: "none"
  },
  colorPrimary: {
    color: colors.amber.A700
  },
  iconColorPrimary: {
    color: colors.amber.A700
  }
})(Chip);

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2)
}));

const ContactList = styled(List)({
  paddingBottom: 0
});

const ContactListIcon = withStyles(theme => ({
  root: {
    minWidth: theme.spacing(4)
  }
}))(ListItemIcon);

const ContactListItem = styled(ListItem as React.FC<ListItemProps>)(
  ({ theme }) => ({
    [theme.breakpoints.down("xs")]: {
      width: `calc(100vw - ${theme.spacing(6)}px - ${theme.spacing(4)}px)`
    },
    padding: 0
  })
);

const AvatarGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: `calc(60px + ${theme.spacing(2)}px)`
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0
  },
  rect: {
    marginBottom: 0
  }
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const ProfileSkeleton: React.FC = props => {
  const theme = useTheme();
  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <AvatarGridItem item xs>
              <Skeleton variant="circle" width={60} height={60} />
            </AvatarGridItem>
            <Grid item xs>
              <Skeleton width={180} />
              <StyledSkeleton width={120} />
            </Grid>
          </Grid>
          <NegativeSpace />
          <Skeleton component="span" width={180} />
          <StyledSkeleton component="span" />
        </CardContent>
        <DialogActions>
          <StyledSkeleton
            component="span"
            width={60}
            height={theme.spacing(3)}
          />
          <StyledSkeleton
            component="span"
            width={110}
            height={theme.spacing(3)}
          />
        </DialogActions>
      </Card>
    </>
  );
};

interface ProfileProps {}

const Profile: React.FC<ProfileProps & RouteComponentProps> = observer(
  props => {
    const [passwordDialogOpen, setPasswordDialogOpen] = React.useState<boolean>(
      false
    );
    const [isSendingEmail, setIsSendingEmail] = React.useState<boolean>(false);
    const [emailSent, setEmailSent] = React.useState<boolean>(false);

    const classes = useStyles();
    const firebaseApp = useFirebaseApp();
    const user = useUser<firebase.User>();

    const handlePasswordDialogClose = () => {
      setPasswordDialogOpen(false);
    };

    const handlePasswordDialogOpen = () => {
      setPasswordDialogOpen(true);
    };

    const handleSendEmail = async () => {
      setIsSendingEmail(true);

      try {
        const auth = firebaseApp.auth();
        if (!user.email) {
          throw new Error(
            "Can't change password because user's email is not found in the app"
          );
        }
        await auth.sendPasswordResetEmail(user.email);
        setEmailSent(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsSendingEmail(false);
      }
    };

    return (
      <>
        <PasswordConfirmation
          emailSent={emailSent}
          open={passwordDialogOpen}
          isSendingEmail={isSendingEmail}
          handleDialogClose={handlePasswordDialogClose}
          handleSendEmail={handleSendEmail}
          variant={PASSWORD_CONFIRMATION_VARIANT.CHANGE_PASSWORD}
        />
        <Card>
          <CardContent>
            <Grid container>
              <AvatarGridItem item xs>
                {user.photoURL && (
                  <Avatar
                    alt="Person"
                    className={classes.bigAvatar}
                    src={user.photoURL}
                  />
                )}
                {!user.photoURL && user.displayName && (
                  <Avatar className={classes.bigAvatar}>
                    <Typography variant="h1">{user.displayName[0]}</Typography>
                  </Avatar>
                )}
              </AvatarGridItem>
              <Grid item xs>
                <Typography variant="h4">{user.displayName}</Typography>
                <Typography color="textSecondary">Pandit</Typography>
              </Grid>
            </Grid>
            <ContactList dense>
              <ContactListItem>
                <ContactListIcon>
                  <PhoneIcon fontSize="small" />
                </ContactListIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" noWrap>
                      {user.phoneNumber}
                    </Typography>
                  }
                />
              </ContactListItem>
              <ContactListItem>
                <ContactListIcon>
                  <EmailIcon fontSize="small" />
                </ContactListIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" noWrap>
                      {user.email}
                    </Typography>
                  }
                />
              </ContactListItem>
            </ContactList>
            {!user.emailVerified && (
              <>
                <StyledChip
                  variant="outlined"
                  size="small"
                  label="Unverified email"
                  color="primary"
                  icon={<UnverifiedIcon />}
                />
                <Typography variant="body2">
                  Please verify your registered email. Follow the instructions
                  in the verification email that was sent to you after
                  registration.
                </Typography>
              </>
            )}
          </CardContent>
          <DialogActions>
            <Button size="small" component={Link} to="/settings/editprofile">
              Edit
            </Button>
            <Button size="small" onClick={handlePasswordDialogOpen}>
              Change password
            </Button>
          </DialogActions>
        </Card>
      </>
    );
  }
);

export default withRouter(Profile);
