import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  styled
} from "@material-ui/core";
import { Info, INFO_VARIANT } from "../ErrorInfo";
import { Link } from "react-router-dom";

const NegativeSpace = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export enum PASSWORD_CONFIRMATION_VARIANT {
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  CHANGE_PASSWORD = "CHANGE_PASSWORD"
}

interface SharedPasswordConfirmationProps {
  open: boolean;
  isSendingEmail: boolean;
  emailSent: boolean;
  handleSendEmail(): void;
  handleDialogClose(): void;
}

type PasswordConfirmationProps =
  | (SharedPasswordConfirmationProps & {
      variant: PASSWORD_CONFIRMATION_VARIANT.FORGOT_PASSWORD;
    })
  | (SharedPasswordConfirmationProps & {
      variant: PASSWORD_CONFIRMATION_VARIANT.CHANGE_PASSWORD;
    });

const PasswordConfirmation: React.FC<PasswordConfirmationProps> = props => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        disableBackdropClick={props.isSendingEmail}
        disableEscapeKeyDown={props.isSendingEmail}
        onClose={() => {
          setIsChecked(false);
          props.handleDialogClose();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {props.emailSent ? "Email sent" : "Have your Email access?"}
        </DialogTitle>
        <DialogContent>
          <Info
            picture={
              props.emailSent
                ? "/images/undraw_message_sent_1030.svg"
                : "/images/undraw_envelope_n8lc.svg"
            }
            variant={INFO_VARIANT.MINIMAL}
          />
          <NegativeSpace />
          <DialogContentText align="center">
            {props.emailSent
              ? "Email has been sent. Check your email for further instructions."
              : "We'll send an Email with instructions to change your password. Make sure you have the access to your registered Email before proceeding."}
          </DialogContentText>
          {!props.emailSent && (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={props.isSendingEmail}
                  color="primary"
                  onClick={toggleCheckbox}
                />
              }
              label={
                <Typography color="textSecondary" variant="body1">
                  I understand and have access to my Email.
                </Typography>
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          {props.variant === PASSWORD_CONFIRMATION_VARIANT.CHANGE_PASSWORD && (
            <Button
              autoFocus
              disabled={props.isSendingEmail}
              onClick={() => {
                setIsChecked(false);
                props.handleDialogClose();
              }}
              size="small"
            >
              Cancel
            </Button>
          )}
          {!props.emailSent &&
            props.variant === PASSWORD_CONFIRMATION_VARIANT.FORGOT_PASSWORD && (
              <Button
                autoFocus
                disabled={props.isSendingEmail}
                onClick={() => {
                  setIsChecked(false);
                  props.handleDialogClose();
                }}
                size="small"
              >
                Cancel
              </Button>
            )}
          {props.emailSent &&
            props.variant === PASSWORD_CONFIRMATION_VARIANT.CHANGE_PASSWORD && (
              <Button
                onClick={() => {
                  setIsChecked(false);
                  props.handleDialogClose();
                }}
                color="primary"
                size="small"
              >
                Ok
              </Button>
            )}
          {props.emailSent &&
            props.variant === PASSWORD_CONFIRMATION_VARIANT.FORGOT_PASSWORD && (
              <Button color="primary" size="small" component={Link} to="/login">
                Login Now
              </Button>
            )}
          {!props.emailSent && (
            <Button
              disabled={props.isSendingEmail || !isChecked}
              onClick={props.handleSendEmail}
              color="primary"
              size="small"
            >
              {props.isSendingEmail ? <CircularProgress /> : "Submit"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PasswordConfirmation;
