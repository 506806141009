import * as Shared from "../shared";

export function createMasigams({
  contact,
  contactId,
  events,
  masamInInt,
  masigamForm,
  panchangamDateSheet,
}: {
  contact: Shared.Contact;
  contactId: string;
  events: undefined[];
  masamInInt: number;
  masigamForm: Shared.Form.Masigam;
  panchangamDateSheet: Shared.PanchangamDateSheet;
}): Array<Shared.Event.Masigam | Shared.Event.VarushaAabdhigam | undefined> {
  const { lastYear, forName, masam, paksham, thithi, panchangam } = masigamForm;
  return events.map((event, index) => {
    const currentMasam = `${(lastYear ? 0 : masamInInt) + index + 1}`;
    const dateKey = `${currentMasam}-${paksham}-${thithi}`;
    const date = panchangamDateSheet[dateKey];

    if (!date) {
      // NOTE: If none of the events needs to be added if this block ever gets executed
      // NOTE: Then throw an error and stop the further processing
      return undefined;
    }

    const masigamDate = date.toDate();
    const masigamNumber = (lastYear ? 12 - masamInInt + index : index) + 2;
    const masigamName =
      currentMasam === masam ? `Varusha Aabdhigam for ${forName}` : `Masigam (${masigamNumber}) for ${forName}`;
    // TODO: Confirm from Vaidhy about the changes in event name

    const { address, emailId, landmark, mobileOne, name, nickname, sutram, telOne, vedam } = contact;
    const masigam: Shared.Event.Masigam | Shared.Event.VarushaAabdhigam = {
      name,
      vedam,
      panchangam,
      masam: currentMasam,
      paksham,
      thithi,
      masigamThithi: dateKey,
      ...(sutram && { sutram }),
      ...(address && { address }),
      ...(emailId && { emailId }),
      ...(landmark && { landmark }),
      ...(mobileOne && { mobileOne }),
      ...(nickname && { nickname }),
      ...(telOne && { telOne }),
      contactId,
      masigamId: "",
      date: masigamDate,
      eventName: masigamName,
    };

    return masigam;
  });
}
