import {
  Button,
  CircularProgress,
  Container,
  Typography,
  Card,
  CardContent,
  styled,
  DialogActions
} from "@material-ui/core";
import { Field, Form, Formik, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import * as Yup from "yup";
import { RegisterStoreContext } from "../../app";
import { ErrorInfo } from "../../components";
import { useFirebaseApp } from "reactfire";

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export interface IOTPForm {
  otp: string;
}

const OTP: React.FC<RouteComponentProps> = observer(props => {
  // local datastructures used
  const validationSchema = Yup.object<IOTPForm>({
    otp: Yup.string()
      .matches(/^\d{6}$/, "OTP is invalid")
      .required("OTP is required")
  });

  // hooks used
  const [, setBoundryError] = useState();

  const [credentialInUseError, setCredentialInUseError] = useState<
    undefined | "auth/credential-already-in-use"
  >();

  const firebaseApp = useFirebaseApp();

  const registerStore = useContext(RegisterStoreContext);

  // handlers used
  const handleSubmit = async (
    { otp }: IOTPForm,
    { setSubmitting, setFieldError }: FormikActions<IOTPForm>
  ) => {
    try {
      if (!registerStore.confirmationResult) {
        throw new Error(
          "Invalid state in register store! At this point the confirmationResult should have been set."
        );
      }

      const credential = await registerStore.confirmationResult.confirm(otp);

      if (!credential.user) {
        throw new Error(
          "User not found in UserCredential returned after successful linking of Phone Authentication!"
        );
      }
      firebaseApp
        .firestore()
        .collection("users")
        .doc(credential.user.uid)
        .update({
          phoneNumber: credential.user.phoneNumber
        });

      props.history.push("/");
    } catch (error) {
      const errorCode:
        | "auth/invalid-verification-code"
        | "auth/missing-verification-code"
        | "auth/credential-already-in-use" // NOTE: For some weird reason getting this error after confirming the OTP
        | undefined = error.code;

      switch (errorCode) {
        case "auth/invalid-verification-code": {
          setFieldError("otp", "OTP provided is incorrect");
          break;
        }
        case "auth/missing-verification-code": {
          setFieldError("otp", error.message);
          break;
        }
        case "auth/credential-already-in-use": {
          setCredentialInUseError(errorCode);
          break;
        }
        default: {
          setBoundryError(() => {
            throw error;
          });
        }
      }
    } finally {
      setSubmitting(false);
      registerStore.clear();
    }
  };

  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <>
      {!credentialInUseError && !registerStore.confirmationResult && (
        <Redirect to="/" />
      )}
      <StyledContainer maxWidth="md">
        {!credentialInUseError && (
          <>
            <Typography variant="h2" gutterBottom>
              OTP
            </Typography>
            <Typography gutterBottom>
              Enter the OTP received on your mobile number in the below input
              field.
            </Typography>
          </>
        )}
        <Card>
          <CardContent>
            {credentialInUseError ? (
              <ErrorInfo
                title="This phone number is already in use by some other user account."
                body="You have successfully created an account but your phone number is not yet recorded. Don't worry. You can always set the phone number from the profile section the our app. Click 'retry' and use a different mobile number."
                picture="/images/undraw_product_teardown_elol.svg"
              />
            ) : (
              <Formik
                onSubmit={handleSubmit}
                initialValues={{ otp: "" }}
                validationSchema={validationSchema}
              >
                {({ isValid, isSubmitting }) => (
                  <Form>
                    <Field
                      type="tel"
                      name="otp"
                      label="OTP"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Button
                      fullWidth
                      disabled={!isValid || isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {isSubmitting ? <CircularProgress /> : "Submit"}
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </CardContent>
          {credentialInUseError && (
            <DialogActions>
              <Button color="primary" size="small" onClick={handleRetry}>
                Retry
              </Button>
            </DialogActions>
          )}
        </Card>
      </StyledContainer>
    </>
  );
});

export default withRouter(OTP);
