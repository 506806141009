import * as React from "react";
import { Paper, InputBase, IconButton, styled } from "@material-ui/core";
import { Close as ResetIcon, Search as SearchIcon } from "@material-ui/icons";

const SearchBase = styled(Paper)(({ theme }) => ({
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  width: "100%"
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1
}));

const SearchIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1)
}));

const ResetIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1)
}));

interface SearchProps {
  placeholder: string;
  handleSearchTyping(text: string): void;
  handleSearchClick(): void;
}

const Search: React.FC<SearchProps> = props => {
  const [text, setText] = React.useState<string>("");

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    props.handleSearchTyping(event.target.value);
  };

  const handleOnReset = () => {
    setText("");
  };

  return (
    <SearchBase>
      <SearchIconButton onClick={props.handleSearchClick}>
        <SearchIcon />
      </SearchIconButton>
      <SearchInput
        value={text}
        onChange={handleOnChange}
        placeholder={props.placeholder}
      />
      {text.length > 0 && (
        <ResetIconButton onClick={handleOnReset}>
          <ResetIcon />
        </ResetIconButton>
      )}
    </SearchBase>
  );
};

export default Search;
