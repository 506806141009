export const tamilNewYearStartDate: { [k: string]: string } = {
  "2019": "04/14/2019",
  "2020": "04/14/2020",
  "2021": "04/14/2021",
  "2022": "04/14/2022",
  "2023": "04/14/2023",
  "2024": "04/14/2024",
  "2025": "04/14/2025",
  "2026": "04/14/2026",
  "2027": "04/14/2027",
  "2028": "04/14/2028",
  
};
