import { action, observable } from "mobx";
import * as Shared from "../shared";
import * as firebase from "firebase/app";
import "firebase/functions";

export enum LINK_STATUS {
  ALREADY_USED = "ALREADY_USED",
  READY_FOR_USE = "READY_FOR_USE",
  SUCCESSFULLY_USED = "SUCCESSFULLY_USED",
  FAILED = "FAILED",
}

export class LinkStore {
  // private verifyLink = firebase.functions().httpsCallable("verifyLink");
  private addContactAndShradham = firebase.functions().httpsCallable("addContactAndShradham");

  @observable public lkid?: string;
  @observable public uid?: string;
  @observable public isValid?: boolean;
  @observable public linkStatus?: LINK_STATUS;
  @observable public contact?: Shared.Contact;
  @observable public shradhams: Shared.Shradham[] = [];

  @action public setContact = (contact: Shared.Contact) => {
    this.contact = contact;
  };

  @action public setUid = (uid: string) => {
    this.uid = uid;
  };

  @action public setLkid = (lkid: string) => {
    this.lkid = lkid;
  };

  @action public setLinkStatus = (status: LINK_STATUS) => {
    this.linkStatus = status;
  };

  @action public addShradham = (shradham: Shared.Shradham) => {
    this.shradhams.push(shradham);
  };

  @action public removeShradham = (index: number) => {
    this.shradhams.splice(index, 1);
  };

  // @action public verifyLinkRequest = async () => {
  //   try {
  //     if (!this.uid || !this.lkid) {
  //       throw new Error("Can't verify link. Set the 'uid' and 'lkid' before calling this function");
  //     }
  //     const result: { data: LINK_STATUS } = await this.verifyLink({
  //       uid: this.uid,
  //       lkid: this.lkid,
  //     });
  //     this.linkStatus = result.data;
  //     console.log("link status is", this.linkStatus);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  @action public addContactAndShradhamRequest = async () => {
    try {
      if (!(this.uid && this.lkid && this.contact && this.shradhams)) {
        throw new Error(
          "Can't verify link. Set the 'contact', 'shradhams', 'uid' and 'lkid' before calling this function"
        );
      }

      const panchangam = this.contact.panchangam;
      const shradhams = this.shradhams.map((shradham) => ({
        ...shradham,
        panchangam,
      }));

      const result: {
        data: LINK_STATUS;
      } = await this.addContactAndShradham({
        uid: this.uid,
        lkid: this.lkid,
        contact: this.contact,
        shradhams,
      });
      this.linkStatus = result.data;
      console.log("link status is", this.linkStatus);
    } catch (error) {
      console.error(error);
    }
  };
}
