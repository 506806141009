import { Button, Typography, styled, colors, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import * as React from "react";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import { Membership } from "../../shared";
import { TestUtilStoreContext } from "../../app";
import { observer } from "mobx-react-lite";

const UpgradeGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: colors.grey[50],
}));

const UpgradeMedia = styled("div")({
  height: 80,
  textAlign: "center",
  "& > img": {
    height: "100%",
    width: "auto",
  },
});

const UpgradeContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const UpgradeActions = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 2, 0),
  display: "flex",
  justifyContent: "center",
}));

interface IUpgradeProps {}

const Upgrade: React.FC<IUpgradeProps> = observer((props) => {
  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const membershipRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("membership")
    .doc("current");
  const membershipSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(membershipRef);
  const membership: Membership | undefined = membershipSnapshot.data() as Membership | undefined;

  const testUtilStore = React.useContext(TestUtilStoreContext);

  const today = Math.round(testUtilStore.today / 1000);
  return (
    <>
      {(!membership ||
        (membership && !membership.isActive) ||
        (membership && membership.isActive && today > membership.nextBillingDate)) && (
        <UpgradeGrid container direction="column" justify="center" alignItems="center">
          <UpgradeMedia>
            <img alt="Upgrade" src="/images/undraw_resume_folder_2_arse.svg" />
          </UpgradeMedia>
          <UpgradeContent>
            <Typography align="center" gutterBottom variant="h6">
              Upgrade
            </Typography>
            <Typography align="center" variant="body2">
              Upgrade to Pandit Diary membership and enjoy an uninterrupted service
            </Typography>
          </UpgradeContent>
          <UpgradeActions>
            <Button component={Link} to="/settings/plans" color="primary" variant="contained" disabled>
              Upgrade
            </Button>
          </UpgradeActions>
        </UpgradeGrid>
      )}
    </>
  );
});

export default Upgrade;
