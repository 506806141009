import * as React from "react";
import { Typography } from "@material-ui/core";
import Form, { CONTACT_FORM_VARIANT, FormSkeleton } from "../form";
import { useParams } from "react-router";

interface EditProps {}

const Edit: React.FunctionComponent<EditProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams<{ contactId: string }>();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Edit contact
      </Typography>
      <React.Suspense fallback={<FormSkeleton />}>
        <Form variant={CONTACT_FORM_VARIANT.EDIT} contactId={params.contactId} />
      </React.Suspense>
    </>
  );
};

export default Edit;
