import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as firebase from "firebase/app";
import React from "react";
import { FirebaseAppProvider } from "reactfire";
import {AppFailed} from "../errors";
import * as config from "../config";
import * as Routes from "../routes";
import 'firebase/analytics';
import 'firebase/performance';
import "../styles/index.css";
import { theme } from "../theme";
import * as Sentry from '@sentry/browser';
import {
  LinkStore,
  SubscriptionStore,
  RegisterStore,
  ToastStore,
  // TODO: Remove after use
  TestUtilStore
} from "../stores";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

Sentry.init({dsn: config.sentry.url});
firebase.initializeApp(config.firebase);
firebase.analytics();
firebase.performance();

export const SubscriptionStoreContext = React.createContext(
  new SubscriptionStore()
);
export const RegisterStoreContext = React.createContext(new RegisterStore());
export const ToastStoreContext = React.createContext(new ToastStore());
// TODO: Remove after use
export const TestUtilStoreContext = React.createContext(new TestUtilStore());
export const LinkStoreContext = React.createContext(new LinkStore());

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <AppFailed>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FirebaseAppProvider firebaseConfig={config.firebase}>
            <Routes.Global />
          </FirebaseAppProvider>
        </MuiPickersUtilsProvider>
        </AppFailed>
      </MuiThemeProvider>
    </>
  );
};

export default App;
