import { Button, Grid, IconButton, Link, Theme, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";
import { Field, Form, Formik, FormikActions } from "formik";
import { TextField as FormikTextField } from "formik-material-ui";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { Link as RouterLink, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { useFirebaseApp } from "reactfire";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/marriage.JPG)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

export interface ILoginForm {
  email: string;
  password: string;
}

const Login: React.FC<RouteComponentProps> = (props) => {
  const validationSchema = Yup.object<ILoginForm>({
    email: Yup.string().email("Enter a vaild email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const classes = useStyles();

  const [, setBoundryError] = useState();

  const firebaseApp = useFirebaseApp();

  const handleBack = () => {
    props.history.goBack();
  };

  const handleSubmit = async (values: ILoginForm, { setSubmitting, setFieldError }: FormikActions<ILoginForm>) => {
    try {
      const auth = firebaseApp.auth();
      const { email, password } = values;
      const userCredential: firebase.auth.UserCredential = await auth.signInWithEmailAndPassword(email, password);
      if (userCredential.user) {
        firebaseApp.analytics().logEvent('login',{})
        firebaseApp.analytics().setUserId(userCredential.user.uid);
      }
      
    } catch (error) {
      const errorCode: "auth/invalid-email" | "auth/user-disabled" | "auth/user-not-found" | "auth/wrong-password" =
        error.code;

      switch (errorCode) {
        case "auth/invalid-email": {
          setFieldError("email", error.message);
          break;
        }
        case "auth/wrong-password": {
          setFieldError("password", error.message);
          break;
        }
        case "auth/user-not-found": {
          setFieldError("email", error.message);
          break;
        }
        default: {
          setBoundryError(() => {
            throw error;
          });
        }
      }
    } finally {
      setSubmitting(false);
      props.history.push("/");
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1"></Typography>
              <div>
                <Typography className={classes.name} variant="body1"></Typography>
                <Typography className={classes.bio} variant="body2"></Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}></IconButton>
            </div>
            <div className={classes.contentBody}>
              <Formik
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                initialValues={{ email: "", password: "" }}
              >
                {({ isValid, isSubmitting }) => (
                  <Form className={classes.form}>
                    <Typography className={classes.title} variant="h2">
                      Pandit Diary
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      Login with email address
                    </Typography>
                    <label>
                      <Field
                        type="email"
                        name="email"
                        label="Email"
                        component={FormikTextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                      />
                    </label>
                    <label>
                      <Field
                        type="password"
                        name="password"
                        label="Password"
                        component={FormikTextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                      />
                    </label>
                    <Button
                      className={classes.signInButton}
                      disabled={!isValid || isSubmitting}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? <CircularProgress /> : "Log in now"}
                    </Button>
                    <Typography variant="body1">
                      <Link component={RouterLink} to="/forgotpassword" variant="h6">
                        Forgot password?
                      </Link>
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      Don't have an account?{" "}
                      <Link component={RouterLink} to="/register" variant="h6">
                        Register
                      </Link>
                    </Typography>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Login);
