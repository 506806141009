// TODO: Confirm if the redundant <xxx>paksham required in the label
export const PAKSHAM = [
  { value: "0", label: "Krishnapaksham" },
  { value: "1", label: "Suklapaksham" }
];

const paksham: [string, string][] = PAKSHAM.reduce(
  (acc, curr, index) => {
    if (index === 0) {
      return [[curr.value, curr.label]];
    }
    return [...acc, [curr.value, curr.label]];
  },
  [["", ""]]
);

export const pakshamAsMap = new Map(paksham);
