import * as React from "react";
import { SearchBoxProvided } from "react-instantsearch-core";
import { connectSearchBox } from "react-instantsearch-dom";
import { Paper, InputBase, IconButton, styled } from "@material-ui/core";
import { Close as ResetIcon, Search as SearchIcon } from "@material-ui/icons";

const SearchBase = styled(Paper)(({ theme }) => ({
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  width: "100%"
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1
}));

const SearchIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1)
}));

const ResetIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1)
}));

const CustomSearchBox: React.FC<SearchBoxProvided> = ({
  currentRefinement,
  isSearchStalled,
  refine
}) => {
  return (
    <form noValidate action="" role="search">
      <SearchBase>
        <SearchIconButton>
          <SearchIcon />
        </SearchIconButton>
        <SearchInput
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
          placeholder="Search contacts"
        />
        {currentRefinement.length > 0 && (
          <ResetIconButton onClick={() => refine()}>
            <ResetIcon />
          </ResetIconButton>
        )}
      </SearchBase>
    </form>
  );
};

const SearchBox = connectSearchBox(CustomSearchBox);

export default SearchBox;
