import * as React from "react";
import { Typography, styled, Container } from "@material-ui/core";
import Form from "./form"; // { FormSkeleton }

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

interface LinkMobileProps {}

const LinkMobile: React.FunctionComponent<LinkMobileProps> = props => {
  return (
    <>
      <StyledContainer maxWidth="md">
        <Typography variant="h2" gutterBottom>
          Link Mobile
        </Typography>
        <React.Suspense fallback={<p>Loading form...</p>}>
          <Form />
        </React.Suspense>
      </StyledContainer>
    </>
  );
};

export default LinkMobile;
