import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Theme,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Field, Form, Formik, FormikActions } from "formik";
import { Checkbox, TextField } from "formik-material-ui";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Link as RouterLink, Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import * as Yup from "yup";
import { IRegisterForm } from "../../shared";
import { RegisterStoreContext, ToastStoreContext } from "../../app";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/marriage.JPG)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-14px",
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}));

const Register: React.FC<RouteComponentProps> = observer((props) => {
  // local datastructures used
  const validationSchema = Yup.object<IRegisterForm>({
    name: Yup.string().min(3, "Name should contain minimun 3 characters").required("Name is required"),
    mobile: Yup.string()
      .min(10, "Mobile Number is Invalid")
      .max(10, "Mobile Number is Invalid")
      .matches(/[0-9]/, "Mobile Number should contain only numbers")
      .required("Mobile Number is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    confirmPassword: Yup.string().test("password-match", "Password did not match", function (val) {
      return this.parent["password"] === val;
    }),
    readTerms: Yup.bool()
      .test("read-terms", "Accept the terms before proceeding", function (val) {
        return val;
      })
      .required("Accepting the terms and conditions is required"),
  });

  // hooks used
  const [, setBoundryError] = useState();

  const classes = useStyles();

  const firebaseApp = useFirebaseApp();

  // TODO: Remove this only for testing purpose
  // firebase.auth().settings.appVerificationDisabledForTesting = true;

  const registerStore = useContext(RegisterStoreContext);
  registerStore.setRecaptchaVerifier(
    new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      size: "invisible",
    })
  );

  const toastStore = useContext(ToastStoreContext);

  // handlers used
  const handleBack = () => {
    props.history.goBack();
  };

  const handleSubmit = async (
    values: IRegisterForm,
    { setSubmitting, setFieldError }: FormikActions<IRegisterForm>
  ) => {
    try {
      if (!registerStore.recaptchaVerifier) {
        throw new Error("Invalid state in register store! At this point the recaptchaVerifier should have been set.");
      }

      window.recaptchaId = await registerStore.recaptchaVerifier.render();
      await registerStore.recaptchaVerifier.verify();

      const auth = firebaseApp.auth();
      const credential = await auth.createUserWithEmailAndPassword(values.email, values.password);
      firebaseApp.analytics().logEvent('sign_up',{});
      if (!credential.user) {
        throw new Error(
          "User not found in UserCredential returned after successful UserWithEmailAndPassword Authentication!"
        );
      }
      // TODO: uncomment the line below after manual testing
      // credential.user.sendEmailVerification();

      const now = new Date();
      // const validTill = Math.round(
      //   new Date().setMonth(now.getMonth() + 1) / 1000
      // );
      const { email, name, mobile } = values;
      credential.user.updateProfile({
        displayName: name,
      });
      firebaseApp
        .firestore()
        .collection("users")
        .doc(credential.user.uid)
        .set({
          createdAt: now.getTime(),
          displayName: name,
          email,
          phoneNumberWhenRegistering: `+91${mobile}`,
          photoURL: "",
          role: "PANDIT",
          // trial: {
          //   startAt: Math.round(now.getTime() / 1000),
          //   validTill
          // },
          uid: credential.user.uid,
        });

      const confirmationResult = await credential.user.linkWithPhoneNumber(
        `+91${values.mobile}`,
        registerStore.recaptchaVerifier
      );

      setSubmitting(false);
      registerStore.setConfirmationResult(confirmationResult);
    } catch (error) {
      // handled and applicable errors
      const errorCode:
        | "auth/email-already-in-use"
        | "auth/invalid-email"
        | "auth/weak-password"
        | "auth/captcha-check-failed"
        | "auth/invalid-phone-number"
        | "auth/credential-already-in-use"
        // unhandled and not applicable errors
        | "auth/provider-already-linked"
        | "auth/missing-phone-number"
        | "auth/user-disabled"
        // some rare errors handled with a common generic react error boundry
        | "auth/quota-exceeded"
        | "auth/operation-not-allowed"
        | "auth/internal-error"
        | undefined = error.code;

      switch (errorCode) {
        case "auth/email-already-in-use": {
          setFieldError("email", error.message);
          break;
        }
        case "auth/invalid-email": {
          setFieldError("email", error.message);
          break;
        }
        case "auth/weak-password": {
          setFieldError("password", error.message);
          break;
        }
        case "auth/captcha-check-failed": {
          if (!window.grecaptcha || !window.recaptchaId) {
            break;
          }
          window.grecaptcha.reset(window.recaptchaId);
          toastStore.addToast({
            body: "Re-submit the register form and prove that you are a human.",
            severity: "warning",
            title: "Verification failed!",
          });
          break;
        }
        default: {
          setBoundryError(() => {
            throw error;
          });
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {registerStore.confirmationResult && <Redirect to="/register/otp" />}
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1"></Typography>
                <div>
                  <Typography className={classes.name} variant="body1"></Typography>
                  <Typography className={classes.bio} variant="body2"></Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <Formik
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  initialValues={{
                    name: "",
                    mobile: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    readTerms: undefined,
                  }}
                >
                  {({ isSubmitting, isValid }) => (
                    <Form className={classes.form}>
                      <Typography className={classes.title} variant="h2">
                        Create new account
                      </Typography>
                      <Typography color="textSecondary" gutterBottom>
                        Use your email to create new account
                      </Typography>
                      <Field
                        type="string"
                        name="name"
                        label="Name"
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                      />
                      <Field
                        type="tel"
                        name="mobile"
                        label="Mobile"
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                        }}
                      />
                      <Field
                        type="email"
                        name="email"
                        label="Email"
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                      />
                      <Field
                        type="password"
                        name="password"
                        label="Password"
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                      />
                      <Field
                        type="password"
                        name="confirmPassword"
                        label="Confirm password"
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                      />
                      <FormControlLabel
                        control={
                          <Field
                            type="checkbox"
                            name="readTerms"
                            component={Checkbox}
                            label="test label"
                            color="primary"
                          />
                        }
                        label={
                          <Typography color="textSecondary" variant="body1">
                            I have read the{" "}
                            <Link color="primary" component={RouterLink} to="#" underline="always" variant="h6">
                              Terms and Conditions
                            </Link>
                          </Typography>
                        }
                      />
                      <Typography variant="body2">
                        This site is protected by reCAPTCHA and the Google{" "}
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                      </Typography>
                      <Button
                        className={classes.signUpButton}
                        disabled={!isValid || isSubmitting}
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {isSubmitting ? <CircularProgress /> : "Sign up now"}
                      </Button>
                      <Typography color="textSecondary" variant="body1">
                        Have an account?{" "}
                        <Link component={RouterLink} to="/login" variant="h6">
                          Sign in
                        </Link>
                      </Typography>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
});

export default withRouter(Register);
