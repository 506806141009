import {
  Card,
  CardContent,
  Typography,
  styled,
  withStyles,
  useTheme,
  Button,
  DialogActions,
  colors,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import * as firebase from "firebase/app";
import * as React from "react";
import "firebase/database";
import "firebase/auth";
import Timeago from "react-timeago";
// import { useUser, useFirebaseApp } from "reactfire";
import { Link } from "react-router-dom";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import { Membership, Trial } from "../../../shared";
// import { TestUtilStoreContext } from "../../../app";
import { observer } from "mobx-react-lite";

const TrialMembershipCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: "white",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "fit-content",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "none",
  },
}));

const MembershipCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: "white",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "fit-content",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "none",
  },
}));

const StyledSkeletonCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  maxWidth: 320,
  [theme.breakpoints.down("xs")]: {
    maxWidth: "none",
  },
}));

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

const CancelSubscriptionButton = withStyles({
  contained: {
    color: "white",
    backgroundColor: colors.red[900],
  },
})(Button);

export const SubscriptionSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledSkeletonCard>
        <CardContent>
          <StyledSkeleton width={110} />
          <Skeleton width={180} height={theme.spacing(4)} />
          <NegativeSpace />
          <Skeleton width={230} />
          <StyledSkeleton />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={90} height={theme.spacing(3)} />
        </DialogActions>
      </StyledSkeletonCard>
    </>
  );
};

interface SubscriptionProps {}

const Subscription: React.FC<SubscriptionProps> = observer((props) => {
  // const testUtilStore = React.useContext(TestUtilStoreContext);

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const trialRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("membership").doc("trial");
  const trialSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(trialRef);
  const trial: Trial | undefined = trialSnapshot.data() as Trial | undefined;

  const membershipRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("membership")
    .doc("current");
  const membershipSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(membershipRef);
  const membership: Membership | undefined = membershipSnapshot.data() as Membership | undefined;

  return (
    <>
      {membership && (
        <MembershipCard>
          <CardContent>
            <Typography color="inherit">{membership.planName}</Typography>
            <Typography variant="h2">{membership.planDescription}</Typography>
            <NegativeSpace />
            {/* {testUtilStore.isTodayFake ? (
              <>
                <Typography variant="subtitle1" color="inherit" gutterBottom>
                  {membership.productType === "subscription"
                    ? "Renews on "
                    : "Expires on "}
                  <Typography component="span" variant="h4" color="inherit">
                    {new Date(membership.nextBillingDate * 1000).toDateString()}
                  </Typography>
                </Typography>
              </>
            ) : (
              <> */}
            <Typography variant="subtitle1" color="inherit" gutterBottom>
              {membership.productType === "subscription" ? "Renews in " : "Expires in "}
              <Typography component="span" variant="h4" color="inherit">
                <Timeago date={membership.nextBillingDate * 1000} />
              </Typography>
            </Typography>
            {/* </>
            )} */}
            <Typography variant="body2" color="inherit">
              Buy a plan from below for an uninterrupted experience.
            </Typography>
          </CardContent>
          <DialogActions>
            {membership.productType === "subscription" && (
              <CancelSubscriptionButton variant="contained" size="small">
                Cancel plan
              </CancelSubscriptionButton>
            )}
            <Button component={Link} variant="contained" size="small" to="/settings/plans">
              Change plan
            </Button>
          </DialogActions>
        </MembershipCard>
      )}
      {!membership && trial && (
        <TrialMembershipCard>
          <CardContent>
            <Typography color="inherit">Current plan</Typography>
            <Typography variant="h2">Free trial</Typography>
            <NegativeSpace />
            <Typography variant="subtitle1" color="inherit" gutterBottom>
              Expires in{" "}
              <Typography component="span" variant="h4" color="inherit">
                <Timeago date={trial.validTill * 1000} />
              </Typography>
            </Typography>
            <Typography variant="body2" color="inherit">
              Buy a plan from below for an uninterrupted experience.
            </Typography>
          </CardContent>
          <DialogActions>
            <Button component={Link} variant="contained" size="small" to="/settings/plans" disabled>
              Select plan
            </Button>
          </DialogActions>
        </TrialMembershipCard>
      )}
    </>
  );
});

export default Subscription;
