export const RASI = [
  { value: "1", label: "Mesham" },
  { value: "2", label: "Rishabam" },
  { value: "3", label: "Midhunam" },
  { value: "4", label: "Kadagam" },
  { value: "5", label: "Simmam" },
  { value: "6", label: "Kanni" },
  { value: "7", label: "Thulam" },
  { value: "8", label: "Vrichigam" },
  { value: "9", label: "Dhanusu" },
  { value: "10", label: "Magaram" },
  { value: "11", label: "Kumbam" },
  { value: "11", label: "Meenam" },
];

const rasi: [string, string][] = RASI.reduce(
  (acc, curr, index) => {
    if (index === 0) {
      return [[curr.value, curr.label]];
    }
    return [...acc, [curr.value, curr.label]];
  },
  [["", ""]]
);

export const rasiAsMap = new Map(rasi);
