import * as React from "react";
import { Typography } from "@material-ui/core";
import {
  EventForm,
  FormSkeleton,
  EVENT_FORM_VARIANT
} from "../../../components";
import { useParams } from "react-router-dom";

interface EventProps {}

const Event: React.FunctionComponent<EventProps> = props => {
  const params = useParams<{ eventId: string }>();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Event event
      </Typography>
      <React.Suspense
        fallback={<FormSkeleton variant={EVENT_FORM_VARIANT.WITHOUT_CONTACT} />}
      >
        <EventForm
          eventId={params.eventId}
          variant={EVENT_FORM_VARIANT.WITHOUT_CONTACT_EDIT}
        />
      </React.Suspense>
    </>
  );
};

export default Event;
