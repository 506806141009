import React from "react";
import { Redirect, Route, Switch, useRouteMatch, match } from "react-router-dom";
// import * as Containers from "../containers";
import * as Views from "../views";
// import * as Errors from "../errors";

export const Routes: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const routeMatch = useRouteMatch() as match<{}>;
  const { path } = routeMatch;
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Views.Settings />
        </Route>
        <Route exact path={`${path}/plans`}>
          <Views.Setting.Plans />
        </Route>
        <Route exact path={`${path}/checkoutplan`}>
          <Views.Setting.CheckoutPlan />
        </Route>
        <Route exact path={`${path}/editprofile`}>
          <Views.Setting.EditProfile />
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
