import * as React from "react";
import {
  Card,
  CardContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
  styled,
  withStyles,
  useTheme,
  CardActionArea,
  Dialog,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  DialogContentText,
  Checkbox,
  DialogTitle,
  useMediaQuery,
  TextField,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { useFirebaseApp, useUser } from "reactfire";
import * as Shared from "../../shared";
import { ToastStoreContext } from "../../app";
import * as Utils from "../../utils";

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const ReviewContactSkeleton: React.FC = (props) => {
  const showShareButton = (navigator as Navigator & {
    share: (options: { url?: string; title?: string; text?: string }) => void;
  }).share;
  const theme = useTheme();
  return (
    <>
      <Card>
        <CardContent>
          <Skeleton width={220} />
          <StyledSkeleton width={110} />
          <NegativeSpace />
          <Skeleton width={220} />
          <StyledSkeleton />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
          {showShareButton && <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />}
        </DialogActions>
      </Card>
    </>
  );
};

interface ReviewContactProps {
  dataid: string;
  contact: Shared.Contact;
  shradhams: Shared.Shradham[];
}

const ReviewContact: React.FC<ReviewContactProps> = (props) => {
  const toastStore = React.useContext(ToastStoreContext);

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState<boolean>(false);
  const [addToContactsDialogOpen, setAddToContactsDialogOpen] = React.useState<boolean>(false);
  const [addInProgress, setAddInProgress] = React.useState<boolean>(false);
  const [fullInfo, setFullInfo] = React.useState<boolean>(false);

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();
  const dataRef = firebaseApp.firestore().collection("custentry").doc(user.uid).collection("data").doc(props.dataid);
  const contactRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("contacts");
  const shradhamRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("shardham");

  const handleDeleteDialogOpen = async () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = async () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    setDeleteInProgress(true);
    try {
      await dataRef.delete();
      toastStore.addToast({
        body: "Successfully deleted!",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteInProgress(false);
      handleDeleteDialogClose();
    }
  };

  const handleAddToContactsDialogOpen = async () => {
    setAddToContactsDialogOpen(true);
  };

  const handleAddToContactsDialogClose = async () => {
    setAddToContactsDialogOpen(false);
  };

  const handleAddToContacts = async (nickname?: string) => {
    setAddInProgress(true);
    const panchangamRef = firebaseApp.firestore().collection("panchangam");
    const eventsRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("events");
    try {
      const currentPanchangamYear = Utils.getCurrentPanchangamYear();
      const { contact, shradhams } = props;
      const { id } = await contactRef.add({
        ...contact,
        ...(nickname && { nickname }),
      });
      const shradhamPromises = shradhams.map((shradham) => {
        return shradhamRef
          .add({
            ...shradham,
            contactId: id,
          })
          .then(async (shardhamRecord) => {
            const { panchangam } = shradham;
            const panchangamDocName = Utils.getPanchangamDocName(panchangam, currentPanchangamYear);
            const panchangamSnapshot = await panchangamRef.doc(panchangamDocName).get();
            const panchangamDateSheet = panchangamSnapshot.data() as Shared.PanchangamDateSheet;
            const shradhamForm: Shared.Form.Shradham = shradham as Shared.Form.Shradham;
            const shradhamEvent: Shared.Event.Shradham = Utils.createShradham({
              contact,
              contactId: id,
              panchangamDateSheet,
              shradhamForm,
            });
            shradhamEvent.shardhamId = shardhamRecord.id;
            return eventsRef.add(shradhamEvent);
          });
      });

      await Promise.all(shradhamPromises);
      await dataRef.delete();
      toastStore.addToast({
        body: "Successfully added!",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setAddInProgress(false);
      handleAddToContactsDialogClose();
    }
  };

  const handleInfoClick = async () => {
    setFullInfo(!fullInfo);
  };

  return (
    <>
      <DeleteDialog
        open={deleteDialogOpen}
        deleteInProgress={deleteInProgress}
        onClose={handleDeleteDialogClose}
        handleDelete={handleDelete}
      />
      <AddToContactsDialog
        open={addToContactsDialogOpen}
        addInProgress={addInProgress}
        onClose={handleAddToContactsDialogClose}
        handleAddToContacts={handleAddToContacts}
      />
      <Card>
        <CardActionArea onClick={handleInfoClick}>
          <CardContent>
            <Typography variant="h5">{props.contact.name}</Typography>
            <Typography variant="body1" color="textSecondary">
              {props.contact.nickname}
            </Typography>
            {(props.contact.address || props.contact.landmark) && (
              <>
                <NegativeSpace />
                {props.contact.landmark && <Typography variant="h5">{props.contact.landmark}</Typography>}
                {props.contact.address && (
                  <Typography variant="body1" color="textSecondary">
                    {props.contact.address}
                  </Typography>
                )}
              </>
            )}
            {fullInfo && (props.contact.emailId || props.contact.mobileOne || props.contact.telOne) && (
              <>
                <NegativeSpace />
                {props.contact.emailId && <Typography variant="h5">{props.contact.emailId}</Typography>}
                {props.contact.mobileOne && <Typography variant="h6">{props.contact.mobileOne}</Typography>}
                {props.contact.telOne && (
                  <Typography variant="body1" color="textSecondary">
                    {props.contact.telOne}
                  </Typography>
                )}
              </>
            )}
            {fullInfo && (props.contact.sutram || props.contact.panchangam || props.contact.vedam) && (
              <>
                <NegativeSpace />
                <Typography variant="h5">{props.contact.panchangam.toUpperCase()} Panchangam</Typography>
                {props.contact.vedam && (
                  <Typography variant="body1" color="textSecondary">
                    {props.contact.vedam.toUpperCase()} Vedam
                  </Typography>
                )}
                {props.contact.sutram && (
                  <Typography variant="body1" color="textSecondary">
                    {props.contact.sutram.toUpperCase()} Sutram
                  </Typography>
                )}
              </>
            )}
            {fullInfo && props.shradhams && props.shradhams.length > 0 && (
              <>
                <NegativeSpace />
                <Typography variant="h5">Shradham(s)</Typography>
                {props.shradhams.map((shradham, index) => (
                  <React.Fragment key={`shradham#${index}`}>
                    <Typography variant="h6">Shradham for {shradham.forName}</Typography>
                    <Typography variant="h6" color="textSecondary">
                      {" "}
                      {`${Shared.masamAsMap.get(shradham.masam)} - `}
                      {`${Shared.pakshamAsMap.get(shradham.paksham)} - `}
                      {`${Shared.thithiAsMap.get(shradham.thithi)}`}
                    </Typography>
                  </React.Fragment>
                ))}
              </>
            )}
          </CardContent>
        </CardActionArea>
        <DialogActions>
          <Grid container justify="space-between" alignItems="center">
            <div>
              <IconButton size="small" onClick={handleDeleteDialogOpen}>
                <DeleteIcon color="disabled" />
              </IconButton>
            </div>
            <div>
              <Button size="small" style={{ marginRight: 8 }} onClick={handleInfoClick}>
                {!fullInfo ? "Info" : "Hide"}
              </Button>
              <Button size="small" color="primary" onClick={handleAddToContactsDialogOpen}>
                Add to contacts
              </Button>
            </div>
          </Grid>
        </DialogActions>
      </Card>
    </>
  );
};

export interface DeleteDialogProps {
  open: boolean;
  deleteInProgress: boolean;
  onClose(): void;
  handleDelete(): void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = (props) => {
  const [confirmCheckbox, setConfirmCheckbox] = React.useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleConfirmCheckboxClick = () => {
    setConfirmCheckbox(!confirmCheckbox);
  };
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        disableBackdropClick={props.deleteInProgress}
        disableEscapeKeyDown={props.deleteInProgress}
        onClose={() => {
          setConfirmCheckbox(false);
          props.onClose();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Delete this review contact?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Event(s) attached to this review contact along with the review contact record will be permamnently lost!
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox disabled={props.deleteInProgress} color="primary" onClick={toggleConfirmCheckboxClick} />
            }
            label={
              <Typography color="textSecondary" variant="body1">
                I understand and want to delete this review contact.
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={props.deleteInProgress}
            onClick={() => {
              setConfirmCheckbox(false);
              props.onClose();
            }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={props.deleteInProgress || !confirmCheckbox}
            onClick={props.handleDelete}
            color="primary"
            size="small"
          >
            {props.deleteInProgress ? <CircularProgress /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export interface AddToContactsDialogProps {
  open: boolean;
  addInProgress: boolean;
  onClose(): void;
  handleAddToContacts(nickName?: string): void;
}

export const AddToContactsDialog: React.FC<AddToContactsDialogProps> = (props) => {
  const [nickname, setNickname] = React.useState<string>("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNickNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.currentTarget.value);
  };
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        disableBackdropClick={props.addInProgress}
        disableEscapeKeyDown={props.addInProgress}
        onClose={() => {
          setNickname("");
          props.onClose();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add this review contact?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Event(s) attached to this contact along with the contact will be added to your contacts list! Add a nick
            name to this review contact if you want by filling the below input before proceeding.
          </DialogContentText>
          <TextField
            variant="outlined"
            fullWidth
            value={nickname}
            onChange={handleNickNameChange}
            label="Nick Name"
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={props.addInProgress}
            onClick={() => {
              setNickname("");
              props.onClose();
            }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={props.addInProgress}
            onClick={() => props.handleAddToContacts(nickname)}
            color="primary"
            size="small"
          >
            {props.addInProgress ? <CircularProgress /> : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewContact;
