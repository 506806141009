import { AppBar, Toolbar, Typography, styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.h3,
    color: "inherit",
  },
}));

interface ITopbarProps {
  className?: string;
}

const Topbar: React.FC<ITopbarProps> = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
      <Toolbar>
        <Title variant="h3" color="inherit" noWrap>
          Pandit Diary
        </Title>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
