import React from "react";
import { Redirect, Route, Switch, useRouteMatch, match } from "react-router-dom";
// import * as Containers from "../containers";
import * as Views from "../views";
// import * as Errors from "../errors";

export const Routes: React.FC = () => {
  const routeMatch = useRouteMatch() as match<{}>;
  const { path } = routeMatch;
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Views.Events />
        </Route>
        <Route exact path={`${path}/:eventId/edit`}>
          <Views.Event.Edit />
        </Route>
        <Route exact path={`${path}/add`}>
          <Views.Event.Add />
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
