export const MASAM = [
  { value: "1", label: "Chittirai" },
  { value: "2", label: "Vaigasi" },
  { value: "3", label: "Aani" },
  { value: "4", label: "Aadi" },
  { value: "5", label: "Aavani" },
  { value: "6", label: "Puratasi" },
  { value: "7", label: "Iypasi" },
  { value: "8", label: "Karthigai" },
  { value: "9", label: "Margazhi" },
  { value: "10", label: "Thai" },
  { value: "11", label: "Masi" },
  { value: "12", label: "Panguni" }
];

const masam: [string, string][] = MASAM.reduce(
  (acc, curr, index) => {
    if (index === 0) {
      return [[curr.value, curr.label]];
    }
    return [...acc, [curr.value, curr.label]];
  },
  [["", ""]]
);

export const masamAsMap = new Map(masam);
