import { colors } from "@material-ui/core";
import createPalette from "@material-ui/core/styles/createPalette";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    white?: string;
    black?: string;
    success?: SimplePaletteColorOptions;
    info?: SimplePaletteColorOptions;
    warning?: SimplePaletteColorOptions;
    icon?: string;
    textLink?: string;
  }

  // allow configuration using `createPalette`
  interface PaletteOptions {
    white?: string;
    black?: string;
    success?: SimplePaletteColorOptions;
    info?: SimplePaletteColorOptions;
    warning?: SimplePaletteColorOptions;
    icon?: string;
    textLink?: string;
  }
}

const white = "#FFFFFF";
const black = "#000000";

export const palette = createPalette({
  white,
  black,
  textLink: colors.blue[600],
  primary: {
    contrastText: white,
    dark: "#b24e2e",
    main: "#ff7043",
    light: "#ff8c68",
  },
  secondary: {
    contrastText: white,
    dark: "#b22a00",
    main: "#ff3d00",
    light: "#ff6333",
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
});
