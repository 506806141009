import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  InputLabel,
  FormControl,
  Typography,
  Grid
} from "@material-ui/core";
import * as React from "react";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { Select } from "formik-material-ui";
import * as Yup from "yup";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import * as Shared from "../../shared";

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: theme.spacing(2) - 2
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34)
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0
  },
  rect: {
    marginBottom: 0
  }
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = props => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton width={230} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton
            component="span"
            width={70}
            height={theme.spacing(3)}
          />
          <StyledSkeleton
            component="span"
            width={60}
            height={theme.spacing(3)}
          />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface FilterForm {
  masam: string;
  paksham: string;
  thithi: string;
}

interface FormProps {
  handleSubmit: (thithi: string) => void;
}

const Form: React.FC<FormProps> = props => {
  const validationSchema = Yup.object<FilterForm>({
    masam: Yup.string().required("Masam is required"),
    paksham: Yup.string().required("Paksham is required"),
    thithi: Yup.string().required("Thithi is required")
  });

  const masamLabel = React.useRef<HTMLLabelElement>(null);
  const pakshamLabel = React.useRef<HTMLLabelElement>(null);
  const thithiLabel = React.useRef<HTMLLabelElement>(null);
  const [masamLabelWidth, setMasamLabelWidth] = React.useState<number>(0);
  const [pakshamLabelWidth, setPakshamLabelWidth] = React.useState<number>(0);
  const [thithiLabelWidth, setThithiLabelWidth] = React.useState<number>(0);
  React.useEffect(() => {
    setMasamLabelWidth(masamLabel.current!.offsetWidth);
    setPakshamLabelWidth(pakshamLabel.current!.offsetWidth);
    setThithiLabelWidth(thithiLabel.current!.offsetWidth);
  }, []);

  const handleSubmit = async (
    masigamForm: FilterForm,
    { setSubmitting }: FormikActions<FilterForm>
  ) => {
    const { masam, paksham, thithi } = masigamForm;
    props.handleSubmit(`${masam}-${paksham}-${thithi}`);
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          masam: "",
          paksham: "",
          thithi: ""
        }}
      >
        {({ isSubmitting, isValid, errors, touched }) => (
          <FormikForm>
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={masamLabel} htmlFor="shradham-masam">
                    Masam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="masam"
                    component={Select}
                    labelWidth={masamLabelWidth}
                    inputProps={{
                      id: "shradham-masam"
                    }}
                  >
                    <option value="" hidden></option>
                    {Shared.MASAM.map((option, index: number) => (
                      <option
                        key={`masam-${option.label}#${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                {touched && touched.masam && errors && errors.masam && (
                  <StyledFormHelperText>{errors.masam}</StyledFormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={pakshamLabel} htmlFor="shradham-paksham">
                    Paksham{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="paksham"
                    component={Select}
                    labelWidth={pakshamLabelWidth}
                    inputProps={{
                      id: "shradham-paksham"
                    }}
                  >
                    <option value="" hidden></option>
                    {Shared.PAKSHAM.map((option, index: number) => (
                      <option
                        key={`paksham-${option.label}#${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                {touched && touched.paksham && errors && errors.paksham && (
                  <StyledFormHelperText>{errors.paksham}</StyledFormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={thithiLabel} htmlFor="shradham-thithi">
                    Thithi{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="thithi"
                    component={Select}
                    labelWidth={thithiLabelWidth}
                    inputProps={{
                      id: "shradham-thithi"
                    }}
                  >
                    <option value="" hidden></option>
                    {Shared.THITHI.map((option, index: number) => (
                      <option
                        key={`thithi-${option.label}#${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                {touched && touched.thithi && errors && errors.thithi && (
                  <StyledFormHelperText>{errors.thithi}</StyledFormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={3}>
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress /> : "Search"}
                </Button>
              </Grid>
            </Grid>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default Form;
