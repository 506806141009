// TODO: Remove after use
import { action, observable, computed } from "mobx";

export class TestUtilStore {
  @observable today: number = new Date().getTime();

  @action public setToday = (today: number) => {
    this.today = today;
  };

  @computed public get isTodayFake() {
    return new Date().toDateString() !== new Date(this.today).toDateString();
  }
}
