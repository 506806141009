import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  InputLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as Shared from "../../../shared";
import { Ancestor } from "../../../shared";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={70} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface FormProps {
  contactId: string;
}

const Form: React.FC<FormProps> = (props) => {
  const validationSchema = Yup.object<Shared.Form.ModifyAncestor>({
    fatherGothram: Yup.string().required("Father Gothram is Required"),
  });

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const ancestorRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("contacts")
    .doc(props.contactId)
    .collection("relatives")
    .doc("ancestor");

  const ancestorSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(ancestorRef);
  const ancestor: Shared.Ancestor | undefined = ancestorSnapshot.data() as Shared.Ancestor | undefined;
  console.log(ancestor);
  const history = useHistory();

  const handleSubmit = async (
    ancestorForm: Shared.Form.ModifyAncestor,
    { setSubmitting }: FormikActions<Shared.Form.ModifyAncestor>
  ) => {
    const ancestorData: Ancestor = {
      fatherSideGothram: ancestorForm.fatherGothram,
      fatherGroup: {
        parent: ancestorForm.father || "",
        grandParent: ancestorForm.grandFather || "",
        greatGrandParent: ancestorForm.greatGrandFather || "",
        greatGreatGrandParent: ancestorForm.greatGreatGrandFather || "",
      },
      motherGroup: {
        parent: ancestorForm.mother || "",
        grandParent: ancestorForm.grandMother || "",
        greatGrandParent: ancestorForm.greatGrandMother || "",
        greatGreatGrandParent: ancestorForm.greatGreatGrandMother || "",
      },
      mothersFatherGroup: {
        parent: ancestorForm.mothersFather || "",
        grandParent: ancestorForm.mothersGrandFather || "",
        greatGrandParent: ancestorForm.mothersGreatGrandFather || "",
        greatGreatGrandParent: ancestorForm.mothersGreatGreatGrandFather || "",
      },
      mothersMotherGroup: {
        parent: ancestorForm.mothersMother || "",
        grandParent: ancestorForm.mothersGrandMother || "",
        greatGrandParent: ancestorForm.mothersGreatGrandMother || "",
        greatGreatGrandParent: ancestorForm.mothersGreatGreatGrandMother || "",
      },
      mothersSideGothram: ancestorForm.mothersSideGothram || "",
    };
    await ancestorRef.set(ancestorData, { merge: true });
    setSubmitting(false);
    history.push(`/contacts/${props.contactId}`);
  };

  return (
    <>
      {!props.contactId && <Redirect to="/404" />}
      <StyledCard>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            fatherGothram: (ancestor && ancestor.fatherSideGothram) || "",
            father: (ancestor && ancestor.fatherGroup && ancestor.fatherGroup.parent) || "",
            grandFather: (ancestor && ancestor.fatherGroup && ancestor.fatherGroup.grandParent) || "",
            greatGrandFather: (ancestor && ancestor.fatherGroup && ancestor.fatherGroup.greatGrandParent) || "",
            greatGreatGrandFather:
              (ancestor && ancestor.fatherGroup && ancestor.fatherGroup.greatGreatGrandParent) || "",
            mother: (ancestor && ancestor.motherGroup && ancestor.motherGroup.parent) || "",
            grandMother: (ancestor && ancestor.motherGroup && ancestor.motherGroup.grandParent) || "",
            greatGrandMother: (ancestor && ancestor.motherGroup && ancestor.motherGroup.greatGrandParent) || "",
            greatGreatGrandMother:
              (ancestor && ancestor.motherGroup && ancestor.motherGroup.greatGreatGrandParent) || "",
            mothersSideGothram: (ancestor && ancestor.mothersSideGothram) || "",
            mothersFather: (ancestor && ancestor.mothersFatherGroup && ancestor.mothersFatherGroup.parent) || "",
            mothersGrandFather:
              (ancestor && ancestor.mothersFatherGroup && ancestor.mothersFatherGroup.grandParent) || "",
            mothersGreatGrandFather:
              (ancestor && ancestor.mothersFatherGroup && ancestor.mothersFatherGroup.greatGrandParent) || "",
            mothersGreatGreatGrandFather:
              (ancestor && ancestor.mothersFatherGroup && ancestor.mothersFatherGroup.greatGreatGrandParent) || "",
            mothersMother: (ancestor && ancestor.mothersMotherGroup && ancestor.mothersMotherGroup.parent) || "",
            mothersGrandMother:
              (ancestor && ancestor.mothersMotherGroup && ancestor.mothersMotherGroup.grandParent) || "",
            mothersGreatGrandMother:
              (ancestor && ancestor.mothersMotherGroup && ancestor.mothersMotherGroup.greatGrandParent) || "",
            mothersGreatGreatGrandMother:
              (ancestor && ancestor.mothersMotherGroup && ancestor.mothersMotherGroup.greatGreatGrandParent) || "",
          }}
        >
          {({ isSubmitting, isValid, errors, touched }) => (
            <FormikForm>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <b>Father's Side Info</b>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      name="fatherGothram"
                      label={
                        <>
                          Father's Side Gothram{" "}
                          <Typography component="span" color="error">
                            *
                          </Typography>
                        </>
                      }
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      type="text"
                      name="father"
                      label="Father Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="grandFather"
                      label="Grand Father Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="greatGrandFather"
                      label="Great GrandFather Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="greatGreatGrandFather"
                      label="Great Great GrandFather Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      type="text"
                      name="mother"
                      label="Mother's Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="grandMother"
                      label="Grand Mothers Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="greatGrandMother"
                      label="Great Grand Mother's Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="greatGreatGrandMother"
                      label="Great Great Grand Mother's Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <b>Mothers Side Info</b>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      name="mothersSideGothram"
                      label={
                        <>
                          Mother's Side Gothram{" "}
                          <Typography component="span" color="error">
                            *
                          </Typography>
                        </>
                      }
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      type="text"
                      name="mothersFather"
                      label="Mother's Father"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="mothersGrandFather"
                      label="Mother's Grand Father"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="mothersGreatGrandFather"
                      label="Mother's Great Grand Father"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="mothersGreatGreatGrandFather"
                      label="Mother's Great Great Grand Father Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      type="text"
                      name="mothersMother"
                      label="Mother's Mother Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="mothersGrandMother"
                      label="Mother's Grand Mothers Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="mothersGreatGrandMother"
                      label="Mother's Great Grand Mother Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="mothersGreatGreatGrandMother"
                      label="Mother's Great Great Grand Mother's Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <DialogActions>
                <Button size="small" disabled={isSubmitting} component={Link} to={`/contacts/${props.contactId}`}>
                  Cancel
                </Button>
                <Button size="small" color="primary" disabled={!isValid || isSubmitting} type="submit">
                  {isSubmitting ? <CircularProgress /> : "Save"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};

export default Form;
