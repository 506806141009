import * as React from "react";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { Membership } from "../../shared";
import { observer } from "mobx-react-lite";
import { TestUtilStoreContext } from "../../app";
import {
  styled,
  Card,
  Grid,
  Typography,
  CardContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { Link, useLocation, Redirect } from "react-router-dom";

const WarningCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2)
}));

interface MembershipHandlerProps {}

const MembershipHandler: React.FC<MembershipHandlerProps> = observer(props => {
  const [showWarning, setShowWarning] = React.useState<boolean | undefined>();
  const [showUltimatum, setShowUltimatum] = React.useState<
    boolean | undefined
  >();
  const [redirect, setRedirect] = React.useState<boolean | undefined>();
  const location = useLocation<Location>();

  const testUtilStore = React.useContext(TestUtilStoreContext);
  const today = Math.round(testUtilStore.today / 1000);

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const membershipRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("membership")
    .doc("current");
  const membershipSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(
    membershipRef
  );
  const membership: Membership | undefined = membershipSnapshot.data() as
    | Membership
    | undefined;

  React.useEffect(() => {
    setShowWarning(
      membership &&
        today > membership.nextBillingDate &&
        today < membership.validTill &&
        !location.pathname.includes("settings")
    );
    setShowUltimatum(
      membership &&
        today > membership.validTill &&
        location.pathname.includes("settings")
    );
    setRedirect(
      membership &&
        today > membership.validTill &&
        !location.pathname.includes("settings")
    );
  }, [today, membership, location]);

  return (
    <>
      {redirect && <Redirect to="/settings" />}
      {showWarning && (
        <>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <WarningCard>
                <CardContent>
                  <Typography variant="h4">Important note</Typography>
                  <Typography color="inherit">
                    There is an issue with your membership. Please, resolve it
                    soon before you lose your access.
                  </Typography>
                </CardContent>
                <DialogActions>
                  <Button
                    component={Link}
                    variant="contained"
                    size="small"
                    to="/settings"
                  >
                    Settings
                  </Button>
                </DialogActions>
              </WarningCard>
            </Grid>
          </Grid>
        </>
      )}
      {showUltimatum && (
        <>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <WarningCard>
                <CardContent>
                  <Typography variant="h4">Important note</Typography>
                  <Typography color="inherit">
                    You've not made the payment for the membership. Please, pay
                    now to access the application.
                  </Typography>
                </CardContent>
                <DialogActions>
                  <Button variant="contained" size="small">
                    Pay now
                  </Button>
                </DialogActions>
              </WarningCard>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});

export default MembershipHandler;
