import * as React from "react";
import { Typography } from "@material-ui/core";
import { EventForm, FormSkeleton, EVENT_FORM_VARIANT } from "../../../components";
import { useParams } from "react-router-dom";

interface EditEventProps {}

const EditEvent: React.FunctionComponent<EditEventProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams<{ contactId: string; eventId: string }>();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Edit event
      </Typography>
      <React.Suspense fallback={<FormSkeleton variant={EVENT_FORM_VARIANT.WITH_CONTACT} />}>
        <EventForm
          eventId={params.eventId}
          contactId={params.contactId}
          variant={EVENT_FORM_VARIANT.WITH_CONTACT_EDIT}
        />
      </React.Suspense>
    </>
  );
};

export default EditEvent;
