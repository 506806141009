import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  Avatar,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { useUser, useFirebaseApp } from "reactfire";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { SkeletonProps } from "@material-ui/lab/Skeleton";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34),
}));

const BigAvatar = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
}));

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton width={240} />
          <Grid container justify="flex-start" alignItems="center">
            <Skeleton variant="circle" width={40} height={40} style={{ marginRight: 8 }} />
            <Skeleton variant="circle" width={60} height={60} />
          </Grid>
          <NegativeSpace />
          <Skeleton width={190} height={theme.spacing(4)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={70} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface EditProfileForm {
  photo?: File;
  name: string;
}

interface FormProps {}

const Form: React.FC<FormProps & RouteComponentProps> = (props) => {
  const [photoURL, setPhotoURL] = React.useState<string | ArrayBuffer | null>("");

  const firebaseApp = useFirebaseApp();
  const storageRef = firebaseApp.storage().ref();
  const user = useUser<firebase.User>();
  const userRef = firebaseApp.firestore().collection("users").doc(user.uid);

  const validationSchema = Yup.object<EditProfileForm>({
    photo: Yup.mixed(),
    name: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  });

  const handleSetFile = (file: File) => {
    let reader = new FileReader();

    reader.onloadend = () => {
      setPhotoURL(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleSubmit = async (
    values: EditProfileForm,
    { setSubmitting, setFieldError }: FormikActions<EditProfileForm>
  ) => {
    if (!user) {
      return;
    }

    if (!values.photo) {
      await user.updateProfile({
        displayName: values.name,
      });
      setSubmitting(false);
      props.history.push("/settings");
      return;
    }

    const file = values.photo;
    const profilePicRef = storageRef.child(`${user.uid}/profilepicture/${file.name}`);

    var metadata = {
      contentType: file.type,
    };

    var uploadTask = profilePicRef.put(file, metadata);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      undefined,
      function (error) {
        console.error(error);
      },
      async function () {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        const profileInfo = {
          photoURL: downloadURL,
          displayName: values.name,
        };
        await Promise.all([user.updateProfile(profileInfo), userRef.update(profileInfo)]);
        setSubmitting(false);
        props.history.push("/settings");
        return;
      }
    );
  };

  return (
    <>
      <StyledCard>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            photo: undefined,
            name: user.displayName || "",
          }}
        >
          {({ isSubmitting, isValid, setFieldValue, values }) => (
            <FormikForm>
              <CardContent>
                {/* <Typography color="textSecondary">You can edit your name and upload your profile picture.</Typography>

                <NegativeSpace /> */}

                {(photoURL || user.photoURL) && (
                  <>
                    <Grid container alignItems="center" spacing={2} style={{ marginBottom: "auto" }}>
                      <Grid item xs style={{ maxWidth: "fit-content" }}>
                        <Avatar alt="Person" src={(photoURL || user.photoURL) as string} />
                      </Grid>
                      <Grid item xs style={{ maxWidth: "fit-content" }}>
                        <BigAvatar alt="Person" src={(photoURL || user.photoURL) as string} />
                      </Grid>
                    </Grid>
                  </>
                )}
                {/*<input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  disabled={isSubmitting}
                  onChange={event => {
                    if (
                      !event.currentTarget.files ||
                      (event.currentTarget.files &&
                        event.currentTarget.files.length === 0)
                    ) {
                      return;
                    }
                    setFieldValue("photo", event.currentTarget.files[0]);
                    handleSetFile(event.currentTarget.files[0]);
                  }}
                /> 
                <label htmlFor="raised-button-file">
                  <Button disabled={isSubmitting} variant="contained" component="span">
                    {user.photoURL ? "Change" : "Upload"} Profile Picture
                  </Button>
                </label> */}

                <NegativeSpace />

                <Field type="text" name="name" label="Name" component={StyledTextField} fullWidth variant="outlined" />
              </CardContent>
              <DialogActions>
                <Button size="small" disabled={isSubmitting} component={Link} to="/settings">
                  Cancel
                </Button>
                <Button size="small" color="primary" disabled={!isValid || isSubmitting} type="submit">
                  {isSubmitting ? <CircularProgress /> : "Save"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};

export default withRouter(Form);
