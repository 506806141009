import * as React from "react";
import { ErrorInfo } from "../../components";
import * as Sentry from '@sentry/browser';

export interface IRegistrationFailedProps {}

export interface IRegistrationFailedState {
  hasError: boolean;
}

class RegistrationFailed extends React.Component<
  IRegistrationFailedProps,
  IRegistrationFailedState
> {
  constructor(props: IRegistrationFailedProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
       Sentry.captureException(error);

  });
    console.info("Caught registration failed error");
    console.info(errorInfo);
    console.error(error);
  }

  render() {
    return this.state.hasError ? (
      <ErrorInfo
        title="Failed to create your Account."
        picture="/images/undraw_profile_6l1l.svg"
      />
    ) : (
      this.props.children
    );
  }
}

export default RegistrationFailed;
