import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  InputLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField, Select } from "formik-material-ui";
import * as Yup from "yup";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import * as Shared from "../../../../shared";
import { LinkStoreContext } from "../../../../app";
import AddShradham from "./add-shradham";
import { observer } from "mobx-react-lite";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34),
  marginBottom: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={70} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </StyledCard>
    </>
  );
};

type ContactForm = Omit<Shared.Contact, "nickname">;

export type FormProps = {};

const Form: React.FC<FormProps> = observer((props) => {
  const linkStore = React.useContext(LinkStoreContext);

  const validationSchema = Yup.object<ContactForm>({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    landmark: Yup.string(),
    emailId: Yup.string().required("Email is required").email("Email is invalid"),
    panchangam: Yup.string().required("Panchangam is required"),
    mobileOne: Yup.string()
      .required("Mobile is required")
      .matches(/^\d{10}$/, "Mobile is invalid"),
    telOne: Yup.string().matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Telephone is invalid"
    ),
    vedam: Yup.string().required("Vedam is required"),
  });

  const vedamLabel = React.useRef<HTMLLabelElement>(null);
  const panchangamLabel = React.useRef<HTMLLabelElement>(null);
  const [vedamLabelWidth, setVedamLabelWidth] = React.useState<number>(0);
  const [panchangamLabelWidth, setPanchangamLabelWidth] = React.useState<number>(0);
  React.useEffect(() => {
    setVedamLabelWidth(vedamLabel.current!.offsetWidth);
    setPanchangamLabelWidth(panchangamLabel.current!.offsetWidth);
  }, []);

  const handleSubmit = async (values: ContactForm, { setSubmitting }: FormikActions<ContactForm>) => {
    const { address, emailId, landmark, mobileOne, name, panchangam, telOne, vedam } = values;
    const contact = {
      name,
      panchangam,
      ...(address && { address }),
      ...(emailId && { emailId }),
      ...(landmark && { landmark }),
      ...(mobileOne && { mobileOne }),
      ...(telOne && { telOne }),
      ...(vedam && { vedam }),
    };

    linkStore.setContact(contact);
    await linkStore.addContactAndShradhamRequest();
    setSubmitting(false);
  };

  return (
    <>
      <StyledCard>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            name: "",
            address: "",
            landmark: "",
            emailId: "",
            panchangam: "",
            mobileOne: "",
            telOne: "",
            vedam: "",
          }}
        >
          {({ isSubmitting, isValid, errors, touched, submitForm, handleChange }) => (
            <FormikForm>
              <CardContent>
                <Field
                  type="text"
                  name="name"
                  label={
                    <>
                      Name{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="textarea"
                  name="address"
                  label={
                    <>
                      Address{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  multiline
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="text"
                  name="landmark"
                  label="Landmark"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="text"
                  name="emailId"
                  label={
                    <>
                      Email{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledFormControl
                  variant="outlined"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    linkStore.setContact({
                      panchangam: event.target.value,
                    } as Shared.Contact)
                  }
                >
                  <InputLabel
                    ref={panchangamLabel}
                    htmlFor="contact-panchangam"
                    error={touched && touched.panchangam && errors && errors.panchangam ? true : false}
                  >
                    Panchangam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="panchangam"
                    component={Select}
                    labelWidth={panchangamLabelWidth}
                    inputProps={{
                      id: "contact-panchangam",
                    }}
                    error={touched && touched.panchangam && errors && errors.panchangam}
                    onChange={handleChange}
                  >
                    <option value="" hidden></option>
                    {Shared.PANCHANGAM.map((option: string, index: number) => (
                      <option key={`panchangam-${option}#${index}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.panchangam && errors && errors.panchangam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.panchangam}
                  </FormHelperText>
                )}
                <Field
                  type="tel"
                  name="mobileOne"
                  label={
                    <>
                      Mobile{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="tel"
                  name="telOne"
                  label="Telephone"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={vedamLabel}
                    htmlFor="contact-vedam"
                    error={touched && touched.vedam && errors && errors.vedam ? true : false}
                  >
                    Vedam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="vedam"
                    component={Select}
                    labelWidth={vedamLabelWidth}
                    inputProps={{
                      id: "contact-vedam",
                    }}
                    error={touched && touched.vedam && errors && errors.vedam}
                  >
                    <option value="" hidden></option>
                    {Shared.VEDAM.map((option: string, index: number) => (
                      <option key={`vedam-${option}#${index}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.vedam && errors && errors.vedam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.vedam}
                  </FormHelperText>
                )}
                <AddShradham />
              </CardContent>
              <DialogActions>
                <Button
                  size="small"
                  color="primary"
                  disabled={!isValid || isSubmitting}
                  onClick={() => submitForm()}
                  // type="submit"
                >
                  {isSubmitting ? <CircularProgress /> : "Submit"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
});

export default Form;
