import * as React from "react";
import { Typography } from "@material-ui/core";
import Form, { FormSkeleton } from "./form";
import { useParams } from "react-router-dom";

interface AddEventProps {}

const ModifyAncestor: React.FunctionComponent<AddEventProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams<{ contactId: string }>();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Modify Ancestor
      </Typography>
      <React.Suspense fallback={<FormSkeleton />}>
        <Form contactId={params.contactId} />
      </React.Suspense>
    </>
  );
};

export default ModifyAncestor;
