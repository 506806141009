import React from "react";
import { Redirect, Route, Switch, useRouteMatch, match } from "react-router-dom";
// import * as Containers from "../containers";
import * as Views from "../views";
// import * as Errors from "../errors";

export const Routes: React.FC = () => {
  const routeMatch = useRouteMatch() as match<{}>;
  const { path } = routeMatch;
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Views.Contacts />
        </Route>
        <Route exact path={`${path}/add`}>
          <Views.Contact.Add />
        </Route>
        <Route exact path={`${path}/:contactId/edit`}>
          <Views.Contact.Edit />
        </Route>
        <Route exact path={`${path}/:contactId/addevent`}>
          <Views.Contact.AddEvent />
        </Route>
        <Route exact path={`${path}/:contactId/event/:eventId/editevent`}>
          <Views.Contact.EditEvent />
        </Route>
        <Route exact path={`${path}/:contactId/addmasigam`}>
          <Views.Contact.AddMasigam />
        </Route>
        <Route exact path={`${path}/:contactId/addshradham`}>
          <Views.Contact.AddShradham />
        </Route>
        <Route exact path={`${path}/:contactId`}>
          <Views.Contact.Info />
        </Route>
        <Route exact path={`${path}/:contactId/relatives`}>
          <Views.Contact.Relative />
        </Route>
        <Route exact path={`${path}/:contactId/addRelative`}>
          <Views.Contact.ModifyRelative />
        </Route>
        <Route exact path={`${path}/:contactId/modifyrelative/:relativeId`}>
          <Views.Contact.ModifyRelative />
        </Route>
        <Route exact path={`${path}/:contactId/ancestordetails`}>
          <Views.Contact.Ancestor />
        </Route>
        <Route exact path={`${path}/:contactId/modifyancestor`}>
          <Views.Contact.ModifyAncestor />
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
