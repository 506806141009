import { Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import * as React from "react";

export enum INFO_VARIANT {
  COMPLETE = "COMPLETE",
  MINIMAL = "MINIMAL"
}

interface IErrorInfoProps {
  title?: string;
  body?: string;
  picture?: string;
}

const ErrorInfo: React.FC<IErrorInfoProps> = ({
  children,
  body = "We've notified our development team about the issue. Please check back later.",
  picture = "/images/undraw_warning_cyit.svg",
  title = "Something went wrong"
}) => {
  return (
    <>
      <Info body={body} picture={picture} title={title}>
        {children}
      </Info>
    </>
  );
};

const PaddedContainer = styled("div")(({ theme }) => ({
  padding: (props: { variant?: INFO_VARIANT }) =>
    props.variant === INFO_VARIANT.MINIMAL
      ? theme.spacing(0)
      : theme.spacing(0, 4, 4, 4)
}));

const Image = styled("img")(({ theme }) => ({
  marginTop: (props: { variant?: INFO_VARIANT }) =>
    props.variant === INFO_VARIANT.MINIMAL
      ? theme.spacing(0)
      : theme.spacing(6),
  display: "inline-block",
  maxWidth: "100%",
  width: 560
}));

const Content = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(6),
  textAlign: "center"
}));

interface SharedInfoProps {
  picture: string;
}

type InfoProps =
  | SharedInfoProps & {
      body: string;
      title: string;
      variant?: INFO_VARIANT.COMPLETE;
    }
  | SharedInfoProps & {
      variant?: INFO_VARIANT.MINIMAL;
    };

export const Info: React.FC<InfoProps> = props => {
  return (
    <>
      <PaddedContainer>
        <Grid container justify="center" spacing={4}>
          <Grid item lg={6} xs={12}>
            <Image alt="Error info" src={props.picture} />
            {props.variant === INFO_VARIANT.COMPLETE && (
              <Content>
                <Typography variant="h1" gutterBottom>
                  {props.title}
                </Typography>
                <Typography variant="subtitle2">{props.body}</Typography>
              </Content>
            )}
            {props.children}
          </Grid>
        </Grid>
      </PaddedContainer>
    </>
  );
};

Info.defaultProps = {
  variant: INFO_VARIANT.COMPLETE
};

export default ErrorInfo;
