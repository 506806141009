/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Button, styled, Grid, Typography, CardContent, Card, DialogActions } from "@material-ui/core";
import { Link } from "react-router-dom";
import Event from "./event";
import { Info as InfoProvider, INFO_VARIANT } from "../ErrorInfo";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { useFirebaseApp, useUser } from "reactfire";
import * as Shared from "../../shared";
import * as config from "../../config";

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const EventContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export enum EVENTS_VARIANT {
  FILTER_BY_DATE = "FILTER_BY_DATE",
  FILTER_BY_TODAY = "FILTER_BY_TODAY",
  FILTER_BY_CONTACT = "FILTER_BY_CONTACT",
  FILTER_BY_THITHI = "FILTER_BY_THITHI",
}

type EventPropsForFilterByDate = {
  start: number;
  end: number;
  variant: EVENTS_VARIANT.FILTER_BY_DATE;
};

type EventPropsForFilterByToday = {
  start: number;
  end: number;
  variant: EVENTS_VARIANT.FILTER_BY_TODAY;
};

type EventPropsForFilterByContact = {
  contactId: string;
  variant: EVENTS_VARIANT.FILTER_BY_CONTACT;
};

type EventPropsForFilterByThithi = {
  thithi: string;
  variant: EVENTS_VARIANT.FILTER_BY_THITHI;
};

type EventsProps =
  | EventPropsForFilterByDate
  | EventPropsForFilterByToday
  | EventPropsForFilterByContact
  | EventPropsForFilterByThithi;

const Events: React.FC<EventsProps> = (props) => {
  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();
  const eventRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("events");

  const [, setBoundryError] = React.useState();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [events, setEvents] = React.useState<firebase.firestore.QueryDocumentSnapshot[]>();

  const subscriptions: Array<() => void> = [];

  const fetchEvents = async (): Promise<void> => {
    if (props.variant === EVENTS_VARIANT.FILTER_BY_CONTACT) {
      const currentDate = new Date().getTime();
      const currentYear = new Date(currentDate).getFullYear();

      let dateForSearch = new Date(config.tamilNewYearStartDate[currentYear - 1]);
      const currentTamilNewYearDate = new Date(config.tamilNewYearStartDate[currentYear]);

      if (new Date() >= currentTamilNewYearDate) {
        dateForSearch = currentTamilNewYearDate;
      } else {
      }

      if (!dateForSearch) {
        throw new Error(`Tamil new year start date not found in the config for the Gregorian year ${currentYear}`);
      }

      const subscription = eventRef
        .where("contactId", "==", props.contactId)
        .where("date", ">=", new Date(dateForSearch))
        .orderBy("date")
        .onSnapshot(
          (snapshot) => {
            setEvents(snapshot.docs);
          },
          (error) =>
            setBoundryError(() => {
              throw error;
            })
        );
      subscriptions.push(subscription);
      return;
    }

    if (props.variant === EVENTS_VARIANT.FILTER_BY_DATE || props.variant === EVENTS_VARIANT.FILTER_BY_TODAY) {
      const subscription = eventRef.where("date", "==", new Date(props.start)).onSnapshot(
        (snapshot) => setEvents(snapshot.docs),
        (error) =>
          setBoundryError(() => {
            throw error;
          })
      );
      subscriptions.push(subscription);
      return;
    }

    let currMasigamThithiDocs: firebase.firestore.QueryDocumentSnapshot[] = [];
    let currShradhamThithiDocs: firebase.firestore.QueryDocumentSnapshot[] = [];
    const masigamSubscription = eventRef.where("masigamThithi", "==", props.thithi).onSnapshot(
      (snapshot) => {
        currMasigamThithiDocs = snapshot.docs;
        setEvents([...currMasigamThithiDocs, ...currShradhamThithiDocs]);
      },
      (error) =>
        setBoundryError(() => {
          throw error;
        })
    );
    const shradhamSubscription = eventRef.where("shardhamThithi", "==", props.thithi).onSnapshot(
      (snapshot) => {
        currShradhamThithiDocs = snapshot.docs;
        setEvents([...currMasigamThithiDocs, ...currShradhamThithiDocs]);
      },
      (error) =>
        setBoundryError(() => {
          throw error;
        })
    );
    subscriptions.push(masigamSubscription);
    subscriptions.push(shradhamSubscription);
    return;
  };

  React.useEffect(() => {
    setIsLoading(true);
    fetchEvents().then(() => {
      setIsLoading(false);
    });
    return () => {
      subscriptions.forEach((subscription) => subscription());
    };
  }, [props]);

  return (
    <>
      {isLoading && <>Loading...</>}
      {events &&
        events.length === 0 &&
        (props.variant === EVENTS_VARIANT.FILTER_BY_CONTACT || props.variant === EVENTS_VARIANT.FILTER_BY_TODAY) && (
          <NoEventsInfo {...props} />
        )}
      {events &&
        events.length === 0 &&
        props.variant !== EVENTS_VARIANT.FILTER_BY_CONTACT &&
        props.variant !== EVENTS_VARIANT.FILTER_BY_TODAY && (
          <Grid item xs={12}>
            <NoEventsInfo {...props} />
          </Grid>
        )}
      {events &&
        events.length > 0 &&
        events.map((eventSnapshot, index: number) =>
          props.variant === EVENTS_VARIANT.FILTER_BY_CONTACT || props.variant === EVENTS_VARIANT.FILTER_BY_TODAY ? (
            <EventContainer key={`event#${index}`}>
              <Event eventId={eventSnapshot.id} {...(eventSnapshot.data() as Shared.Event.Event)} />
            </EventContainer>
          ) : (
            <Grid key={`event#${index}`} item xs={12} md={6}>
              <Event eventId={eventSnapshot.id} {...(eventSnapshot.data() as Shared.Event.Event)} />
            </Grid>
          )
        )}
    </>
  );
};

const NoEventsInfo: React.FC<EventsProps> = (props) => {
  return (
    <>
      <Card>
        <CardContent>
          <InfoProvider
            picture={
              props.variant === EVENTS_VARIANT.FILTER_BY_CONTACT
                ? "/images/undraw_no_data_qbuo.svg"
                : "/images/undraw_no_data_qbuo.svg"
            }
            variant={INFO_VARIANT.MINIMAL}
          />
          <NegativeSpace />
          <Grid container direction="column" alignItems="center">
            <Typography variant="h3">No Events</Typography>
            <Typography variant="body1" color="textSecondary">
              {props.variant === EVENTS_VARIANT.FILTER_BY_CONTACT &&
                "There are no events associated with this contact."}
              {props.variant === EVENTS_VARIANT.FILTER_BY_TODAY && "There are no events for today."}
              {props.variant === EVENTS_VARIANT.FILTER_BY_DATE &&
                "There are no events for this date. Try changing the date or add an event on this date."}
              {props.variant === EVENTS_VARIANT.FILTER_BY_THITHI &&
                "There are no events for this filter. Try changing the filters or add an event with these filters."}
            </Typography>
          </Grid>
        </CardContent>
        {props.variant !== EVENTS_VARIANT.FILTER_BY_THITHI && (
          <DialogActions>
            {props.variant === EVENTS_VARIANT.FILTER_BY_TODAY && (
              <Button size="small" component={Link} to={"/events"}>
                Events
              </Button>
            )}
            <Button
              color="primary"
              size="small"
              component={Link}
              to={
                props.variant === EVENTS_VARIANT.FILTER_BY_CONTACT
                  ? `/contacts/${props.contactId}/addevent`
                  : "/events/add"
              }
            >
              Add Event
            </Button>
          </DialogActions>
        )}
      </Card>
    </>
  );
};

export default Events;
