import * as React from "react";
import {
  Card,
  CardContent,
  withStyles,
  Dialog,
  DialogActions,
  useTheme,
  useMediaQuery,
  styled
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import AddShradhamForm from "./add-shradham-form";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34)
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0
  },
  rect: {
    marginBottom: 0
  }
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = props => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton
            component="span"
            width={70}
            height={theme.spacing(3)}
          />
          <StyledSkeleton
            component="span"
            width={60}
            height={theme.spacing(3)}
          />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface AddShradhamDialogProps {
  open: boolean;
  handleDialogClose(): void;
}

const AddShradhamDialog: React.FC<AddShradhamDialogProps> = props => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AddShradhamForm handleSubmit={props.handleDialogClose} />
      </Dialog>
    </>
  );
};

export default AddShradhamDialog;
