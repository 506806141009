import { Plan, Membership } from "../shared";
import * as firebase from "firebase/app";
import "firebase/functions";
import { action, observable } from "mobx";
import * as config from "../config";
import { IAuthResponse } from "../typings/razorpay";

export class SubscriptionStore {
  private cancelSubscription = firebase.functions().httpsCallable("cancelSubscription");
  private createSubscription = firebase.functions().httpsCallable("createSubscription");
  private verifyPayment = firebase.functions().httpsCallable("verifyPayment");
  private viewPlans = firebase.functions().httpsCallable("viewPlans");

  @observable isLoadingPlans: boolean = false;
  @observable isCreatingSubscription: boolean = false;
  @observable isProcessingPaymentVerification: boolean = false;
  @observable isPaymentVerified: boolean = false;

  @observable plans: Plan[] = [];

  @observable chosenPlan?: Plan;

  @observable subscriptionId?: string;

  @action public cancelSubscriptionRequest = async (membership: Membership) => {
    try {
      const result: { data: boolean } = await this.cancelSubscription({
        subscriptionId: membership.productId,
      });
      console.log("cancelSubscriptionRequest result is", result.data);
    } catch (error) {
      console.error(error);
    } finally {
      // do something finally
    }
  };

  @action public createSubscriptionRequest = async () => {
    if (!this.chosenPlan) {
      throw new Error("Can't create subscription. Set chosenPlan before calling this method");
    }
    try {
      this.isCreatingSubscription = true;
      const result: {
        data: string;
      } = await this.createSubscription({
        planId: this.chosenPlan.id,
      });
      this.subscriptionId = result.data;
    } catch (error) {
      console.error(error);
    } finally {
      this.isCreatingSubscription = false;
    }
  };

  @action private verifyPaymentRequest = async (response: IAuthResponse & { productId: string }) => {
    try {
      const result: { data: boolean } = await this.verifyPayment(response);
      this.isPaymentVerified = result.data;
    } catch (error) {
      console.error(error);
    } finally {
      this.isProcessingPaymentVerification = false;
    }
  };

  @action public viewPlansRequest = async () => {
    if (this.plans.length > 0) {
      console.log("This log should not be seen repeatedly. Fix your code.");
      return;
    }
    try {
      this.isLoadingPlans = true;
      const result: {
        data: Plan[];
      } = await this.viewPlans();
      this.plans = result.data;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingPlans = false;
    }
  };

  @action public clearCheckoutState = () => {
    this.chosenPlan = undefined;
    this.subscriptionId = undefined;
    this.isPaymentVerified = false;
    this.isCreatingSubscription = false;
    this.isProcessingPaymentVerification = false;
  };

  @action public processSubscriptionPayment = async (user: firebase.User | null, onDismissCallback: () => void) => {
    if (!window.Razorpay) {
      throw new Error(
        "Can't process subscription payment. Initialise the razorpay checkout widget using the useCheckout hook before calling this method"
      );
    }

    if (!this.chosenPlan) {
      throw new Error("Can't process subscription payment. Set chosenPlan before calling this method");
    }

    if (!this.subscriptionId) {
      throw new Error("Can't process subscription payment. Set subscriptionId before calling this method");
    }

    if (!user) {
      throw new Error(
        "Can't process subscription payment. Send a valid authenticated user as an argument to this method"
      );
    }

    const subscriptionId = this.subscriptionId;
    const plan = this.chosenPlan;

    const checkout = new window.Razorpay({
      key: config.razorpay.keyId,
      ...(plan.item.name.includes("One") || plan.notes.isOneTimePlan
        ? { order_id: subscriptionId }
        : { subscription_id: subscriptionId }),
      name: plan.item.name,
      description: `Transaction for ${plan.item.name} with product # ${subscriptionId}`,
      prefill: {
        name: user.displayName ? user.displayName : undefined,
        contact: user.phoneNumber ? user.phoneNumber : undefined,
        email: user.email ? user.email : undefined,
      },
      modal: {
        ondismiss: onDismissCallback,
      },
      handler: (response: Razorpay.IAuthResponse) => {
        this.isProcessingPaymentVerification = true;
        this.verifyPaymentRequest({
          ...response,
          productId: subscriptionId,
        });
        onDismissCallback();
      },
    });

    checkout.open();
  };

  @action public setChosenPlan = (plan: Plan | undefined) => {
    this.chosenPlan = plan;
  };
}
