import { action, observable } from "mobx";
import * as Shared from "../shared";

export class ToastStore {
  @observable toasts: Shared.IToast[] = [];

  @action addToast(toast: Shared.IToast) {
    this.toasts.push(toast);
  }
}
