import * as React from "react";
import {
  Card,
  CardContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
  styled,
  withStyles,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import * as Shared from "../../shared";
import { ShareConfirmation } from "../ShareConfirmation";
import * as config from "../../config";

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export enum CONTACT_VARIANT {
  FULL_INFO = "FULL_INFO",
  PARTIAL_INFO = "PARTIAL_INFO",
}

export const ContactSkeleton: React.FC = (props) => {
  const showShareButton = (navigator as Navigator & {
    share: (options: { url?: string; title?: string; text?: string }) => void;
  }).share;
  const theme = useTheme();
  return (
    <>
      <Card>
        <CardContent>
          <Skeleton width={220} />
          <StyledSkeleton width={110} />
          <NegativeSpace />
          <Skeleton width={220} />
          <StyledSkeleton />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
          {showShareButton && <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />}
        </DialogActions>
      </Card>
    </>
  );
};

interface SharedContactProps {
  contactId: string;
  name: string;
  nickname?: string;
  address?: string;
  landmark?: string;
}

type ContactPropsForPartialInfoVariant = SharedContactProps & {
  variant?: CONTACT_VARIANT.PARTIAL_INFO;
};

type ContactPropsForFullInfoVariant = SharedContactProps & {
  emailId?: string;
  mobileOne?: string;
  telOne?: string;
  panchangam: string;
  vedam?: string;
  sutram?: string;
  variant?: CONTACT_VARIANT.FULL_INFO;
};

type ContactProps = ContactPropsForFullInfoVariant | ContactPropsForPartialInfoVariant;

const Contact: React.FC<ContactProps> = (props) => {
  const [shareText, setShareText] = React.useState<string>();
  const [shareDialogOpen, setShareDialogOpen] = React.useState<boolean>(false);

  const firebaseApp = useFirebaseApp();
  const auth = firebaseApp.auth();
  const user = auth.currentUser;

  const showShareButton = (navigator as Navigator & {
    share: (options: { url?: string; title?: string; text?: string }) => Promise<void>;
  }).share;

  const getShareText = async (eventsSnapshot: firebase.firestore.QuerySnapshot): Promise<string> => {
    const text: string = eventsSnapshot.docs.reduce((acc: string, event) => {
      const eventName = (event.data() as Shared.Event.Event).eventName;
      const eventDate = (event.data() as Shared.Event.Event).date.toDate().toDateString();
      return `${acc}Event Name: ${eventName}\nEvent Date: ${eventDate}\n\n`;
    }, "");
    return text;
  };

  const handleShare = async () => {
    if (!user) {
      return;
    }

    const currentDate = new Date().getTime();
    const currentYear = new Date(currentDate).getFullYear();

    let dateForSearch = new Date(config.tamilNewYearStartDate[currentYear - 1]);
    const currentTamilNewYearDate = new Date(config.tamilNewYearStartDate[currentYear]);

    if (new Date() >= currentTamilNewYearDate) {
      dateForSearch = currentTamilNewYearDate;
    } else {
    }

    if (!dateForSearch) {
      throw new Error(`Tamil new year start date not found in the config for the Gregorian year ${currentYear}`);
    }

    const eventsRef = firebaseApp
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("events")
      .where("contactId", "==", props.contactId)
      .where("date", ">=", new Date(dateForSearch))
      .orderBy("date");

    const eventsSnapshot = await eventsRef.get();

    const text = await getShareText(eventsSnapshot);
    setShareText(text);
    setShareDialogOpen(true);
  };

  return (
    <>
      {shareText && (
        <ShareConfirmation
          handleDialogClose={() => setShareDialogOpen(false)}
          open={shareDialogOpen}
          text={shareText}
        />
      )}
      <Card>
        <CardContent>
          <Typography variant="h5">{props.name}</Typography>
          <Typography variant="body1" color="textSecondary">
            {props.nickname}
          </Typography>
          {(props.address || props.landmark) && (
            <>
              <NegativeSpace />
              {props.landmark && <Typography variant="h5">{props.landmark}</Typography>}
              {props.address && (
                <Typography variant="body1" color="textSecondary">
                  {props.address}
                </Typography>
              )}
            </>
          )}
          {props.variant === CONTACT_VARIANT.FULL_INFO && (props.emailId || props.mobileOne || props.telOne) && (
            <>
              <NegativeSpace />
              {props.emailId && <Typography variant="h5">{props.emailId}</Typography>}
              {props.mobileOne && <Typography variant="h6"><a href={`tel:${props.mobileOne}`}>{props.mobileOne}</a></Typography>}
              {props.telOne && (
                <Typography variant="body1" color="textSecondary">
                  {props.telOne}
                </Typography>
              )}
            </>
          )}
          {props.variant === CONTACT_VARIANT.FULL_INFO && (props.sutram || props.panchangam || props.vedam) && (
            <>
              <NegativeSpace />
              <Typography variant="h5">{props.panchangam.toUpperCase()} Panchangam</Typography>
              {props.vedam && (
                <Typography variant="body1" color="textSecondary">
                  {props.vedam.toUpperCase()} Vedam
                </Typography>
              )}
              {props.sutram && (
                <Typography variant="body1" color="textSecondary">
                  {props.sutram.toUpperCase()} Sutram
                </Typography>
              )}
            </>
          )}
        </CardContent>
        <DialogActions>
          <Grid container justify="space-between" alignItems="center">
            <div>
              {false && (
                <IconButton size="small">
                  <DeleteIcon color="disabled" />
                </IconButton>
              )}
            </div>
            <div>
              {props.variant !== CONTACT_VARIANT.FULL_INFO && (
                <Button size="small" style={{ marginRight: 8 }} disableRipple  component={Link} to={`/contacts/${props.contactId}`}>
                  Info
                </Button>
              )}
              <Button
                size="small"
                color={showShareButton ? "default" : "primary"}
                style={{ marginRight: 8 }}
                component={Link}
                to={`/contacts/${props.contactId}/edit`}
              >
                Edit
              </Button>
              {showShareButton && (
                <Button size="small" color="primary" onClick={handleShare}>
                  Share
                </Button>
              )}
            </div>
          </Grid>
        </DialogActions>
      </Card>
    </>
  );
};

Contact.defaultProps = {
  variant: CONTACT_VARIANT.PARTIAL_INFO,
};

export default Contact;
