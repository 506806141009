import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  styled,
  TextField,
  Typography
} from "@material-ui/core";
import { Info, INFO_VARIANT } from "../ErrorInfo";

const NegativeSpace = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export enum SHARE_CONFIRMATION_VARIANTS {
  TEXT = "TEXT",
  LINK = "LINK"
}

interface ShareConfirmationProps {
  open: boolean;
  text: string;
  handleDialogClose(): void;
  variant?: SHARE_CONFIRMATION_VARIANTS;
}

const ShareConfirmation: React.FC<ShareConfirmationProps> = props => {
  const [isSharing, setIsSharing] = React.useState<boolean>(false);

  const isShareEnabled = (navigator as Navigator & {
    share: (options: {
      url?: string;
      title?: string;
      text?: string;
    }) => Promise<void>;
  }).share;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleShare = async () => {
    setIsSharing(true);
    try {
      await (navigator as Navigator & {
        share: (options: {
          url?: string;
          title?: string;
          text?: string;
        }) => Promise<void>;
      }).share({ text: props.text });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        disableBackdropClick={isSharing}
        disableEscapeKeyDown={isSharing}
        onClose={props.handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        {props.variant === SHARE_CONFIRMATION_VARIANTS.TEXT && (
          <>
            <DialogTitle id="responsive-dialog-title">
              Want to share the following details?
            </DialogTitle>
            <DialogContent>
              <pre>
                <DialogContentText>{props.text}</DialogContentText>
              </pre>
            </DialogContent>
          </>
        )}
        {props.variant === SHARE_CONFIRMATION_VARIANTS.LINK && (
          <>
            <DialogContent>
              <Info
                picture="/images/undraw_link_shortener_mvf6.svg"
                variant={INFO_VARIANT.MINIMAL}
              />
              <NegativeSpace />
              <Typography variant="h4" align="center" gutterBottom>
                Sharable Link
              </Typography>
              <Typography align="center">
                Here is your new sharable link. Share it with the new client you
                want to onboard and review the details entered by them in your
                dashboard page
              </Typography>
              <NegativeSpace />
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                label="Sharable Link"
                defaultValue={props.text}
                value={props.text}
              />
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            autoFocus
            disabled={isSharing}
            onClick={props.handleDialogClose}
            size="small"
          >
            Cancel
          </Button>
          {isShareEnabled ? (
            <Button
              disabled={isSharing}
              onClick={handleShare}
              color="primary"
              size="small"
            >
              {isSharing ? <CircularProgress /> : "Share"}
            </Button>
          ) : (
            <CopyToClipboard text={props.text}>
              <Button
                disabled={isSharing}
                onClick={handleShare}
                color="primary"
                size="small"
              >
                Copy to clipboard
              </Button>
            </CopyToClipboard>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

ShareConfirmation.defaultProps = {
  variant: SHARE_CONFIRMATION_VARIANTS.TEXT
};

export default ShareConfirmation;
