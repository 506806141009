import { createStyles, Drawer, Hidden, makeStyles, SwipeableDrawer, useTheme, styled } from "@material-ui/core";
import React from "react";
import SideNavbarContent from "./side-navbar-content";

const drawerWidth = 240;

const useStyles = makeStyles(
  createStyles({
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

const Nav = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

interface SideNavbarProps {
  toggleDrawer(event: React.KeyboardEvent | React.MouseEvent): void;
  open: boolean;
}

const SideNavbar: React.FC<SideNavbarProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const iOS = typeof window !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <Nav aria-label="App navigation">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            onOpen={props.toggleDrawer}
            open={props.open}
            onClose={() => {}}
            onClick={props.toggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: false, // Better open performance on mobile.
            }}
          >
            <SideNavbarContent />
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SideNavbarContent />
          </Drawer>
        </Hidden>
      </Nav>
    </>
  );
};

export default SideNavbar;
