import * as React from "react";
import { Button, styled, Grid, Typography, CardContent, Card, DialogActions } from "@material-ui/core";
import { Link, useParams, Redirect } from "react-router-dom";
import {
  Contact,
  ContactSkeleton,
  CONTACT_VARIANT,
  Events as RenderEvents,
  EventRecord,
  Info as InfoProvider,
  INFO_VARIANT,
  EVENTS_VARIANT,
} from "../../../components";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { useFirebaseApp, useUser, useFirestoreDoc, useFirestoreCollection } from "reactfire";
import * as Shared from "../../../shared";

const InfoGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const RecordsTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const EventRecordContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

enum RENDER_RECORDS_VARIANT {
  MASIGAM = "MASIGAM",
  SHRADHAM = "SHRADHAM",
}

interface InfoProps {}

const Info: React.FunctionComponent<InfoProps> = (props) => {
  const { contactId } = useParams<{ contactId: string }>();

  return (
    <>
      <Grid container justify="flex-end" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to={`/contacts/${contactId}/addevent`}
          >
            Add Event
          </Button>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to={`/contacts/${contactId}/addmasigam`}
          >
            Add Masigam
          </Button>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to={`/contacts/${contactId}/addshradham`}
          >
            Add Shradham
          </Button>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to={`/contacts/${contactId}/ancestordetails`}
          >
            Ancestor Info
          </Button>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to={`/contacts/${contactId}/relatives`}
          >
            Relatives Info
          </Button>
        </Grid>
      </Grid>
      <InfoGridContainer container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            Contact
          </Typography>
          <React.Suspense fallback={<ContactSkeleton />}>
            <RenderContact contactId={contactId} />
          </React.Suspense>
          <RecordsTitle variant="h2" gutterBottom>
            Masigam
          </RecordsTitle>
          <React.Suspense fallback={<ContactSkeleton />}>
            <RenderRecords contactId={contactId} variant={RENDER_RECORDS_VARIANT.MASIGAM} />
          </React.Suspense>
          <RecordsTitle variant="h2" gutterBottom>
            Shradham
          </RecordsTitle>
          <React.Suspense fallback={<ContactSkeleton />}>
            <RenderRecords contactId={contactId} variant={RENDER_RECORDS_VARIANT.SHRADHAM} />
          </React.Suspense>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            Events
          </Typography>
          <React.Suspense fallback={<ContactSkeleton />}>
            <RenderEvents contactId={contactId} variant={EVENTS_VARIANT.FILTER_BY_CONTACT} />
          </React.Suspense>
        </Grid>
      </InfoGridContainer>
    </>
  );
};

const RenderContact: React.FC<{ contactId: string }> = (props) => {
  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();
  const contactRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("contacts")
    .doc(props.contactId);
  const contactSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(contactRef);
  const contact: Shared.Contact | undefined = contactSnapshot.data() as Shared.Contact | undefined;

  return (
    <>
      {props.contactId && !contactSnapshot.exists && <Redirect to="/404" />}
      {contact && <Contact {...{ contactId: props.contactId, ...contact }} variant={CONTACT_VARIANT.FULL_INFO} />}
    </>
  );
};

interface RenderRecordsProps {
  contactId: string;
  variant: RENDER_RECORDS_VARIANT;
}

const RenderRecords: React.FC<RenderRecordsProps> = (props) => {
  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();
  const recordRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection(props.variant === RENDER_RECORDS_VARIANT.MASIGAM ? "masigam" : "shardham")
    .where("contactId", "==", props.contactId);

  const recordsSnapshot: firebase.firestore.QuerySnapshot = useFirestoreCollection(
    recordRef as firebase.firestore.CollectionReference
  ) as firebase.firestore.QuerySnapshot;

  const records = recordsSnapshot.docs;

  return (
    <>
      {records && records.length === 0 && (
        <Card>
          <CardContent>
            <InfoProvider picture="/images/undraw_no_data_qbuo.svg" variant={INFO_VARIANT.MINIMAL} />
            <NegativeSpace />
            <Grid container direction="column" alignItems="center">
              <Typography variant="h3">
                No {props.variant === RENDER_RECORDS_VARIANT.MASIGAM ? "Masigams" : "Shradhams"}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                There are no {props.variant === RENDER_RECORDS_VARIANT.MASIGAM ? "Masigams" : "Shradhams"} associated
                with this contact.
              </Typography>
            </Grid>
          </CardContent>
          <DialogActions>
            <Button
              color="primary"
              size="small"
              component={Link}
              to={`/contacts/${props.contactId}/add${
                props.variant === RENDER_RECORDS_VARIANT.MASIGAM ? "masigam" : "shradham"
              }`}
            >
              Add {props.variant === RENDER_RECORDS_VARIANT.MASIGAM ? "Masigam" : "Shradham"}
            </Button>
          </DialogActions>
        </Card>
      )}
      {records &&
        records.length > 0 &&
        records.map((recordSnapshot, index: number) => (
          <EventRecordContainer key={`record#${index}`}>
            <EventRecord
              recordId={recordSnapshot.id}
              {...(recordSnapshot.data() as Shared.Shradham & Shared.Masigam)}
            />
          </EventRecordContainer>
        ))}
    </>
  );
};

export default Info;
