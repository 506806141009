import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Topbar } from "./components";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: "100%"
  },
  content: {
    height: "100%"
  }
}));

interface IMinimalProps {
  children?: React.ReactNode;
  className?: string;
}

const Minimal: React.FC<IMinimalProps> = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Minimal;
