export const NATCHATRAM = [
  { value: "1", label: "Ashwini" },
  { value: "2", label: "Bharani" },
  { value: "3", label: "Karthikai" },
  { value: "4", label: "Rohini" },
  { value: "5", label: "Mrigashiridam" },
  { value: "6", label: "Tiruvadharai" },
  { value: "7", label: "Ponarpoosam" },
  { value: "8", label: "Poosam" },
  { value: "9", label: "Ayilyam" },
  { value: "10", label: "Magam" },
  { value: "11", label: "Pooram" },
  { value: "12", label: "Uttiram" },
  { value: "13", label: "Hastham" },
  { value: "14", label: "Chittrai" },
  { value: "15", label: "Swati" },
  { value: "16", label: "Vishagam" },
  { value: "17", label: "Anusham" },
  { value: "18", label: "Kettai" },
  { value: "19", label: "Moolam" },
  { value: "20", label: "Pooradam" },
  { value: "21", label: "Uttiradam" },
  { value: "22", label: "Thiruvonam" },
  { value: "23", label: "Avittam" },
  { value: "24", label: "Satham" },
  { value: "25", label: "Pooratathi" },
  { value: "26", label: "Uttarathadi" },
  { value: "27", label: "Revathi" },
];

const natchatram: [string, string][] = NATCHATRAM.reduce(
  (acc, curr, index) => {
    if (index === 0) {
      return [[curr.value, curr.label]];
    }
    return [...acc, [curr.value, curr.label]];
  },
  [["", ""]]
);

export const natchatramAsMap = new Map(natchatram);
