import {
  Button,
  Card,
  CardContent,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  InputLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField, Select } from "formik-material-ui";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { DatePicker } from "@material-ui/pickers";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as Shared from "../../../shared";
import uuidv4 from "uuid/v4";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const RelativeFormSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={70} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface FormProps {
  contactId: string;
  relativeId?: string;
}

const Form: React.FC<FormProps> = (props) => {
  const validationSchema = Yup.object<Shared.RelativeForm>({
    name: Yup.string().required("Name is Required"),
    gender: Yup.string().required("Gender is Required"),
  });

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const masamLabel = React.useRef<HTMLLabelElement>(null);
  const [masamLabelWidth, setMasamLabelWidth] = React.useState<number>(0);
  React.useEffect(() => {
    setMasamLabelWidth(masamLabel.current!.offsetWidth);
  }, []);

  const relativesRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("contacts")
    .doc(props.contactId)
    .collection("relatives")
    .doc("relativeinfo");

  const relativeSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(relativesRef);
  const RelativesInfo: Shared.RelativesInfo | undefined = relativeSnapshot.data() as Shared.RelativesInfo | undefined;
  const history = useHistory();
  const edit: boolean = props.relativeId && props.relativeId != "" ? true : false;
  let relativeForm: Shared.RelativeForm = {
    name: "",
    natchatram: "",
    rasi: "",
    relation: "",
    gender: "M",
    dob: new Date(),
    birthMonth: "",
  };

  if (edit && RelativesInfo && RelativesInfo.relatives && props.relativeId) {
    let formData = RelativesInfo.relatives[props.relativeId];
    if (formData.dob) {
      formData.dob = formData.dob.toDate();
    }
    relativeForm = formData;
  }

  const handleSubmit = async (
    relativeForm: Shared.RelativeForm,
    { setSubmitting }: FormikActions<Shared.RelativeForm>
  ) => {
    console.log("Submitting the Form");
    console.log(edit);
    let relatives: any = {};

    if (edit) {
      if (!props.relativeId) {
        return;
      }
      relatives[props.relativeId] = relativeForm;
      await relativesRef.set({ relatives: relatives }, { merge: true });
    } else {
      relatives[uuidv4()] = relativeForm;
      await relativesRef.set({ relatives: relatives }, { merge: true });
    }
    // Else need to add the new Relative Info
    setSubmitting(false);
    history.push(`/contacts/${props.contactId}/relatives`);
  };

  return (
    <>
      {!props.contactId && <Redirect to="/404" />}
      <StyledCard>
        <Formik onSubmit={handleSubmit} validationSchema={validationSchema} initialValues={relativeForm}>
          {({ isSubmitting, isValid, values, setFieldValue, errors, touched }) => (
            <FormikForm>
              <CardContent>
                <Field
                  type="text"
                  name="name"
                  label="Relative Name"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel ref={masamLabel} htmlFor="natchatram">
                    Natchatram{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="natchatram"
                    component={Select}
                    labelWidth={masamLabelWidth}
                    inputProps={{
                      id: "natchatram",
                    }}
                  >
                    <option value="" hidden></option>
                    {Shared.NATCHATRAM.map((option, index: number) => (
                      <option key={`natchatram-${option.label}#${index}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel ref={masamLabel} htmlFor="rasi">
                    Rasi
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="rasi"
                    component={Select}
                    labelWidth={masamLabelWidth}
                    inputProps={{
                      id: "rasi",
                    }}
                  >
                    <option value="" hidden></option>
                    {Shared.RASI.map((option, index: number) => (
                      <option key={`rasi-${option.label}#${index}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                <Field
                  type="text"
                  name="relation"
                  label="Relation"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledDatePicker
                  label={
                    <>
                      Date of Birth{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  disabled={isSubmitting}
                  fullWidth
                  animateYearScrolling
                  inputVariant="outlined"
                  invalidDateMessage="Event date is invalid"
                  value={values.dob || new Date()}
                  format="dd/MM/yyyy"
                  onChange={(date: Date | null) => {
                    if (!date) {
                      return;
                    }
                    setFieldValue("dob", date);
                  }}
                />
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel ref={masamLabel} htmlFor="birthMonth">
                    Birth Month
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="birthMonth"
                    component={Select}
                    labelWidth={masamLabelWidth}
                    inputProps={{
                      id: "birthMonth",
                    }}
                  >
                    <option value="" hidden></option>
                    {Shared.MASAM.map((option, index: number) => (
                      <option key={`birthMonth-${option.label}#${index}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
              </CardContent>
              <DialogActions>
                <Button
                  size="small"
                  disabled={isSubmitting}
                  component={Link}
                  to={`/contacts/${props.contactId}/relatives`}
                >
                  Cancel
                </Button>
                <Button size="small" color="primary" disabled={!isValid || isSubmitting} type="submit">
                  {isSubmitting ? <CircularProgress /> : edit ? "Save" : "Add"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};

export default Form;
