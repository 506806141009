import * as React from "react";
import { ErrorInfo } from "../../components";
import * as Sentry from '@sentry/browser';

export interface IPhoneAuthFailedProps {}

export interface IPhoneAuthFailedState {
  hasError?: Error & { code?: "auth/credential-already-in-use" };
}

// TODO: Find if there is a way to show different error messages based on the error properties
// REF: https://stackoverflow.com/questions/51384606/error-boundary-does-not-give-access-to-error-if-it-is-an-instance-of-error-class
class PhoneAuthFailed extends React.Component<
  IPhoneAuthFailedProps,
  IPhoneAuthFailedState
> {
  constructor(props: IPhoneAuthFailedProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
       Sentry.captureException(error);

  });
    console.info("Caught phone authentication failed error", error);
    console.info(errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <ErrorInfo
        title="We failed to add your phone number to your profile."
        body="You have successfully created an account but your phone number is not yet recorded. Don't worry. You can always set the phone number from the profile section the our app."
        picture="/images/undraw_product_teardown_elol.svg"
      />
    ) : (
      this.props.children
    );
  }
}

export default PhoneAuthFailed;
