import * as Shared from "../shared";

export function createShradham({
  contact,
  contactId,
  panchangamDateSheet,
  shradhamForm,
}: {
  contact: Shared.Contact;
  contactId: string;
  panchangamDateSheet: Shared.PanchangamDateSheet;
  shradhamForm: Shared.Form.Shradham;
}): Shared.Event.Shradham {
  const { forName, masam, paksham, thithi, panchangam } = shradhamForm;
  const dateKey = `${masam}-${paksham}-${thithi}`;
  const date = panchangamDateSheet[dateKey];

  if (!date) {
    // NOTE: If none of the events needs to be added if this block ever gets executed
    // NOTE: Then throw an error and stop the further processing
    throw new Error(
      `Can't create shradham event because for the given key (${dateKey}) there is no date found in the panchangam date sheet`
    );
  }

  const shradhamDate = date.toDate();
  const shradhamName = `Shradham for ${forName}`;
  // TODO: Confirm from Vaidhy about the changes in event name

  const { address, emailId, landmark, mobileOne, name, nickname, sutram, telOne, vedam } = contact;
  const shradham: Shared.Event.Shradham = {
    name,
    vedam,
    panchangam,
    masam,
    paksham,
    thithi,
    shardhamThithi: dateKey,
    ...(sutram && { sutram }),
    ...(address && { address }),
    ...(emailId && { emailId }),
    ...(landmark && { landmark }),
    ...(mobileOne && { mobileOne }),
    ...(nickname && { nickname }),
    ...(telOne && { telOne }),
    contactId,
    shardhamId: "",
    date: shradhamDate,
    eventName: shradhamName,
  };

  return shradham;
}
