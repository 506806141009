import { AppBar, IconButton, Toolbar, Typography, styled, Grid } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { observer } from "mobx-react-lite";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none"
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.h3,
    color: "inherit"
  }
}));
interface HeaderProps {
  handleDrawerToggle(): void;
  title: string;
}

const Header: React.FC<HeaderProps & RouteComponentProps> = observer(props => {
  const { pathname } = props.location;

  return (
    <>
      <StyledAppBar position="fixed">
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Title variant="h3" color="inherit" noWrap>
              {pathname === "/" && "Dashboard"}
              {pathname.includes("/contacts") && "Contacts"}
              {pathname.includes("/events") && "Events"}
              {pathname.includes("/settings") && "Settings"}
            </Title>
            <MenuButton color="inherit" aria-label="open drawer" edge="start" onClick={props.handleDrawerToggle}>
              <MenuIcon />
            </MenuButton>
          </Grid>
        </Toolbar>
      </StyledAppBar>
    </>
  );
});

export default withRouter(Header);
