import * as firebase from "firebase/app";
import { action, observable } from "mobx";

export class RegisterStore {
  @observable recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
  @observable confirmationResult?: firebase.auth.ConfirmationResult;

  @action public setRecaptchaVerifier(
    verifier: firebase.auth.RecaptchaVerifier
  ) {
    this.recaptchaVerifier = verifier;
  }

  @action public setConfirmationResult(
    result: firebase.auth.ConfirmationResult
  ) {
    this.confirmationResult = result;
  }

  @action public clear() {
    this.confirmationResult = undefined;
  }
}
