import * as React from "react";
import {
  Card,
  CardContent,
  DialogActions,
  Button,
  withStyles,
  useTheme
} from "@material-ui/core";
import { useFirebaseApp, useUser } from "reactfire";
import {
  ShareConfirmation,
  SHARE_CONFIRMATION_VARIANTS
} from "../../../components";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0
  },
  rect: {
    marginBottom: 0
  }
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const GenerateNewLinkSkeleton: React.FC = props => {
  const theme = useTheme();
  return (
    <>
      <Card>
        <CardContent>
          <StyledSkeleton />
          <StyledSkeleton />
          <StyledSkeleton />
        </CardContent>
        <DialogActions>
          <StyledSkeleton
            component="span"
            width={90}
            height={theme.spacing(3)}
          />
        </DialogActions>
      </Card>
    </>
  );
};

interface GenerateNewLinkProps {}

const GenerateNewLink: React.FC<GenerateNewLinkProps> = props => {
  const [shareDialogOpen, setShareDialogOpen] = React.useState<boolean>(false);
  const [shareText, setShareText] = React.useState<string>("");

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const handleGenerateLink = async () => {
    const now = new Date();

    const currentDate = now.getTime();
    const expiryDate = now.setDate(now.getDate() + 3);

    const { uid } = user;
    const { id: lkid } = await firebaseApp
      .firestore()
      .collection("custentry")
      .doc(uid)
      .collection("links")
      .add({ expiryDate, currentDate });
    const link = `${window.location.origin}/pandit/${uid}/link/${lkid}/add/contactandshradham`;
    setShareText(link);
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  return (
    <>
      <ShareConfirmation
        handleDialogClose={handleShareDialogClose}
        open={shareDialogOpen}
        text={shareText}
        variant={SHARE_CONFIRMATION_VARIANTS.LINK}
      />
      <Card>
        <CardContent>
          By generating and sharing a new link with your customers you can
          gather their personal information without you entering them manually.
          The link <strong>automatically expires in 3 days</strong> from the
          time it was generated.
        </CardContent>
        <DialogActions>
          <Button size="small" color="primary" onClick={handleGenerateLink}>
            Generate
          </Button>
        </DialogActions>
      </Card>
    </>
  );
};

export default GenerateNewLink;
