import * as React from "react";
import { Button, styled, Grid, Typography, CardContent, Card, DialogActions, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link, useParams, Redirect } from "react-router-dom";
import {
  Contact,
  AncestorDetails,
  ContactSkeleton,
  CONTACT_VARIANT,
  Events as RenderEvents,
  EventRecord,
  Info as InfoProvider,
  INFO_VARIANT,
  EVENTS_VARIANT,
} from "../../../components";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { useFirebaseApp, useUser, useFirestoreDoc, useFirestoreCollection } from "reactfire";
import * as Shared from "../../../shared";

const InfoGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

enum RENDER_RECORDS_VARIANT {
  MASIGAM = "MASIGAM",
  SHRADHAM = "SHRADHAM",
}

interface InfoProps {}

const Ancestor: React.FunctionComponent<InfoProps> = (props) => {
  const { contactId } = useParams<{ contactId: string }>();

  return (
    <>
      <Grid container>
        <Grid item>
          <Button component={Link} to={`/contacts/${contactId}`}>
            <IconButton aria-label="back" size="small">
              <ArrowBack fontSize="inherit" />
            </IconButton>
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to={`/contacts/${contactId}/modifyancestor`}
          >
            Add / Edit Ancestor Details
          </Button>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}></Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}></Grid>
      </Grid>
      <InfoGridContainer container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant="h2" gutterBottom>
            Ancestor Details
          </Typography>
          <React.Suspense fallback={<ContactSkeleton />}>
            <RenderContactAncestor contactId={contactId} />
          </React.Suspense>
        </Grid>
      </InfoGridContainer>
    </>
  );
};

const RenderContactAncestor: React.FC<{ contactId: string }> = (props) => {
  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();
  const contactRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("contacts")
    .doc(props.contactId)
    .collection("relatives")
    .doc("ancestor");
  const ancestorSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(contactRef);
  const ancestor: Shared.Ancestor | undefined = ancestorSnapshot.data() as Shared.Ancestor | undefined;

  return (
    <>
      {props.contactId && !ancestorSnapshot.exists && <Redirect to={`/contacts/${props.contactId}/modifyancestor`} />}
      {ancestor && <AncestorDetails contactId={props.contactId} ancestorDetails={ancestor} />}
    </>
  );
};

interface RenderRecordsProps {
  contactId: string;
  variant: RENDER_RECORDS_VARIANT;
}

export default Ancestor;
