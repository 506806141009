import {
  Button,
  CircularProgress,
  Card,
  CardContent,
  DialogActions,
  InputAdornment,
  useTheme,
  withStyles
} from "@material-ui/core";
import * as firebase from "firebase/app";
import "firebase/auth";
import { Field, Form as FormikForm, Formik, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { useUser } from "reactfire";
import * as Yup from "yup";
import { RegisterStoreContext } from "../../../app";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { Redirect } from "react-router-dom";

interface LinkMobileForm {
  mobile: string;
}

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0
  },
  rect: {
    marginBottom: 0
  }
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = props => {
  const theme = useTheme();
  return (
    <>
      <Card>
        <CardContent>
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </Card>
    </>
  );
};

const Form: React.FC = observer(props => {
  // local datastructures used
  const validationSchema = Yup.object<{ mobile: string }>({
    mobile: Yup.string()
      .min(10, "Mobile Number is Invalid")
      .max(10, "Mobile Number is Invalid")
      .matches(/[0-9]/, "Mobile Number should contain only numbers")
      .required("Mobile Number is required")
  });

  // hooks used
  const [, setBoundryError] = useState();

  const user = useUser<firebase.User>();

  // TODO: Remove this only for testing purpose
  // firebase.auth().settings.appVerificationDisabledForTesting = true;

  const registerStore = useContext(RegisterStoreContext);
  registerStore.setRecaptchaVerifier(
    new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      size: "invisible"
    })
  );

  const handleSubmit = async (
    values: LinkMobileForm,
    { setSubmitting, setFieldError }: FormikActions<LinkMobileForm>
  ) => {
    try {
      if (!registerStore.recaptchaVerifier) {
        throw new Error("Invalid state in register store! At this point the recaptchaVerifier should have been set.");
      }

      window.recaptchaId = await registerStore.recaptchaVerifier.render();
      await registerStore.recaptchaVerifier.verify();

      const { mobile } = values;

      const confirmationResult = await user.linkWithPhoneNumber(`+91${mobile}`, registerStore.recaptchaVerifier);

      setSubmitting(false);
      registerStore.setConfirmationResult(confirmationResult);
    } catch (error) {
      // handled and applicable errors
      const errorCode:
        | "auth/email-already-in-use"
        | "auth/invalid-email"
        | "auth/weak-password"
        | "auth/captcha-check-failed"
        | "auth/invalid-phone-number"
        | "auth/credential-already-in-use"
        // unhandled and not applicable errors
        | "auth/provider-already-linked"
        | "auth/missing-phone-number"
        | "auth/user-disabled"
        // some rare errors handled with a common generic react error boundry
        | "auth/quota-exceeded"
        | "auth/operation-not-allowed"
        | "auth/internal-error"
        | undefined = error.code;

      switch (errorCode) {
        case "auth/email-already-in-use": {
          setFieldError("email", error.message);
          break;
        }
        case "auth/invalid-email": {
          setFieldError("email", error.message);
          break;
        }
        case "auth/weak-password": {
          setFieldError("password", error.message);
          break;
        }
        case "auth/captcha-check-failed": {
          if (!window.grecaptcha || window.recaptchaId !== undefined) {
            break;
          }
          window.grecaptcha.reset(window.recaptchaId);
          break;
        }
        default: {
          setBoundryError(() => {
            throw error;
          });
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {registerStore.confirmationResult && <Redirect to="/register/otp" />}
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          name: "",
          mobile: "",
          email: "",
          password: "",
          confirmPassword: "",
          readTerms: undefined
        }}
      >
        {({ isSubmitting, isValid }) => (
          <FormikForm>
            <Card>
              <CardContent>
                <Field
                  type="tel"
                  name="mobile"
                  label="Mobile"
                  component={TextField}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+91</InputAdornment>
                  }}
                />
              </CardContent>
              <DialogActions>
                <Button disabled={!isValid || isSubmitting} color="primary" size="small" type="submit">
                  {isSubmitting ? <CircularProgress /> : "Link"}
                </Button>
              </DialogActions>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </>
  );
});

export default Form;
