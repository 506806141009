import * as React from "react";
import {
  Container,
  Card,
  Typography,
  styled,
  CardContent,
  Button,
  CircularProgress,
  DialogActions
} from "@material-ui/core";
import {
  Info,
  INFO_VARIANT,
  PasswordConfirmation,
  PASSWORD_CONFIRMATION_VARIANT
} from "../../components";
import { Formik, Form, Field, FormikActions } from "formik";
import "firebase/auth";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import * as Shared from "../../shared";
import { useFirebaseApp } from "reactfire";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const NegativeSpace = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

interface ForgotPasswordProps {}

const ForgotPassword: React.FunctionComponent<ForgotPasswordProps> = props => {
  const validationSchema = Yup.object<Shared.Form.ForgotPassword>({
    email: Yup.string()
      .email("Enter a vaild email")
      .required("Email is required")
  });
  const firebaseApp = useFirebaseApp();

  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState<boolean>(
    false
  );
  const [isSendingEmail, setIsSendingEmail] = React.useState<boolean>(false);
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");

  const handlePasswordDialogClose = () => {
    setIsSendingEmail(false);
    setEmailSent(false);
    setPasswordDialogOpen(false);
  };

  const handlePasswordDialogOpen = () => {
    setPasswordDialogOpen(true);
  };

  const handleSendEmail = async () => {
    setIsSendingEmail(true);

    try {
      const auth = firebaseApp.auth();
      await auth.sendPasswordResetEmail(email);
      setEmailSent(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendingEmail(false);
    }
  };

  const handleSubmit = (
    values: Shared.Form.ForgotPassword,
    { setSubmitting }: FormikActions<Shared.Form.ForgotPassword>
  ) => {
    setEmail(values.email);
    handlePasswordDialogOpen();
    setSubmitting(false);
  };

  return (
    <>
      <PasswordConfirmation
        emailSent={emailSent}
        isSendingEmail={isSendingEmail}
        open={passwordDialogOpen}
        handleDialogClose={handlePasswordDialogClose}
        handleSendEmail={handleSendEmail}
        variant={PASSWORD_CONFIRMATION_VARIANT.FORGOT_PASSWORD}
      />
      <StyledContainer maxWidth="md">
        <Typography variant="h2" gutterBottom>
          Forgot password
        </Typography>
        <Card>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={{ email: "" }}
          >
            {({ isValid, isSubmitting }) => (
              <>
                <Form>
                  <CardContent>
                    <Info
                      picture="/images/undraw_forgot_password_gi2d.svg"
                      variant={INFO_VARIANT.MINIMAL}
                    />
                    <NegativeSpace />
                    <Typography variant="h4" align="center">
                      Tell us your Email
                    </Typography>
                    <Typography align="center">
                      Don't worry. Tell us for which Email you forgot the
                      password. We'll help you get your access back.
                    </Typography>
                    <Field
                      type="email"
                      name="email"
                      label="Email"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </CardContent>

                  <DialogActions>
                    <Button
                      disabled={!isValid || isSubmitting}
                      color="primary"
                      size="small"
                      type="submit"
                    >
                      {isSubmitting ? <CircularProgress /> : "Submit"}
                    </Button>
                  </DialogActions>
                </Form>
              </>
            )}
          </Formik>
        </Card>
      </StyledContainer>
    </>
  );
};

export default ForgotPassword;
