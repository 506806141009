import algoliasearch from "algoliasearch";
import { InstantSearch } from "react-instantsearch-dom";
import * as React from "react";
import { Button, styled, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ContactSkeleton, Search } from "../../components";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useUser } from "reactfire";
import * as config from "../../config";
import * as Algolia from "./Algolia";

const ContactsGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const ContactsSkeleton: React.FC = props => {
  return (
    <>
      <Grid container justify="flex-end" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button size="large" variant="contained" color="primary">
            Add Contact
          </Button>
        </Grid>
        <Grid item xs={12} md={5}>
          <Search
            placeholder="Search contacts"
            handleSearchClick={() => {}}
            handleSearchTyping={() => {}}
          />
        </Grid>
      </Grid>
      <ContactsGridContainer container spacing={3}>
        {[1, 2].map(val => (
          <Grid key={`contact-skeleton#${val}`} item xs={12} md={6}>
            <ContactSkeleton />
          </Grid>
        ))}
      </ContactsGridContainer>
    </>
  );
};

interface ContactsProps {}

const Contacts: React.FunctionComponent<ContactsProps> = props => {
  return (
    <>
      <React.Suspense fallback={<ContactsSkeleton />}>
        <ContactsWithAlgoliaSearch />
      </React.Suspense>
    </>
  );
};

const ContactsWithAlgoliaSearch: React.FC = props => {
  const algoliaClient = algoliasearch(
    config.algolia.appId,
    config.algolia.apiKey
  );

  const searchClient = {
    search(requests: any) {
      const shouldSearch = requests.some(
        ({ params: { query } }: any) => query !== ""
      );
      if (shouldSearch) {
        return algoliaClient.search(requests);
      }
      return Promise.resolve({
        results: [{ hits: [] }]
      });
    },
    searchForFacetValues: algoliaClient.searchForFacetValues
  };

  const user = useUser<firebase.User>();

  return (
    <>
      <InstantSearch searchClient={searchClient} indexName={user.uid}>
        <Grid container justify="flex-end" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
            <Button
              component={Link}
              size="large"
              variant="contained"
              color="primary"
              to="/contacts/add"
            >
              Add Contact
            </Button>
          </Grid>
          <Grid item xs={12} md={5}>
            <Algolia.Custom.SearchBox />
          </Grid>
        </Grid>

        <ContactsGridContainer container spacing={3}>
          <Algolia.Custom.Hits />
        </ContactsGridContainer>
      </InstantSearch>
    </>
  );
};

export default Contacts;
