/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid } from "@material-ui/core";
import * as React from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SubscriptionStoreContext } from "../../../app";
import { Plan, PlanSkeleton, PLAN_VARIANT } from "../../../components";

interface PlansProps {}

const Plans: React.FC<PlansProps & RouteComponentProps> = observer(props => {
  const subscriptionStore = React.useContext(SubscriptionStoreContext);
  React.useEffect(() => {
    subscriptionStore.viewPlansRequest();
  }, []);

  return (
    <>
      {subscriptionStore.chosenPlan && <Redirect to="/settings/checkoutplan" />}
      {(subscriptionStore.isLoadingPlans ||
        subscriptionStore.plans.length > 0) && (
        <>
          <Grid container alignItems="flex-end" spacing={5}>
            {subscriptionStore.isLoadingPlans &&
              [1, 2, 3].map((num, index) => (
                <Grid item xs={12} md={4} key={`plan-skeleton#${index}`}>
                  {index === 0 && (
                    <Typography variant="h2" gutterBottom>
                      Plans
                    </Typography>
                  )}
                  <PlanSkeleton />
                </Grid>
              ))}
            {subscriptionStore.plans.length > 0 &&
              subscriptionStore.plans.map((plan, index) => (
                <Grid item xs={12} md={4} key={`${plan.id}`}>
                  {index === 0 && (
                    <Typography variant="h2" gutterBottom>
                      Plans
                    </Typography>
                  )}
                  <Plan
                    plan={plan}
                    handleBuyNow={subscriptionStore.setChosenPlan}
                    variant={PLAN_VARIANT.WITH_ACTIONS}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
});

export default withRouter(Plans);
