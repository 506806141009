import {
  Button,
  Card,
  CardContent,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as Shared from "../../shared";
import * as Utils from "../../utils";
import { EventTypes } from "./event";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34),
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export enum EVENT_FORM_VARIANT {
  WITH_CONTACT = "WITH_CONTACT",
  WITHOUT_CONTACT = "WITHOUT_CONTACT",
  WITH_CONTACT_EDIT = "WITH_CONTACT_EDIT",
  WITHOUT_CONTACT_EDIT = "WITHOUT_CONTACT_EDIT",
}

export const FormSkeleton: React.FC<{
  variant: EVENT_FORM_VARIANT;
}> = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          {props.variant === EVENT_FORM_VARIANT.WITHOUT_CONTACT && (
            <>
              <Skeleton height={theme.spacing(7)} />
              <Skeleton height={theme.spacing(7)} />
            </>
          )}
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={70} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface FormPropsForWithContactVariant {
  contactId: string;
  variant: EVENT_FORM_VARIANT.WITH_CONTACT;
}

interface FormPropsForWithoutContactVariant {
  variant: EVENT_FORM_VARIANT.WITHOUT_CONTACT;
}

interface FormPropsForWithContactEditVariant {
  contactId: string;
  eventId: string;
  variant: EVENT_FORM_VARIANT.WITH_CONTACT_EDIT;
}

interface FormPropsForWithoutContactEditVariant {
  eventId: string;
  variant: EVENT_FORM_VARIANT.WITHOUT_CONTACT_EDIT;
}

type FormProps =
  | FormPropsForWithContactVariant
  | FormPropsForWithoutContactVariant
  | FormPropsForWithContactEditVariant
  | FormPropsForWithoutContactEditVariant;

const Form: React.FC<FormProps> = (props) => {
  const validationSchema = Yup.object<Shared.Form.EventWithContact | Shared.Form.EventWithoutContact>({
    ...(props.variant === EVENT_FORM_VARIANT.WITHOUT_CONTACT && {
      name: Yup.string().required("Name is required"),
      nickname: Yup.string(),
    }),
    date: Yup.mixed().required("Date is required"),
    eventStartTime: Yup.mixed(),
    eventName: Yup.string().required("Event name is required"),
    desc: Yup.string(),
    address: Yup.string().required("Address is required"),
    landmark: Yup.string(),
    mobileOne: Yup.string()
      .matches(/^\d{10}$/, "Mobile is invalid")
      .required("Mobile is required"),
    emailId: Yup.string().email("Email is invalid"),
  });

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const eventsRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("events");

  const eventSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(
    eventsRef.doc(
      (props as FormPropsForWithContactEditVariant | FormPropsForWithoutContactEditVariant).eventId || "undefined"
    )
  );

  const event: EventTypes | undefined = eventSnapshot.data() as EventTypes | undefined;

  const contactRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("contacts")
    .doc((props as FormPropsForWithContactVariant).contactId || "undefined");

  const contactSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(contactRef);
  const contact: Shared.Contact | undefined = contactSnapshot.data() as Shared.Contact | undefined;

  const history = useHistory();

  const handleSubmit = async (
    eventForm: Shared.Form.EventWithContact | Shared.Form.EventWithoutContact,
    { setSubmitting }: FormikActions<Shared.Form.EventWithContact | Shared.Form.EventWithoutContact>
  ) => {
    if (
      (props.variant === EVENT_FORM_VARIANT.WITH_CONTACT || props.variant === EVENT_FORM_VARIANT.WITH_CONTACT_EDIT) &&
      !contact
    ) {
      return;
    }

    const { contactId } = props as FormPropsForWithContactVariant;
    const event = Utils.createEvent({ contact, contactId, eventForm });

    if (
      props.variant === EVENT_FORM_VARIANT.WITH_CONTACT_EDIT ||
      props.variant === EVENT_FORM_VARIANT.WITHOUT_CONTACT_EDIT
    ) {
      await eventsRef.doc(props.eventId).update(event);
      setSubmitting(false);
      const redirectUrl =
        props.variant === EVENT_FORM_VARIANT.WITH_CONTACT_EDIT ? `/contacts/${props.contactId}` : "/events";
      history.push(redirectUrl);
      return;
    }

    await eventsRef.add(event);
    setSubmitting(false);
    const redirectUrl = props.variant === EVENT_FORM_VARIANT.WITH_CONTACT ? `/contacts/${props.contactId}` : "/events";
    history.push(redirectUrl);
  };

  return (
    <>
      {(props.variant === EVENT_FORM_VARIANT.WITH_CONTACT || props.variant === EVENT_FORM_VARIANT.WITH_CONTACT_EDIT) &&
        props.contactId &&
        !contact && <Redirect to="/404" />}
      <StyledCard>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            ...((props.variant === EVENT_FORM_VARIANT.WITHOUT_CONTACT ||
              props.variant === EVENT_FORM_VARIANT.WITHOUT_CONTACT_EDIT) && {
              name: (event && event.name) || "",
              nickname: (event && event.nickname) || "",
            }),
            date: (event && event.date && event.date.toDate()) || new Date(),
            eventStartTime: new Date(new Date().setHours(9, 0, 0, 0)),
            eventName: (event && event.eventName) || "",
            desc: (event && (event as Shared.Event.Event).desc) || "",
            address: (event && event.address) || (contact && contact.address) || "",
            landmark: (event && event.landmark) || (contact && contact.landmark) || "",
            mobileOne: (event && event.mobileOne) || (contact && contact.mobileOne) || "",
            emailId: (event && event.emailId) || (contact && contact.emailId) || "",
          }}
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => (
            <FormikForm>
              <CardContent>
                {props.variant === EVENT_FORM_VARIANT.WITHOUT_CONTACT && (
                  <>
                    <Field
                      type="text"
                      name="name"
                      label={
                        <>
                          Name{" "}
                          <Typography component="span" color="error">
                            *
                          </Typography>
                        </>
                      }
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      type="text"
                      name="nickname"
                      label="Nick Name"
                      component={StyledTextField}
                      fullWidth
                      variant="outlined"
                    />
                  </>
                )}
                <StyledDatePicker 
                  label={
                    <>
                      Event date{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  disabled={isSubmitting}
                  fullWidth
                  animateYearScrolling
                  inputVariant="outlined"
                  invalidDateMessage="Event date is invalid"
                  value={values.date || new Date()}
                  format="dd/MM/yyyy"
                  onChange={(date: Date | null) => {
                    if (!date) {
                      return;
                    }
                    setFieldValue("date", date);
                  }}
                />
                <StyledTimePicker
                  label="Event time"
                  clearable
                  fullWidth
                  disabled={isSubmitting}
                  inputVariant="outlined"
                  invalidDateMessage="Event time is invalid"
                  value={values.eventStartTime}
                  onChange={(date: Date | null) => {
                    setFieldValue("eventStartTime", date);
                  }}
                />
                <Field
                  type="text"
                  name="eventName"
                  label={
                    <>
                      Event Name{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="textarea"
                  name="desc"
                  label="Description"
                  component={StyledTextField}
                  multiline
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="textarea"
                  name="address"
                  label={
                    <>
                      Address{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  multiline
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="text"
                  name="landmark"
                  label="Landmark"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="text"
                  name="emailId"
                  label="Email"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="tel"
                  name="mobileOne"
                  label={
                    <>
                      Mobile{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
              </CardContent>
              <DialogActions>
                <Button
                  size="small"
                  disabled={isSubmitting}
                  component={Link}
                  to={
                    props.variant === EVENT_FORM_VARIANT.WITH_CONTACT ||
                    props.variant === EVENT_FORM_VARIANT.WITH_CONTACT_EDIT
                      ? `/contacts/${props.contactId}`
                      : "/events"
                  }
                >
                  Cancel
                </Button>
                <Button size="small" color="primary" disabled={!isValid || isSubmitting} type="submit">
                  {isSubmitting ? <CircularProgress /> : "Save"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};

export default Form;
