import * as React from "react";
import {
  Button,
  styled,
  Grid,
  Typography,
  IconButton,
  Card,
  CardActions,
  Avatar,
  CardHeader,
  CardContent,
  colors,
} from "@material-ui/core";
import { ArrowBack, Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Link, useParams, Redirect } from "react-router-dom";
import { ContactSkeleton } from "../../../components";
import { RelativeForm, RelativeFormSkeleton } from "../ModifyRelative";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { useFirebaseApp, useUser, useFirestoreDoc, useFirestoreCollection } from "reactfire";
import * as Shared from "../../../shared";

const InfoGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: 5,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: 5,
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: colors.red[500],
}));

enum RENDER_RECORDS_VARIANT {
  MASIGAM = "MASIGAM",
  SHRADHAM = "SHRADHAM",
}

interface InfoProps {}

const Relative: React.FunctionComponent<InfoProps> = (props) => {
  const { contactId } = useParams<{ contactId: string }>();

  return (
    <>
      <Grid container>
        <Grid item>
          <Button component={Link} to={`/contacts/${contactId}`}>
            <IconButton aria-label="back" size="small">
              <ArrowBack fontSize="inherit" />
            </IconButton>
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Button
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to={`/contacts/${contactId}/addRelative`}
          >
            Add Relative
          </Button>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}></Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}></Grid>
      </Grid>
      <InfoGridContainer container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant="h2" gutterBottom>
            Relative Details
          </Typography>
          <React.Suspense fallback={<ContactSkeleton />}>
            <RenderContactAncestor contactId={contactId} />
          </React.Suspense>
        </Grid>
      </InfoGridContainer>
    </>
  );
};

const RenderContactAncestor: React.FC<{ contactId: string }> = (props) => {
  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();
  const relativeRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("contacts")
    .doc(props.contactId)
    .collection("relatives")
    .doc("relativeinfo");
  const relativeSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(relativeRef);
  const RelativesInfo: Shared.RelativesInfo | undefined = relativeSnapshot.data() as Shared.RelativesInfo | undefined;
  const [edit, setEdit] = React.useState<boolean>(false);
  const [expandPanel, setExpandPanel] = React.useState<boolean>(false);
  const [arrayEdit, setArrayEdit] = React.useState<number>(0);
  const [relativeForm, setRelativeForm] = React.useState<Shared.RelativeForm>({
    name: "",
    natchatram: "",
    rasi: "",
    relation: "",
    gender: "M",
    dob: new Date(),
    birthMonth: "",
  });

  return (
    <>
      {RelativesInfo && RelativesInfo.relatives && (
        <>
          <Grid container>
            {Object.keys(RelativesInfo.relatives).map((key) => {
              const relative = RelativesInfo.relatives[key];
              return (
                <StyledGrid item xs={12} sm={6}>
                  <StyledCard>
                    <CardHeader
                      avatar={<StyledAvatar aria-label="recipe">{relative.name.charAt(0).toUpperCase()}</StyledAvatar>}
                      title={`${relative.name} (${relative.relation})`}
                      subheader={relative.dob && `${relative.dob.toDate().toDateString()}`}
                    />

                    <CardContent>
                      <Typography variant="body1" color="textPrimary" component="p">
                        Natchatram: {`${Shared.natchatramAsMap.get(relative.natchatram!)}`}
                        <br />
                        Rasi: {`${Shared.rasiAsMap.get(relative.rasi!)}`}
                        <br />
                        Birth Month: {`${Shared.masamAsMap.get(relative.birthMonth!)}`}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <IconButton
                        aria-label="Edit Relative"
                        component={Link}
                        to={`/contacts/${props.contactId}/modifyrelative/${key}`}
                      >
                        <EditIcon />
                      </IconButton>
                    </CardActions>
                  </StyledCard>
                </StyledGrid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

interface RenderRecordsProps {
  contactId: string;
  variant: RENDER_RECORDS_VARIANT;
}

export default Relative;
