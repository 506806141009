/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Container, Typography, styled } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Form, { FormSkeleton } from "./form";
import { LinkStoreContext } from "../../../../app";
import { observer } from "mobx-react-lite";
import { LINK_STATUS } from "../../../../stores";
import Info from "./info";
import { useFirebaseApp } from "reactfire";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

interface ContactAndShradhamProps {}

const ContactAndShradham: React.FC<ContactAndShradhamProps> = observer(
  props => {
    const linkStore = React.useContext(LinkStoreContext);
    const params = useParams<{ uid: string; lkid: string }>();

    linkStore.setLkid(params.lkid);
    linkStore.setUid(params.uid);

    const firebaseApp = useFirebaseApp();

    const verifyLink = async () => {
      const now = new Date().getTime();
      const linkSnapshot = await firebaseApp
        .firestore()
        .collection("custentry")
        .doc(params.uid)
        .collection("links")
        .doc(params.lkid)
        .get();

      if (
        !linkSnapshot.exists ||
        (linkSnapshot &&
          now > (linkSnapshot.data() as { expiryDate: number }).expiryDate)
      ) {
        linkStore.setLinkStatus(LINK_STATUS.ALREADY_USED);
        return;
      }

      linkStore.setLinkStatus(LINK_STATUS.READY_FOR_USE);
      return;
    };

    React.useEffect(() => {
      verifyLink();
    }, [linkStore]);

    return (
      <>
        <StyledContainer maxWidth="md">
          {!linkStore.linkStatus && (
            <>
              <Typography variant="h2">Add your personal details</Typography>
              <Typography gutterBottom>
                Add your personal details for sharing with your pandit.
              </Typography>
              <FormSkeleton />
            </>
          )}
          {linkStore.linkStatus &&
            linkStore.linkStatus === LINK_STATUS.ALREADY_USED && (
              <>
                <Info used />
              </>
            )}
          {linkStore.linkStatus && linkStore.linkStatus === LINK_STATUS.FAILED && (
            <>
              <Info error />
            </>
          )}

          {linkStore.linkStatus &&
            linkStore.linkStatus === LINK_STATUS.SUCCESSFULLY_USED && (
              <>
                <Info success />
              </>
            )}
          {linkStore.linkStatus &&
            linkStore.linkStatus === LINK_STATUS.READY_FOR_USE && (
              <>
                {/* <Grid
                  container
                  justify="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() => setAddShradhamOpen(true)}
                      disabled={linkStore.shradhams.length >= 5}
                    >
                      Add Shradham
                    </Button>
                  </Grid>
                </Grid> */}
                <Typography variant="h2">Add your personal details</Typography>
                <Typography gutterBottom>
                  Add your personal details for sharing with your pandit.
                </Typography>
                <Form />
              </>
            )}
        </StyledContainer>
      </>
    );
  }
);

export default ContactAndShradham;
