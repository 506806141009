import * as Shared from "../shared";

export function createShradhamRecordFromMasigam({
  masigam
}: {
  masigam: Shared.Masigam;
}): Shared.Shradham {
  const { contactId, forName, masam, paksham, panchangam, thithi } = masigam;

  const shradham: Shared.Shradham = {
    contactId,
    forName,
    masam,
    paksham,
    panchangam,
    thithi
  };

  return shradham;
}
