import * as React from "react";
import { ErrorInfo, Info, INFO_VARIANT } from "../../../../components";
import { Card, CardContent, Typography, styled } from "@material-ui/core";

const NegativeSpace = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

interface UsedInfoProps {
  error?: boolean;
  success?: boolean;
  used?: boolean;
}

const UsedInfo: React.FunctionComponent<UsedInfoProps> = props => {
  return (
    <>
      <Card>
        <CardContent>
          {props.used && (
            <>
              <Info picture="/images/undraw_time_management_30iu.svg" />
              <Typography variant="h4" align="center" gutterBottom>
                Link expired
              </Typography>
              <NegativeSpace />
              <Typography align="center">
                This link already has been used to input a contact and can't be
                used anymore. You'll have to contact your pandit if you want a
                new link.
              </Typography>
            </>
          )}
          {props.success && (
            <>
              <Info
                picture="/images/undraw_done_a34v.svg"
                variant={INFO_VARIANT.MINIMAL}
              />
              <NegativeSpace />
              <Typography variant="h4" align="center" gutterBottom>
                Thank you
              </Typography>
              <NegativeSpace />
              <Typography align="center">
                Thank you for taking your time to fill these details. Your
                pandit now will be able to access your details.
              </Typography>
            </>
          )}
          {props.error && <ErrorInfo />}
        </CardContent>
      </Card>
    </>
  );
};

export default UsedInfo;
