import * as config from "../config";

export function getCurrentPanchangamYear(): string {
  const currentDate = new Date().getTime();
  const currentYear = new Date(currentDate).getFullYear();

  const tamilNewYearStartDateFromConfig = config.tamilNewYearStartDate[currentYear];

  if (!tamilNewYearStartDateFromConfig) {
    throw new Error(`Tamil new year start date not found in the config for the Gregorian year ${currentYear}`);
  }

  const tamilNewYearStartDate = new Date(tamilNewYearStartDateFromConfig).getTime();

  const currentPanchangamYear =
    currentDate >= tamilNewYearStartDate ? `${currentYear}-${currentYear + 1}` : `${currentYear - 1}-${currentYear}`;

  return currentPanchangamYear;
}
