import * as Shared from "../shared";
import { getPreviousYear } from "./get-previous-year";

export function createMasigamRecord({
  contactId,
  currentPanchangamYear,
  masigamForm
}: {
  contactId: string;
  currentPanchangamYear: string;
  masigamForm: Shared.Form.Masigam;
}): Shared.Masigam {
  const { lastYear, ...restOfMasigamForm } = masigamForm;
  const masigam: Shared.Masigam = {
    ...restOfMasigamForm,
    contactId,
    karmaYear: lastYear
      ? getPreviousYear(currentPanchangamYear)
      : currentPanchangamYear
  };

  return masigam;
}
