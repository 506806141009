import { Theme, makeStyles, createStyles } from "@material-ui/core";

// NOTE: For styles which uses mixins is better used with the hooks API of overriding styles
// Example - Not all styles from the mixin.toolbar would be applied using the below styled API
// export const ToolbarVoidFiller = styled("div")(
//   ({ theme }) => theme.mixins.toolbar
// );

export const useSharedStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    bigAvatar: {
      height: 60,
      width: 60
    }
  })
);
