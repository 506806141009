export const THITHI = [
  { value: "1", label: "Prathamai" },
  { value: "2", label: "Dwithiyai" },
  { value: "3", label: "Thrithiyai" },
  { value: "4", label: "Chathurthi" },
  { value: "5", label: "Panchami" },
  { value: "6", label: "Sasthi" },
  { value: "7", label: "Sapthami" },
  { value: "8", label: "Ashtami" },
  { value: "9", label: "Navami" },
  { value: "10", label: "Dasami" },
  { value: "11", label: "Ekadhasi" },
  { value: "12", label: "Dwadhasi" },
  { value: "13", label: "Thrayodhasi" },
  { value: "14", label: "Chathurdhasi" },
  { value: "15", label: "Amavasai/Poornami" }
];

const thithi: [string, string][] = THITHI.reduce(
  (acc, curr, index) => {
    if (index === 0) {
      return [[curr.value, curr.label]];
    }
    return [...acc, [curr.value, curr.label]];
  },
  [["", ""]]
);

export const thithiAsMap = new Map(thithi);
