import { Typography, Grid } from "@material-ui/core";
import * as React from "react";
import { Profile, ProfileSkeleton } from "./Profile";
import { Subscription, SubscriptionSkeleton } from "./Subscription";
import { GenerateNewLink, GenerateNewLinkSkeleton } from "./GenerateNewLink";
import { observer } from "mobx-react-lite";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = observer(props => {
  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <Typography variant="h2" gutterBottom>
            Profile
          </Typography>
          <React.Suspense fallback={<ProfileSkeleton />}>
            <Profile />
          </React.Suspense>
        </Grid>
        <Grid item xs={12} md>
          <Typography variant="h2" gutterBottom>
            Membership
          </Typography>
          <React.Suspense fallback={<SubscriptionSkeleton />}>
            <Subscription />
          </React.Suspense>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h2" gutterBottom>
            Generate new link for customer entry
          </Typography>
          <React.Suspense fallback={<GenerateNewLinkSkeleton />}>
            <GenerateNewLink />
          </React.Suspense>
        </Grid>
      </Grid>
    </>
  );
});

export default Settings;
