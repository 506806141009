import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  styled,
  withStyles,
  List,
  ListItem,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import * as React from "react";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { Plan as SubscriptionPlan } from "../../shared";

const StyledCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #ff3d00 30%, #b22a00 90%)",
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: "white",
}));

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const PlanSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <StyledSkeleton width={220} />
          <StyledSkeleton width={235} height={theme.spacing(4)} />
          <NegativeSpace />
          <Skeleton width={90} height={theme.spacing(3)} />
          <NegativeSpace />
          <StyledSkeleton style={{ marginLeft: theme.spacing(3) }} />
          <NegativeSpace />
          <StyledSkeleton style={{ marginLeft: theme.spacing(3) }} />
          <NegativeSpace />
          <Skeleton />
          <NegativeSpace />
        </CardContent>
        <CardActions>
          <Grid container justify="center">
            <StyledSkeleton width={80} height={theme.spacing(5)} />
          </Grid>
        </CardActions>
      </StyledCard>
    </>
  );
};

export enum PLAN_VARIANT {
  WITHOUT_ACTIONS,
  WITH_ACTIONS,
}

type PlanProps =
  | {
      plan: SubscriptionPlan;
      variant: PLAN_VARIANT.WITHOUT_ACTIONS;
    }
  | {
      plan: SubscriptionPlan;
      handleBuyNow(plan: SubscriptionPlan): void;
      variant: PLAN_VARIANT.WITH_ACTIONS;
    };

const Plan: React.FC<PlanProps> = (props) => {
  const name = props.plan.item.name;
  const description = props.plan.item.description;
  const terms = Object.keys(props.plan.notes)
    .filter((key: string) => key.includes("term"))
    .map((key: string) => props.plan.notes[key]);
  return (
    <>
      <StyledCard>
        <CardContent>
          <Typography color="inherit">{name}</Typography>
          <Typography variant="h2">{description}</Typography>
          <NegativeSpace />
          {terms && terms.length > 0 && (
            <>
              <Typography variant="h4" color="inherit" noWrap>
                Terms
              </Typography>
              <List>
                {terms.map((term, index) => (
                  <React.Fragment key={`custom-plan-term#${index}`}>
                    <ListItem>
                      <Typography color="inherit">{term}</Typography>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </>
          )}
          {props.plan.notes && props.plan.notes.tagline && (
            <Typography variant="body2" color="inherit">
              {props.plan.notes.tagline}
            </Typography>
          )}
        </CardContent>
        {props.variant === PLAN_VARIANT.WITH_ACTIONS && (
          <CardActions>
            <Grid container justify="center">
              <Button variant="contained" size="large" onClick={() => props.handleBuyNow(props.plan)}>
                Buy now
              </Button>
            </Grid>
          </CardActions>
        )}
      </StyledCard>
    </>
  );
};

export default Plan;
