export function getPreviousYear(year: string): string {
  const [startYearInString, endYearInString] = year.split("-");

  const startYearInInt = parseInt(startYearInString, 10);
  const endYearInInt = parseInt(endYearInString, 10);

  if (isNaN(startYearInInt) || isNaN(endYearInInt)) {
    throw new Error(`Invalid input(${year}) to the function getPreviousYear`);
  }

  const previousStartYearInInt = startYearInInt - 1;
  const previousEndYearInInt = endYearInInt - 1;

  const previousYear = `${previousStartYearInInt}-${previousEndYearInInt}`;
  return previousYear;
}
