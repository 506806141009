import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  InputLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField, Select } from "formik-material-ui";
import * as Yup from "yup";
import { Link, useHistory, Redirect } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import * as Shared from "../../shared";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={70} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </StyledCard>
    </>
  );
};

export enum CONTACT_FORM_VARIANT {
  ADD = "ADD",
  EDIT = "EDIT",
}

interface FormPropsAddVariant {
  variant?: CONTACT_FORM_VARIANT.EDIT;
}

interface FormPropsEditVariant {
  contactId: string;
  variant?: CONTACT_FORM_VARIANT.EDIT;
}

export type FormProps = FormPropsAddVariant | FormPropsEditVariant;

const Form: React.FC<FormProps> = (props) => {
  const validationSchema = Yup.object<Shared.Contact>({
    name: Yup.string().required("Name is required"),
    nickname: Yup.string(),
    address: Yup.string(),
    landmark: Yup.string(),
    emailId: Yup.string().email("Email is invalid"),
    panchangam: Yup.string().min(2).required("Panchangam is required"),
    mobileOne: Yup.string().matches(/^\d{10}$/, "Mobile is invalid"),
    telOne: Yup.string().matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Telephone is invalid"
    ),
    vedam: Yup.string().test("vedam-match", "Vedam is required", function (val) {
      return val;
    }),
  });

  const vedamLabel = React.useRef<HTMLLabelElement>(null);
  const panchangamLabel = React.useRef<HTMLLabelElement>(null);
  const [vedamLabelWidth, setVedamLabelWidth] = React.useState<number>(0);
  const [panchangamLabelWidth, setPanchangamLabelWidth] = React.useState<number>(0);
  React.useEffect(() => {
    setVedamLabelWidth(vedamLabel.current!.offsetWidth);
    setPanchangamLabelWidth(panchangamLabel.current!.offsetWidth);
  }, []);

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();
  const eventsRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("events");
  const ref = firebaseApp.firestore().collection("users").doc(user.uid).collection("contacts");

  let contact: Shared.Contact | undefined = undefined;

  // NOTE: It's more like an adhoc workaround and thus not an elegant solution
  const contactSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(
    ref.doc((props as FormPropsEditVariant).contactId || "undefined")
  );

  if ((props as FormPropsEditVariant).contactId) {
    contact = contactSnapshot.data() as Shared.Contact | undefined;
  }

  const history = useHistory();

  const handleSubmit = async (values: Shared.Contact, { setSubmitting }: FormikActions<Shared.Contact>) => {
    const { address, emailId, landmark, mobileOne, name, nickname, panchangam, telOne, vedam } = values;
    const contact = {
      name,
      panchangam,
      ...(address && { address }),
      ...(emailId && { emailId }),
      ...(landmark && { landmark }),
      ...(mobileOne && { mobileOne }),
      ...(nickname && { nickname }),
      ...(telOne && { telOne }),
      ...(vedam && { vedam }),
    };

    if (props.variant === CONTACT_FORM_VARIANT.EDIT) {
      const contactId = (props as FormPropsEditVariant).contactId;
      const eventsSnapshot = await eventsRef
        .where("contactId", "==", contactId)
        .where("date", ">", new Date())
        .orderBy("date", "asc")
        .get();
      const eventUpdatePromises = eventsSnapshot.docs.map((event) => {
        return event.ref.update(contact);
      });
      const contactUpdatePromise = ref.doc((props as FormPropsEditVariant).contactId).update(contact);
      await Promise.all([contactUpdatePromise, ...eventUpdatePromises]);
      setSubmitting(false);
      history.push(`/contacts/${contactId}`);
      return;
    }

    const contactRef = await ref.add(contact);
    setSubmitting(false);
    history.push(`/contacts/${contactRef.id}`);
  };

  return (
    <>
      {props.variant === CONTACT_FORM_VARIANT.EDIT && (props as FormPropsEditVariant).contactId && !contact && (
        <Redirect to="/404" />
      )}
      <StyledCard>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={
            contact || {
              name: "",
              nickname: "",
              address: "",
              landmark: "",
              emailId: "",
              panchangam: "",
              mobileOne: "",
              telOne: "",
              vedam: "",
            }
          }
        >
          {({ isSubmitting, isValid, errors, touched }) => (
            <FormikForm>
              <CardContent>
                <Field
                  type="text"
                  name="name"
                  label={
                    <>
                      Name{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="text"
                  name="nickname"
                  label="Nick Name"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="textarea"
                  name="address"
                  label="Address"
                  component={StyledTextField}
                  multiline
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="text"
                  name="landmark"
                  label="Landmark"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="text"
                  name="emailId"
                  label="Email"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={panchangamLabel}
                    htmlFor="contact-panchangam"
                    error={(touched && touched.panchangam && errors && errors.panchangam) as boolean}
                  >
                    Panchangam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="panchangam"
                    component={Select}
                    labelWidth={panchangamLabelWidth}
                    inputProps={{
                      id: "contact-panchangam",
                    }}
                    error={touched && touched.panchangam && errors && errors.panchangam}
                  >
                    <option value="" hidden></option>
                    {Shared.PANCHANGAM.map((option: string, index: number) => (
                      <option key={`panchangam-${option}#${index}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.panchangam && errors && errors.panchangam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.panchangam}
                  </FormHelperText>
                )}
                <Field
                  type="tel"
                  name="mobileOne"
                  label="Mobile"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <Field
                  type="tel"
                  name="telOne"
                  label="Telephone"
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={vedamLabel}
                    htmlFor="contact-vedam"
                    error={(touched && touched.vedam && errors && errors.vedam) as boolean}
                  >
                    Vedam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="vedam"
                    component={Select}
                    labelWidth={vedamLabelWidth}
                    inputProps={{
                      id: "contact-vedam",
                    }}
                    error={touched && touched.vedam && errors && errors.vedam}
                  >
                    <option value="" hidden></option>
                    {Shared.VEDAM.map((option: string, index: number) => (
                      <option key={`vedam-${option}#${index}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.vedam && errors && errors.vedam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.vedam}
                  </FormHelperText>
                )}
              </CardContent>
              <DialogActions>
                {props.variant === CONTACT_FORM_VARIANT.EDIT ? (
                  <Button
                    size="small"
                    disabled={isSubmitting}
                    component={Link}
                    to={`/contacts/${(props as FormPropsEditVariant).contactId}`}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button size="small" disabled={isSubmitting} component={Link} to="/contacts">
                    Cancel
                  </Button>
                )}
                <Button size="small" color="primary" disabled={!isValid || isSubmitting} type="submit">
                  {isSubmitting ? <CircularProgress /> : "Save"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};

export default Form;
