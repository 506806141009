import { styled, Container } from "@material-ui/core";
import React, { useContext, SyntheticEvent } from "react";
import Header from "./header";
import SideNavbar from "./side-navbar";
import { useSharedStyles } from "./styles";
import MembershipHandler from "./membership-handler";
import LinkMobileHandler from "./link-mobile-handler";
import { observer } from "mobx-react-lite";
import { ToastStoreContext } from "../../app";
import Toast from "./toast";
import * as Shared from "../../shared";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(3)
}));

const Page = styled("div")({
  display: "flex"
});

const MainContent = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3)
}));

const Main: React.FC = observer(props => {
  const sharedClasses = useSharedStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [toast, setToast] = React.useState<Shared.IToast>();

  const toastStore = useContext(ToastStoreContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setMobileOpen(!mobileOpen);
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setToast(undefined);
  };

  if (toastStore.toasts && toastStore.toasts.length > 0) {
    const toast = toastStore.toasts.pop();
    setToast(toast);
  }

  return (
    <Page>
      <Toast
        handleClose={handleClose}
        open={toast ? true : false}
        message={(toast && toast.body) || ""}
        variant={toast ? toast.severity : "success"}
      />
      <Header
        title="Responsive Navbar"
        handleDrawerToggle={handleDrawerToggle}
      />
      <SideNavbar toggleDrawer={toggleDrawer} open={mobileOpen} />
      <MainContent>
        <div className={sharedClasses.toolbar} />
        <StyledContainer maxWidth="md">
          <React.Suspense fallback="">
            <MembershipHandler />
            <LinkMobileHandler />
          </React.Suspense>
          {props.children}
        </StyledContainer>
      </MainContent>
    </Page>
  );
});

export default Main;
