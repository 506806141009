import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import {
  Contacts as ContactIcon,
  Dashboard as DashboardIcon,
  Event as EventIcon,
  ExitToApp as SignOutIcon,
  Settings as SettingIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import "firebase/auth";
import React from "react";
import { useFirebaseApp } from "reactfire";
import ProfileInfo, { ProfileInfoSkeleton } from "./profile-info";
import { useSharedStyles } from "./styles";
import Upgrade from "./upgrade";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      color: theme.palette.primary.main,
    },
    inactive: {
      color: theme.palette.text.secondary,
    },
  })
);

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(4),
  color: "inherit",
}));

const SignOutListItemIcon = styled(StyledListItemIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const SideNavbarContent: React.FC<RouteComponentProps> = (props) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const firebaseApp = useFirebaseApp();
  const auth = firebaseApp.auth();

  const { pathname } = props.location;

  return (
    <>
      <StyledContainer>
        <Hidden xsDown>
          <div className={sharedClasses.toolbar} />
        </Hidden>

        <React.Suspense fallback={<ProfileInfoSkeleton />}>
          <ProfileInfo />
        </React.Suspense>

        <StyledDivider />

        <List>
          <ListItem
            button
            component={Link}
            to="/"
            className={clsx(pathname === "/" ? classes.active : classes.inactive)}
          >
            <StyledListItemIcon>
              <DashboardIcon />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Typography variant="h6" color="inherit">
                  Dashboard
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/contacts"
            className={clsx(pathname.includes("/contacts") ? classes.active : classes.inactive)}
          >
            <StyledListItemIcon>
              <ContactIcon />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Typography variant="h6" color="inherit">
                  Contacts
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/events"
            className={clsx(pathname.includes("/events") ? classes.active : classes.inactive)}
          >
            <StyledListItemIcon>
              <EventIcon />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Typography variant="h6" color="inherit">
                  Events
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/settings"
            className={clsx(pathname.includes("/settings") ? classes.active : classes.inactive)}
          >
            <StyledListItemIcon>
              <SettingIcon />
            </StyledListItemIcon>
            <ListItemText
              primary={
                <Typography variant="h6" color="inherit">
                  Settings
                </Typography>
              }
            />
          </ListItem>
        </List>

        <StyledDivider />

        <List>
          <ListItem component={Link} to="/login" button onClick={() => auth.signOut()}>
            <SignOutListItemIcon>
              <SignOutIcon color="inherit" />
            </SignOutListItemIcon>
            <ListItemText
              primary={
                <Typography variant="h6" color="textSecondary">
                  Sign out
                </Typography>
              }
            />
          </ListItem>
        </List>

        <StyledDivider />

        <React.Suspense fallback="">
          <Upgrade />
        </React.Suspense>
      </StyledContainer>
    </>
  );
};

export default withRouter(SideNavbarContent);
