/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { ContactSkeleton, Events, EVENTS_VARIANT } from "../../components";
import { styled, Grid, Typography, CardContent, Card, DialogActions, Button } from "@material-ui/core";
import { ReviewContact, Info as InfoProvider, INFO_VARIANT } from "../../components";
import * as Shared from "../../shared";
import { Link } from "react-router-dom";
import { useFirebaseApp, useUser, useFirestoreCollection } from "reactfire";

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const InfoGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

interface InfoProps {}

const Info: React.FunctionComponent<InfoProps> = (props) => {
  const start = new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate());
  const end = new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 1);
  return (
    <>
      <InfoGridContainer container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            Today's Events
          </Typography>
          <React.Suspense fallback={<ContactSkeleton />}>
            <Events start={start} end={end} variant={EVENTS_VARIANT.FILTER_BY_TODAY} />
          </React.Suspense>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            Contact Reviews
          </Typography>
          <React.Suspense fallback={<ContactSkeleton />}>
            <RenderContacts />
          </React.Suspense>
        </Grid>
      </InfoGridContainer>
    </>
  );
};

interface RenderContactsProps {}

const RenderContacts: React.FC<RenderContactsProps> = (props) => {
  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const dataRef = firebaseApp.firestore().collection("custentry").doc(user.uid).collection("data");
  const dataSnapshot: firebase.firestore.QuerySnapshot = useFirestoreCollection(
    dataRef
  ) as firebase.firestore.QuerySnapshot;
  const data = dataSnapshot ? dataSnapshot.docs : undefined;

  return (
    <>
      {((data && data.length === 0) || !dataSnapshot) && (
        <Card>
          <CardContent>
            <InfoProvider picture="/images/undraw_no_data_qbuo.svg" variant={INFO_VARIANT.MINIMAL} />
            <NegativeSpace />
            <Grid container direction="column" alignItems="center">
              <Typography variant="h3">No Review Contacts</Typography>
              <Typography variant="body1" color="textSecondary">
                Try a different search query or add new contacts.
              </Typography>
            </Grid>
          </CardContent>
          <DialogActions>
            <Button color="primary" size="small" component={Link} to="/contacts/add">
              Add Contact
            </Button>
          </DialogActions>
        </Card>
      )}
      {dataSnapshot &&
        data &&
        data.length > 0 &&
        data.map((doc, index) => (
          <ReviewContact
            key={`${doc.id}#${index}`}
            {...{
              dataid: doc.id,
              ...(doc.data() as {
                contact: Shared.Contact;
                shradhams: Shared.Shradham[];
              }),
            }}
          />
        ))}
    </>
  );
};

export default Info;
