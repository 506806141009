import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  styled,
  CircularProgress,
  withStyles,
  useTheme,
  DialogActions,
  InputLabel,
  FormControl,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import * as React from "react";
import { Form as FormikForm, Formik, Field, FormikActions } from "formik";
import { TextField, Select, Checkbox } from "formik-material-ui";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import * as Shared from "../../../shared";
import { useFirebaseApp, useUser, useFirestoreDoc } from "reactfire";
import * as Utils from "../../../utils";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(34),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const FormSkeleton: React.FC = (props) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <CardContent>
          <Skeleton width={230} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
          <Skeleton height={theme.spacing(7)} />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={70} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
        </DialogActions>
      </StyledCard>
    </>
  );
};

interface MasigamForm {
  lastYear?: boolean;
  forName: string;
  masam: string;
  paksham: string;
  thithi: string;
  panchangam: string;
}

interface FormProps {
  contactId: string;
}

const Form: React.FC<FormProps> = (props) => {
  const validationSchema = Yup.object<MasigamForm>({
    lastYear: Yup.bool(),
    forName: Yup.string().required("Masigam for is required"),
    masam: Yup.string().required("Masam is required"),
    paksham: Yup.string().required("Paksham is required"),
    thithi: Yup.string().required("Thithi is required"),
    panchangam: Yup.string().required("Panchangam is required"),
  });

  const masamLabel = React.useRef<HTMLLabelElement>(null);
  const pakshamLabel = React.useRef<HTMLLabelElement>(null);
  const thithiLabel = React.useRef<HTMLLabelElement>(null);
  const panchangamLabel = React.useRef<HTMLLabelElement>(null);
  const [masamLabelWidth, setMasamLabelWidth] = React.useState<number>(0);
  const [pakshamLabelWidth, setPakshamLabelWidth] = React.useState<number>(0);
  const [thithiLabelWidth, setThithiLabelWidth] = React.useState<number>(0);
  const [panchangamLabelWidth, setPanchangamLabelWidth] = React.useState<number>(0);
  React.useEffect(() => {
    setMasamLabelWidth(masamLabel.current!.offsetWidth);
    setPakshamLabelWidth(pakshamLabel.current!.offsetWidth);
    setThithiLabelWidth(thithiLabel.current!.offsetWidth);
    setPanchangamLabelWidth(panchangamLabel.current!.offsetWidth);
  }, []);

  const firebaseApp = useFirebaseApp();
  const user = useUser<firebase.User>();

  const panchangamRef = firebaseApp.firestore().collection("panchangam");
  const eventsRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("events");
  const masigamRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("masigam");
  const shradhamRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("shardham");
  // NOTE: Don't change the spelling mistake

  const contactRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("contacts")
    .doc(props.contactId);

  const contactSnapshot: firebase.firestore.DocumentSnapshot = useFirestoreDoc(contactRef);
  const contact: Shared.Contact | undefined = contactSnapshot.data() as Shared.Contact | undefined;

  const history = useHistory();

  const handleSubmit = async (masigamForm: MasigamForm, { setSubmitting }: FormikActions<MasigamForm>) => {
    if (!contact) {
      return;
    }

    const { contactId } = props;
    const { lastYear, masam, panchangam } = masigamForm;

    // 1. Determine the currentPanchangamYear and panchangamDocName
    const currentPanchangamYear = Utils.getCurrentPanchangamYear();
    const panchangamDocName = Utils.getPanchangamDocName(panchangam, currentPanchangamYear);

    // 2. Fetch the panchangamDateSheet from the database for the panchangamDocName
    const panchangamSnapshot = await panchangamRef.doc(panchangamDocName).get();

    // 3. If the panchangamDateSheet for the currentPanchangamYear doesn't exists fail early
    if (!panchangamSnapshot.exists) {
      // TODO: Let the pandit know that the panchangamDateSheet is not yet released for the currentPanchangamYear
      console.log("Exiting because no panchamgamDateSheet found for the", panchangamDocName);
      return;
    }

    // 4. Check if the special edge case is applicable for the masigamForm
    // if yes handle it differently
    const masamInInt: number = parseInt(masam, 10);
    // if (!lastYear && masamInInt === 12) {
    //   // TODO: This is a special edge case needs to be handled differently
    // }

    // 5. Create an undefined events array of appropriate length based on the boolean lastYear
    let events: undefined[];
    if (lastYear) {
      events = new Array(masamInInt).fill(undefined);
    } else {
      events = new Array(12 - masamInInt).fill(undefined);
    }

    // 6. Load the panchangamDateSheet
    const panchangamDateSheet = panchangamSnapshot.data() as Shared.PanchangamDateSheet;

    // 7. Create all the masigams first
    const masigams: Array<Shared.Event.Masigam | Shared.Event.VarushaAabdhigam | undefined> = Utils.createMasigams({
      contact,
      contactId,
      events,
      masamInInt,
      masigamForm,
      panchangamDateSheet,
    });

    // 8. create all the sodagumbams next
    const sodagumbams: Array<
      Shared.Event.Sodagumbam | Shared.Event.AabdhigaSodagumbam | undefined
    > = Utils.createSodagumbams({ masigams, masigamForm });

    // 9. create the masigam record
    const masigam: Shared.Masigam = Utils.createMasigamRecord({
      contactId,
      currentPanchangamYear,
      masigamForm,
    });
    // 9.1 Mark the masigam that events for currentPanchangamYear has been addded
    masigam[currentPanchangamYear] = true;

    const { id: masigamId } = await masigamRef.add(masigam);

    const masigamPromises: Promise<firebase.firestore.DocumentReference>[] = (masigams.filter(
      (masigam) => masigam
    ) as Array<Shared.Event.Masigam | Shared.Event.VarushaAabdhigam>).map((masigam) => {
      masigam.masigamId = masigamId;
      return eventsRef.add(masigam);
    });

    const sodagumbaPromises: Promise<firebase.firestore.DocumentReference>[] = (sodagumbams.filter(
      (sodagumbam) => sodagumbam
    ) as Array<Shared.Event.Sodagumbam | Shared.Event.AabdhigaSodagumbam>).map((sodagumbam) => {
      sodagumbam.masigamId = masigamId;
      return eventsRef.add(sodagumbam);
    });

    const eventPromises: Promise<firebase.firestore.DocumentReference>[] = [...masigamPromises, ...sodagumbaPromises];

    // 10. If lastYear was set if yes then create shradham and shubam events
    if (lastYear) {
      const aabdhigaSodagumbam = sodagumbams[sodagumbams.length - 1];

      if (
        !aabdhigaSodagumbam ||
        (aabdhigaSodagumbam && !aabdhigaSodagumbam.eventName.includes("Aabdhiga Sodagumbam"))
      ) {
        // TODO: Confirm if this is possible
        return;
      }

      const shubam: Shared.Event.Shubam = Utils.createShubamFromAabdhigaSodagumbam({ aabdhigaSodagumbam });

      eventPromises.push(eventsRef.add(shubam));

      // TODO: Confirm from Vaidhy is it needed to create an event for this shradhamRecord
      const shradham: Shared.Shradham = Utils.createShradhamRecordFromMasigam({
        masigam,
      });

      eventPromises.push(shradhamRef.add(shradham));
    }

    await Promise.all(eventPromises);
    setSubmitting(false);
    history.push(`/contacts/${props.contactId}`);
  };

  return (
    <>
      {props.contactId && !contact && <Redirect to="/404" />}
      <StyledCard>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            lastYear: undefined,
            forName: "",
            masam: "",
            paksham: "",
            thithi: "",
            panchangam: (contact && contact.panchangam) || "",
          }}
        >
          {({ isSubmitting, isValid, errors, touched }) => (
            <FormikForm>
              <CardContent>
                <FormControlLabel
                  control={<Field type="checkbox" name="lastYear" component={Checkbox} color="primary" />}
                  label={<Typography variant="body1">Carried over from last year</Typography>}
                />
                <Field
                  type="text"
                  name="forName"
                  label={
                    <>
                      Masigam for{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </>
                  }
                  component={StyledTextField}
                  fullWidth
                  variant="outlined"
                />
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={masamLabel}
                    htmlFor="shradham-masam"
                    error={touched && touched.masam && errors && errors.masam ? true : false}
                  >
                    Masam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="masam"
                    component={Select}
                    labelWidth={masamLabelWidth}
                    inputProps={{
                      id: "shradham-masam",
                    }}
                    error={touched && touched.masam && errors && errors.masam ? true : false}
                  >
                    <option value="" hidden></option>
                    {Shared.MASAM.map((option, index: number) => (
                      <option key={`masam-${option.label}#${index}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.masam && errors && errors.masam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.masam}
                  </FormHelperText>
                )}
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={pakshamLabel}
                    htmlFor="shradham-paksham"
                    error={touched && touched.paksham && errors && errors.paksham ? true : false}
                  >
                    Paksham{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="paksham"
                    component={Select}
                    labelWidth={pakshamLabelWidth}
                    inputProps={{
                      id: "shradham-paksham",
                    }}
                    error={touched && touched.paksham && errors && errors.paksham ? true : false}
                  >
                    <option value="" hidden></option>
                    {Shared.PAKSHAM.map((option, index: number) => (
                      <option key={`paksham-${option.label}#${index}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.paksham && errors && errors.paksham && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.paksham}
                  </FormHelperText>
                )}
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={thithiLabel}
                    htmlFor="shradham-thithi"
                    error={touched && touched.thithi && errors && errors.thithi ? true : false}
                  >
                    Thithi{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="thithi"
                    component={Select}
                    labelWidth={thithiLabelWidth}
                    inputProps={{
                      id: "shradham-thithi",
                    }}
                    error={touched && touched.thithi && errors && errors.thithi ? true : false}
                  >
                    <option value="" hidden></option>
                    {Shared.THITHI.map((option, index: number) => (
                      <option key={`thithi-${option.label}#${index}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.thithi && errors && errors.thithi && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.thithi}
                  </FormHelperText>
                )}
                <StyledFormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={panchangamLabel}
                    htmlFor="contact-panchangam"
                    error={touched && touched.panchangam && errors && errors.panchangam ? true : false}
                  >
                    Panchangam{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Field
                    native
                    type="text"
                    name="panchangam"
                    component={Select}
                    labelWidth={panchangamLabelWidth}
                    inputProps={{
                      id: "contact-panchangam",
                    }}
                    error={touched && touched.panchangam && errors && errors.panchangam ? true : false}
                  >
                    <option value="" hidden></option>
                    {Shared.PANCHANGAM.map((option: string, index: number) => (
                      <option key={`panchangam-${option}#${index}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                </StyledFormControl>
                {touched && touched.panchangam && errors && errors.panchangam && (
                  <FormHelperText style={{ marginLeft: 14 }} error required>
                    {errors.panchangam}
                  </FormHelperText>
                )}
              </CardContent>
              <DialogActions>
                <Button size="small" disabled={isSubmitting} component={Link} to={`/contacts/${props.contactId}`}>
                  Cancel
                </Button>
                <Button size="small" color="primary" disabled={!isValid || isSubmitting} type="submit">
                  {isSubmitting ? <CircularProgress /> : "Save"}
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};

export default Form;
