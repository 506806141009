import { Typography } from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Form, { FormSkeleton } from "./form";

interface EditProfileProps {}

const EditProfile: React.FC<EditProfileProps & RouteComponentProps> = props => {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Edit profile
      </Typography>
      <React.Suspense fallback={<FormSkeleton />}>
        <Form />
      </React.Suspense>
    </>
  );
};

export default withRouter(EditProfile);
