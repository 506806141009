import * as React from "react";
import { Typography, Grid, styled, Card, CardActionArea, CardContent } from "@material-ui/core";
import { EventRecord, EVENT_RECORD_VARIANT } from "../../../../components";
import { LinkStoreContext } from "../../../../app";
import * as Shared from "../../../../shared";
import AddShradhamDialog from "./add-shradham-dialog";
import { observer } from "mobx-react-lite";

const StyledCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #b22a00 30%, #ff3d00 90%)",
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: "white",
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0, 2, 0),
}));

interface AddShradhamProps {}

const AddShradham: React.FC<AddShradhamProps> = observer((props) => {
  const linkStore = React.useContext(LinkStoreContext);

  const [addShradhamOpen, setAddShradhamOpen] = React.useState<boolean>(false);

  const handleAddShradhamDialogClose = () => {
    setAddShradhamOpen(false);
  };

  return (
    <>
      <AddShradhamDialog open={addShradhamOpen} handleDialogClose={handleAddShradhamDialogClose} />
      <StyledTypography variant="h5">Add shradham(s)</StyledTypography>
      <StyledGrid container spacing={3}>
        {linkStore.shradhams &&
          linkStore.shradhams.length > 0 &&
          linkStore.shradhams.map((shradham, index) => (
            <Grid key={`shradham#${index}`} item xs={12} md={6}>
              <EventRecord
                {...(shradham as Shared.Shradham & Shared.Masigam)}
                index={index}
                variant={EVENT_RECORD_VARIANT.LOCAL_RECORD}
              />
            </Grid>
          ))}
        {new Array(6 - linkStore.shradhams.length).fill(undefined).map((val, index) => (
          <Grid key={`add-shradham#${index}`} item xs={12} md={6}>
            <StyledCard>
              <CardActionArea onClick={() => setAddShradhamOpen(true)}>
                <CardContent>
                  <Typography variant="subtitle1" color="textPrimary">
                    Add Shradham {linkStore.shradhams.length + index + 1} - if applicable
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        ))}
      </StyledGrid>
    </>
  );
});

export default AddShradham;
