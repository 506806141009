import * as React from "react";
import { Hit } from "react-instantsearch-core";
import { connectHits } from "react-instantsearch-dom";
import {
  styled,
  Grid,
  Typography,
  CardContent,
  Card,
  DialogActions,
  Button
} from "@material-ui/core";
import {
  Contact,
  Info as InfoProvider,
  INFO_VARIANT
} from "../../../components";
import * as Shared from "../../../shared";
import { Link } from "react-router-dom";

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2)
}));

interface RenderContactsProps {
  hits: Array<Hit<Shared.Contact>>;
}

const RenderContacts: React.FC<RenderContactsProps> = ({ hits }) => {
  return (
    <>
      {hits && hits.length === 0 && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <InfoProvider
                picture="/images/undraw_no_data_qbuo.svg"
                variant={INFO_VARIANT.MINIMAL}
              />
              <NegativeSpace />
              <Grid container direction="column" alignItems="center">
                <Typography variant="h3">No Contacts found</Typography>
                <Typography variant="body1" color="textSecondary">
                  Try a different search query or add new contacts.
                </Typography>
              </Grid>
            </CardContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                component={Link}
                to="/contacts/add"
              >
                Add Contact
              </Button>
            </DialogActions>
          </Card>
        </Grid>
      )}
      {hits &&
        hits.length > 0 &&
        hits.map(contact => (
          <Grid key={`${contact.objectID}`} item xs={12} md={6}>
            <Contact
              {...{
                contactId: contact.objectID,
                ...(contact as Shared.Contact)
              }}
            />
          </Grid>
        ))}
    </>
  );
};

const Hits = connectHits(RenderContacts);

export default Hits;
