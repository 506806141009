import * as React from "react";
import {
  Card,
  CardContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
  styled,
  withStyles,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { SkeletonProps } from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { Ancestor } from "../../shared";

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledSkeleton = withStyles({
  text: {
    marginBottom: 0,
  },
  rect: {
    marginBottom: 0,
  },
})(Skeleton as React.FC<SkeletonProps & { component?: string }>);

export const AncestorSkeleton: React.FC = (props) => {
  const showShareButton = (navigator as Navigator & {
    share: (options: { url?: string; title?: string; text?: string }) => void;
  }).share;
  const theme = useTheme();
  return (
    <>
      <Card>
        <CardContent>
          <Skeleton />
          <StyledSkeleton />
          <NegativeSpace />
          <Skeleton />
          <StyledSkeleton />
        </CardContent>
        <DialogActions>
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
          <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />
          {showShareButton && <StyledSkeleton component="span" width={60} height={theme.spacing(3)} />}
        </DialogActions>
      </Card>
    </>
  );
};

interface AncestorDetailsProps {
  ancestorDetails: Ancestor;
  contactId: string;
}
const AncestorDetails: React.FC<AncestorDetailsProps> = (props) => {
  return (
    <>
      <Card>
        <CardContent>
          {props.ancestorDetails && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{`Father Side Gothram: ${props.ancestorDetails.fatherSideGothram}`}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="textSecondary">
                  <b>Father:</b>&nbsp;{props.ancestorDetails.fatherGroup.parent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Grand Father:</b>&nbsp;{props.ancestorDetails.fatherGroup.grandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Great GrandFather:</b>&nbsp;{props.ancestorDetails.fatherGroup.greatGrandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Great Great GrandFather:</b>&nbsp;{props.ancestorDetails.fatherGroup.greatGreatGrandParent}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother:</b>&nbsp;{props.ancestorDetails.motherGroup.parent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Grand Mother:</b>&nbsp;{props.ancestorDetails.motherGroup.grandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Great GrandMother:</b>&nbsp;{props.ancestorDetails.motherGroup.greatGrandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Great Great GrandMother:</b>&nbsp;{props.ancestorDetails.motherGroup.greatGreatGrandParent}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{`Mother's Side Gothram: ${props.ancestorDetails.mothersSideGothram}`}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Father:</b>&nbsp;{props.ancestorDetails.mothersFatherGroup.parent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Grand Father:</b>&nbsp;{props.ancestorDetails.mothersFatherGroup.grandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Great GrandFather:</b>&nbsp;{props.ancestorDetails.mothersFatherGroup.greatGrandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Great Great GrandFather:</b>&nbsp;
                  {props.ancestorDetails.mothersFatherGroup.greatGreatGrandParent}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Mother:</b>&nbsp;{props.ancestorDetails.mothersMotherGroup.parent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Grand Mother:</b>&nbsp;{props.ancestorDetails.mothersMotherGroup.grandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Great GrandMother:</b>&nbsp;{props.ancestorDetails.mothersMotherGroup.greatGrandParent}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>Mother's Great Great GrandMother:</b>&nbsp;
                  {props.ancestorDetails.mothersMotherGroup.greatGreatGrandParent}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default AncestorDetails;
