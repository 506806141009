import { getCurrentPanchangamYear } from "./get-current-panchangam-year";

export function getPanchangamDocName(
  panchangam: string,
  currentPanchangamYear?: string
): string {
  const panchangamYear = currentPanchangamYear || getCurrentPanchangamYear();
  const panchangamDocName = `${panchangam.toLowerCase()}-${panchangamYear}`;

  return panchangamDocName;
}
