import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  CardActionArea,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import * as Shared from "../../shared";
import { useFirebaseApp } from "reactfire";
import "firebase/auth";
import "firebase/firestore";
import { Link } from "react-router-dom";

export enum EVENT_VARIANT {
  FULL_INFO = "FULL_INFO",
  PARTIAL_INFO = "PARTIAL_INFO",
}

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

export type EventTypes = Shared.Event.Event | Shared.Event.Masigam | Shared.Event.Sodagumbam | Shared.Event.Shradham;

type EventProps = {
  eventId: string;
  contactId?: string;
} & EventTypes;

const Event: React.FunctionComponent<EventProps> = (props) => {
  const showShareButton = (navigator as Navigator & {
    share: (options: { url?: string; title?: string; text?: string }) => Promise<void>;
  }).share;

  const [fullInfo, setFullInfo] = React.useState<boolean>(false);

  const [confirmCheckbox, setConfirmCheckbox] = React.useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const firebaseApp = useFirebaseApp();

  const handleInfoClick = () => {
    setFullInfo(!fullInfo);
  };

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteDialogClose = () => {
    setDeleteInProgress(false);
    setConfirmCheckbox(false);
    setConfirmDeleteOpen(false);
  };

  const handleConfirmCheckboxClick = () => {
    setConfirmCheckbox(!confirmCheckbox);
  };

  const getLabelFromContactKey = (key: string): string => {
    switch (key) {
      case "eventName":
        return "Event Name: ";
      case "mobileOne":
        return "Mobile(1): ";
      default:
        return `${key[0].toUpperCase()}${key.slice(1).toLowerCase()}: `;
    }
  };

  const handleDelete = async () => {
    setDeleteInProgress(true);
    try {
      const user = firebaseApp.auth().currentUser;
      if (!user) {
        throw new Error("Can't delete records because User not found.");
      }

      const eventRef = firebaseApp
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("events")
        .doc(props.eventId);

      await eventRef.delete();
    } catch (error) {
      console.error(error);
    } finally {
      handleConfirmDeleteDialogClose();
    }
  };

  const handleShare = async () => {
    const { address, eventName, landmark, mobileOne, name, date } = props;
    const availableInfo: { [k: string]: string } = {
      eventName,
      name,
      ...(landmark && { landmark }),
      ...(address && { address }),
      ...(mobileOne && { mobileOne }),
    };

    const otherText = Object.keys(availableInfo).reduce((acc, key) => {
      return `${acc}${getLabelFromContactKey(key)}${availableInfo[key]}`;
    }, "");
    const text = `EventDate: ${date.toDate().toDateString()}\n${otherText}`
    try {
      await (navigator as Navigator & {
        share: (options: { url?: string; title?: string; text?: string }) => Promise<void>;
      }).share({ text });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={confirmDeleteOpen}
        disableBackdropClick={deleteInProgress}
        disableEscapeKeyDown={deleteInProgress}
        onClose={handleConfirmDeleteDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Delete this Event?</DialogTitle>
        <DialogContent>
          <DialogContentText>Event and it's data will be permamnently lost!</DialogContentText>
          <FormControlLabel
            control={<Checkbox disabled={deleteInProgress} color="primary" onClick={handleConfirmCheckboxClick} />}
            label={
              <Typography color="textSecondary" variant="body1">
                I understand and want to delete this event.
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus disabled={deleteInProgress} onClick={handleConfirmDeleteDialogClose} size="small">
            Cancel
          </Button>
          <Button disabled={deleteInProgress || !confirmCheckbox} onClick={handleDelete} color="primary" size="small">
            {deleteInProgress ? <CircularProgress /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardActionArea onClick={handleInfoClick}>
          <CardContent>
            <Typography variant="h4" color="primary">
              {props.eventName}
            </Typography>
            {(props as Shared.Event.Masigam | Shared.Event.Shradham).masam &&
              (props as Shared.Event.Masigam | Shared.Event.Shradham).paksham &&
              (props as Shared.Event.Masigam | Shared.Event.Shradham).thithi && (
                <Typography variant="h6" color="textSecondary">
                  {" "}
                  {`${Shared.masamAsMap.get((props as Shared.Event.Masigam | Shared.Event.Shradham).masam)} - `}
                  {`${Shared.pakshamAsMap.get((props as Shared.Event.Masigam | Shared.Event.Shradham).paksham)} - `}
                  {`${Shared.thithiAsMap.get((props as Shared.Event.Masigam | Shared.Event.Shradham).thithi)}`}
                </Typography>
              )}
            <Grid container justify="space-between">
              {props.date && (
                <Typography variant="h6" color="textSecondary">
                  {props.date.toDate().toDateString().toUpperCase()}
                </Typography>
              )}
              {(props as Shared.Event.Event).eventStartTime && (
                <Typography variant="h6" color="textSecondary">
                  {((props as Shared.Event.Event).eventStartTime as string).split(" ")[0]}
                </Typography>
              )}
            </Grid>
            {fullInfo && (props as Shared.Event.Event).desc && (
              <Typography variant="h6" color="textSecondary">
                {(props as Shared.Event.Event).desc}
              </Typography>
            )}
            <NegativeSpace />
            <Typography variant="h5">{props.name}</Typography>
            {props.nickname && (
              <Typography variant="body1" color="textSecondary">
                {props.nickname}
              </Typography>
            )}
            {fullInfo && (props.address || props.landmark) && (
              <>
                <NegativeSpace />
                {props.landmark && <Typography variant="h5">{props.landmark}</Typography>}
                {props.address && (
                  <Typography variant="body1" color="textSecondary">
                    {props.address}
                  </Typography>
                )}
              </>
            )}
            {fullInfo && (props.emailId || props.mobileOne || props.telOne) && (
              <>
                <NegativeSpace />
                {props.emailId && <Typography variant="h5">{props.emailId}</Typography>}
                {props.mobileOne && <Typography variant="h6"><a href={`tel:${props.mobileOne}`}>{props.mobileOne}</a></Typography>}
                {props.telOne && (
                  <Typography variant="body1" color="textSecondary">
                    {props.telOne}
                  </Typography>
                )}
              </>
            )}
            {fullInfo && (props.panchangam || props.vedam) && (
              <>
                <NegativeSpace />
                {props.panchangam && <Typography variant="h5">{props.panchangam.toUpperCase()} Panchangam</Typography>}
                {props.vedam && (
                  <Typography variant="body1" color="textSecondary">
                    {props.vedam.toUpperCase()} Vedam
                  </Typography>
                )}
              </>
            )}
            {fullInfo && props.contactId && (
              <>
                <NegativeSpace />
                {props.contactId && (
                  <Button size="small" color="primary" component={Link} to={`/contacts/${props.contactId}`}>
                    Go to contact page
                  </Button>
                )}
              </>
            )}
          </CardContent>
        </CardActionArea>
        <DialogActions>
          <Grid container justify="space-between" alignItems="center">
            <div>
              <IconButton size="small" onClick={handleDeleteClick}>
                <DeleteIcon color="disabled" />
              </IconButton>
            </div>
            <div>
              <Button size="small" style={{ marginRight: 8 }} onClick={handleInfoClick}>
                {!fullInfo ? "Info" : "Hide"}
              </Button>
              <Button
                size="small"
                color={showShareButton ? "default" : "primary"}
                style={{ marginRight: 8 }}
                component={Link}
                to={
                  props.contactId
                    ? `/contacts/${props.contactId}/event/${props.eventId}/editevent`
                    : `/events/${props.eventId}/edit`
                }
              >
                Edit
              </Button>
              {showShareButton && (
                <Button size="small" color="primary" onClick={handleShare}>
                  Share
                </Button>
              )}
            </div>
          </Grid>
        </DialogActions>
      </Card>
    </>
  );
};

export default Event;
