import * as Shared from "../shared";

export function createShubamFromAabdhigaSodagumbam({
  aabdhigaSodagumbam,
}: {
  aabdhigaSodagumbam: Shared.Event.Sodagumbam;
}): Shared.Event.Shubam {
  const shubamDate = new Date(aabdhigaSodagumbam.date);
  shubamDate.setDate(shubamDate.getDate() + 2);

  const shubam: Shared.Event.Shubam = {
    ...aabdhigaSodagumbam,
    eventName: "Shubam",
    date: shubamDate,
  };

  return shubam;
}
