import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import * as Shared from "../../shared";
import { useFirebaseApp } from "reactfire";
import "firebase/auth";
import "firebase/firestore";
import { LinkStoreContext } from "../../app";
import { observer } from "mobx-react-lite";

const NegativeSpace = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
}));

export enum EVENT_RECORD_VARIANT {
  DB_RECORD = "DB_RECORD",
  LOCAL_RECORD = "LOCAL_RECORD",
}

interface SharedRecordProps extends Shared.Masigam, Shared.Shradham {}

type DBRecordVariant = SharedRecordProps & {
  recordId: string;
  variant?: EVENT_RECORD_VARIANT.DB_RECORD;
};
type LocalRecordVariant = SharedRecordProps & {
  index: number;
  variant?: EVENT_RECORD_VARIANT.LOCAL_RECORD;
};

type RecordProps = DBRecordVariant | LocalRecordVariant;

const Record: React.FunctionComponent<RecordProps> = observer((props) => {
  const linkStore = React.useContext(LinkStoreContext);

  const [confirmCheckbox, setConfirmCheckbox] = React.useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const firebaseApp = useFirebaseApp();

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteDialogClose = () => {
    setDeleteInProgress(false);
    setConfirmCheckbox(false);
    setConfirmDeleteOpen(false);
  };

  const handleConfirmCheckboxClick = () => {
    setConfirmCheckbox(!confirmCheckbox);
  };

  const handleLocalDelete = () => {
    linkStore.removeShradham(props.index);
  };

  const handleDelete = async () => {
    setDeleteInProgress(true);
    try {
      const user = firebaseApp.auth().currentUser;
      if (!user) {
        throw new Error("Can't delete records because User not found.");
      }

      const recordRef = firebaseApp
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection(props.karmaYear ? "masigam" : "shardham")
        .doc(props.recordId);

      const recordPromise = recordRef.delete();

      let eventsRef = firebaseApp
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("events")
        .where(props.karmaYear ? "masigamId" : "shardhamId", "==", props.recordId);

      if (!props.karmaYear) {
        eventsRef = firebaseApp
          .firestore()
          .collection("users")
          .doc(user.uid)
          .collection("events")
          .where("shardhamId", "==", props.recordId);
      }
      console.log(eventsRef);

      const eventsSnapshot = await eventsRef.get();
      eventsSnapshot.docs.map((event) => {
        console.log(event);
      });

      const eventPromises = eventsSnapshot.docs.map((event) => {
        const eventRef = firebaseApp.firestore().collection("users").doc(user.uid).collection("events").doc(event.id);
        return eventRef.delete();
      });

      await Promise.all([recordPromise, ...eventPromises]);
    } catch (error) {
      console.error(error);
    } finally {
      handleConfirmDeleteDialogClose();
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={confirmDeleteOpen}
        disableBackdropClick={deleteInProgress}
        disableEscapeKeyDown={deleteInProgress}
        onClose={handleConfirmDeleteDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Delete this {props.karmaYear ? "Masigam" : "Shradham"} record?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Event(s) attached to this {props.karmaYear ? "Masigam" : "Shradham"} along with the{" "}
            {props.karmaYear ? "Masigam" : "Shradham"} record will be permamnently lost!
          </DialogContentText>
          <FormControlLabel
            control={<Checkbox disabled={deleteInProgress} color="primary" onClick={handleConfirmCheckboxClick} />}
            label={
              <Typography color="textSecondary" variant="body1">
                I understand and want to delete this record.
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus disabled={deleteInProgress} onClick={handleConfirmDeleteDialogClose} size="small">
            Cancel
          </Button>
          <Button disabled={deleteInProgress || !confirmCheckbox} onClick={handleDelete} color="primary" size="small">
            {deleteInProgress ? <CircularProgress /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardContent>
          <Typography variant="h4" color="primary">
            {props.karmaYear ? "Masigam" : "Shradham"}
            {` for ${props.forName}`}
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {`${Shared.masamAsMap.get(props.masam)} - `}
            {`${Shared.pakshamAsMap.get(props.paksham)} - `}
            {`${Shared.thithiAsMap.get(props.thithi)}`}
          </Typography>
          <NegativeSpace />
          <Typography variant="h5">{props.panchangam.toUpperCase()} Panchangam</Typography>
        </CardContent>
        <DialogActions>
          <Grid container justify="space-between" alignItems="center">
            <div>
              <IconButton
                size="small"
                onClick={props.variant === EVENT_RECORD_VARIANT.LOCAL_RECORD ? handleLocalDelete : handleDeleteClick}
              >
                <DeleteIcon color="disabled" />
              </IconButton>
            </div>
          </Grid>
        </DialogActions>
      </Card>
    </>
  );
});

Record.defaultProps = {
  variant: EVENT_RECORD_VARIANT.DB_RECORD,
};

export default Record;
