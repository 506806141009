import * as React from "react";
import { Typography } from "@material-ui/core";
import { EventForm, FormSkeleton, EVENT_FORM_VARIANT } from "../../../components";
import Note from "./note";

interface AddProps {}

const Add: React.FunctionComponent<AddProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Note />
      <Typography variant="h2" gutterBottom>
        Add event
      </Typography>
      <React.Suspense fallback={<FormSkeleton variant={EVENT_FORM_VARIANT.WITHOUT_CONTACT} />}>
        <EventForm variant={EVENT_FORM_VARIANT.WITHOUT_CONTACT} />
      </React.Suspense>
    </>
  );
};

export default Add;
