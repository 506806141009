import * as Shared from "../shared";

export function createEvent({
  contact,
  contactId,
  eventForm,
}: {
  contactId?: string;
  contact?: Shared.Contact;
  eventForm: Shared.Form.EventWithContact | Shared.Form.EventWithoutContact;
}): Shared.Event.Event {
  const { name, nickname, panchangam, telOne, vedam, sutram } = (contact || {}) as Shared.Contact;
  const { address, date, desc, emailId, eventName, eventStartTime, landmark, mobileOne } = eventForm;
  date.setHours(0, 0, 0, 0);
  const event: Shared.Event.Event = {
    eventName,
    date,
    name: (eventForm as Shared.Form.EventWithoutContact).name || name,
    ...(((eventForm as Shared.Form.EventWithoutContact).nickname || nickname) && {
      nickname: (eventForm as Shared.Form.EventWithoutContact).nickname || nickname,
    }),
    mobileOne,
    address,
    ...(eventStartTime && {
      eventStartTime: eventStartTime.toLocaleTimeString(),
    }),
    ...(contactId && { contactId }),
    ...(desc && { desc }),
    ...(emailId && { emailId }),
    ...(landmark && { landmark }),
    ...(nickname && { nickname }),
    ...(panchangam && { panchangam }),
    ...(sutram && { sutram }),
    ...(telOne && { telOne }),
    ...(vedam && { vedam }),
  };

  return event;
}
