import * as React from "react";
import * as Sentry from "@sentry/browser";
import { ErrorInfo } from "../../components";

export interface AppFailedProps {}

export interface AppFailedState {
  hasError?: Error;
  eventId?: string;
}

// TODO: Find if there is a way to show different error messages based on the error properties
// REF: https://stackoverflow.com/questions/51384606/error-boundary-does-not-give-access-to-error-if-it-is-an-instance-of-error-class
class AppFailed extends React.Component<AppFailedProps, AppFailedState> {
  constructor(props: AppFailedProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error);
    console.error(errorInfo);
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  private handleStartFresh() {
    window.location.replace("/");
  }

  render() {
    return this.state.hasError ? (
      <ErrorInfo />
    ) : (
      this.props.children
    );
  }
}

export default AppFailed;
