import "firebase/auth";
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { AuthCheck } from "reactfire";
import * as Layouts from "../layouts";
import * as Views from "../views";
import * as Register from "./register";
import * as Settings from "./settings";
import * as Contacts from "./contacts";
import * as Events from "./events";

// NOTE: This is added to get rid of the error ts(2605) when using AuthCheck
declare module "reactfire/auth" {
  function AuthCheck({
    auth,
    fallback,
    children,
    requiredClaims
  }: AuthCheckProps): JSX.Element;
}

const Global: React.FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Suspense fallback={<Layouts.Main />}>
              <AuthCheck fallback={<Redirect to="/login" />}>
                <Layouts.Main>
                  <Views.Dashboard />
                </Layouts.Main>
              </AuthCheck>
            </Suspense>
          </Route>
          <Route exact path="/settings/linkmobile">
            <Suspense
              fallback={
                <Layouts.Minimal>
                  <Views.Setting.LinkMobile />
                </Layouts.Minimal>
              }
            >
              <AuthCheck fallback={<Redirect to="/login" />}>
                <Layouts.Minimal>
                  <Views.Setting.LinkMobile />
                </Layouts.Minimal>
              </AuthCheck>
            </Suspense>
          </Route>
          <Route path="/settings">
            <Suspense
              fallback={
                <Layouts.Main>
                  <Settings.Routes />
                </Layouts.Main>
              }
            >
              <AuthCheck fallback={<Redirect to="/login" />}>
                <Layouts.Main>
                  <Settings.Routes />
                </Layouts.Main>
              </AuthCheck>
            </Suspense>
          </Route>
          <Route path="/contacts">
            <Suspense
              fallback={
                <Layouts.Main>
                  <Contacts.Routes />
                </Layouts.Main>
              }
            >
              <AuthCheck fallback={<Redirect to="/login" />}>
                <Layouts.Main>
                  <Contacts.Routes />
                </Layouts.Main>
              </AuthCheck>
            </Suspense>
          </Route>
          <Route path="/events">
            <Suspense
              fallback={
                <Layouts.Main>
                  <Events.Routes />
                </Layouts.Main>
              }
            >
              <AuthCheck fallback={<Redirect to="/login" />}>
                <Layouts.Main>
                  <Events.Routes />
                </Layouts.Main>
              </AuthCheck>
            </Suspense>
          </Route>
          <Route exact path="/login">
            <Layouts.Minimal>
              <Views.Login />
            </Layouts.Minimal>
          </Route>
          <Route path="/register">
            <Layouts.Minimal>
              <Register.Routes />
            </Layouts.Minimal>
          </Route>
          <Route path="/forgotpassword">
            <Layouts.Minimal>
              <Views.ForgotPassword />
            </Layouts.Minimal>
          </Route>
          <Route path="/pandit/:uid/link/:lkid/add/contactandshradham">
            <Layouts.Minimal>
              <Views.Link.Add.ContactAndShradham />
            </Layouts.Minimal>
          </Route>
          <Route exact path="/404">
            <Layouts.Minimal>
              <Views.NotFound />
            </Layouts.Minimal>
          </Route>
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default Global;
