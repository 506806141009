import * as React from "react";
import { Typography } from "@material-ui/core";
import Form, { RelativeFormSkeleton } from "./form";
import { useParams } from "react-router-dom";

interface AddEventProps {}

const ModifyRelative: React.FunctionComponent<AddEventProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams<{ contactId: string; relativeId: string }>();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        {params.relativeId ? `Modify Relative` : `Add Relative`}
      </Typography>
      <React.Suspense fallback={<RelativeFormSkeleton />}>
        {<Form contactId={params.contactId} relativeId={params.relativeId} />}
      </React.Suspense>
    </>
  );
};

export default ModifyRelative;
